// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import * as utils from '@/utils/ruleUtils'
import {
  SPECIAL_REQUEST,
  SECTION_SPECIAL_REQUEST,
  BUNDLE_AVAILABILITY,
  CONSTRAINT,
  SALES_BOM,
  MFG_BOM,
  VISIBILITY,
  ENABLEMENT,
  SECTION_VISIBILITY,
  SECTION_ENABLEMENT,
  MODEL,
  REQUIRED,
  SECTION_REQUIRED,
  RECOMMENDATIONS,
  FILTERS
} from '@/constants/ruleTableTypes'
import { MODEL_TYPE_LINE_BASED, MODEL_TYPE_HEADER_BASED } from '@/constants/modelTypes'
import { VALUE_DATE_RANGE, VALUE_NUMERIC_RANGE, VALUE_LIST, VALUE_DATE, VALUE_MULTI_SELECT } from '@/constants/valuesTypes'
import localization from '@/common/utils/localization'

export default {
  isBundleAvailabilityRule(state) {
    return state.ruleContext === BUNDLE_AVAILABILITY
  },
  isConstraintRule(state) {
    return state.ruleContext === CONSTRAINT
  },
  isSalesBomRule(state) {
    return state.ruleContext === SALES_BOM
  },
  isMfgBomRule(state) {
    return state.ruleContext === MFG_BOM
  },
  isModelEntityRule(state) {
    return state.ruleContext === MODEL
  },
  isRecommendationsRule(state) {
    return state.ruleContext === RECOMMENDATIONS
  },
  isFiltersRule(state) {
    return state.ruleContext === FILTERS
  },
  isPricingBased(state) {
    return state.modelType === MODEL_TYPE_LINE_BASED || state.modelType === MODEL_TYPE_HEADER_BASED
  },
  getOutcomeIds(state) {
    return (state.rulesTable.outcomes || []).map(o => o.binding.id || o.binding.systemProperty || o.binding.customFieldId)
  },
  isVisibilityRule(state) {
    return Boolean(state.ruleContext === VISIBILITY || state.ruleContext === SECTION_VISIBILITY)
  },
  isEnablementRule(state) {
    return state.ruleContext === ENABLEMENT || state.ruleContext === SECTION_ENABLEMENT
  },
  isRequirementRule(state) {
    return state.ruleContext === REQUIRED || state.ruleContext === SECTION_REQUIRED
  },
  isSpecialRequestRule(state) {
    return state.ruleContext === SPECIAL_REQUEST || state.ruleContext === SECTION_SPECIAL_REQUEST
  },
  isAllConditionsToggled(state, getters) {
    return (getters.isVisibilityRule || getters.isEnablementRule || getters.isRequirementRule || getters.isSpecialRequestRule) && getters.getConditionEntitiesCount === 0
  },
  inVisibilityDefaultState(state, getters) {
    return getters.isVisibilityRule && getters.getConditionEntitiesCount === 0
  },
  inEnablementDefaultState(state, getters) {
    return getters.isEnablementRule && getters.getConditionEntitiesCount === 0
  },
  inRequirementDefaultState(state, getters) {
    return getters.isRequirementRule && getters.getConditionEntitiesCount === 0
  },
  inRecommendationsDefaultState(state, getters) {
    return getters.isRecommendationsRule && getters.getConditionEntitiesCount === 0
  },
  inFiltersDefaultState(state, getters) {
    return getters.isFiltersRule && getters.getConditionEntitiesCount === 0
  },
  getProductOutcomes(state, getters) {
    return state.productOutcomes
  },
  getConditionEntities(state, getters) {
    return getters.getRulesTable.conditions || []
  },
  getConditionEntitiesCount(state, getters) {
    return getters.getConditionEntities.length
  },
  getRowsLength(state) {
    return state.rowsLength
  },
  getTempSectionVisibilityTable(state) {
    return state.tempSectionVisibilityTable
  },
  getTempSectionEnablementTable(state) {
    return state.tempSectionEnablementTable
  },
  getTempSectionRequirementTable(state) {
    return state.tempSectionRequirementTable
  },
  getIsSpecialRequest(state) {
    return state.specialRequest
  },
  getTempVisibilityTable(state) {
    return state.tempVisibilityTable
  },
  getTempEnablementTable(state) {
    return state.tempEnablementTable
  },
  getTempRequirementTable(state) {
    return state.tempRequirementTable
  },
  getTempRecommendationsTable(state) {
    return state.tempRecommendationsTable
  },
  getTempFiltersTable(state) {
    return state.tempFiltersTable
  },
  getRulesTable(state) {
    return state.rulesTable
  },
  getRulesTableRows(state) {
    return state.rulesTable.rows || []
  },
  getConstraintRuleDescription(state) {
    return state.rulesTable.description
  },
  getSelectedConditions(state, getters) {
    if (Object.keys(getters.getEntitiesDetailsForRule).length === 0 || !getters.getRulesTable.conditions) {
      return []
    }

    return getters.getRulesTable.conditions
      .map(({ binding, modelItemType }) => {
        const id = binding.id || binding.systemProperty || binding.customFieldId
        return getters.getEntitiesDetailsForRule[utils.entityMap[modelItemType]].find(entity => entity.id === id)
      })
  },
  getSelectedOutcomes(state, getters) {
    if (Object.keys(getters.getEntitiesDetailsForRule).length === 0 || !getters.getRulesTable.outcomes) {
      return []
    }

    return getters.getRulesTable.outcomes
      .map(({ binding, modelItemType }) => {
        const id = binding.id || binding.systemProperty || binding.customFieldId
        const object = getters.getEntitiesDetailsForRule[utils.entityMap[modelItemType]].find(entity => entity.id === id)
        return object || {}
      })
  },
  getConditionsIds(state, getters) {
    return getters.getRulesTable.conditions.map(c => c.binding.id || c.binding.systemProperty || c.binding.customFieldId)
  },
  getRowsWithoutEmpties(state, getters) {
    return utils.getTableWithoutEmpties(getters.getRulesTableRows)
  },
  getConditionsEntitiesForRule(state) {
    return state.conditionsEntities
  },
  getOutcomesEntitiesForRule(state) {
    return state.outcomesEntities
  },
  getBindingsEntities(state) {
    return state.bindingsEntities
  },
  getEntitiesDetailsForRule(state) {
    return state.entitiesDetails
  },
  getBindingsCollection(state) {
    return state.bindingsEntities.availableCollections &&
      state.bindingsEntities.availableCollections.reduce(
        (acc, collection) => {
          return [
            ...acc,
            ...state.bindingsEntities[collection]
          ]
        }, []
      )
  },
  getNumericAssignmentEntities(state, getters) {
    return getters.getBindingsCollection.filter(e => e.dataType === 'Numeric')
  },
  getBooleanAssignmentEntities(state, getters) {
    return getters.getBindingsCollection.filter(e => e.dataType === 'Boolean')
  },
  getDateAssignmentEntities(state, getters) {
    return getters.getBindingsCollection.filter(e => e.dataType === 'Date')
  },
  getStringAndListAssignmentEntities(state, getters) {
    return getters.getBindingsCollection.filter(e => e.dataType === 'List' || e.dataType === 'String')
  },
  incompletedRowIds(state, getters) {
    const { rows = [] } = getters.getRulesTable
    return rows.reduce(
      (incompletedRowIds, row) => utils.isRowComplete(row) ? incompletedRowIds : [row.rowId, ...incompletedRowIds],
      []
    )
  },
  incompleteRowObjects(state, getters) {
    return getters.incompletedRowIds
      .map(rowID => getters.getRulesTable.rows.find(r => r.rowId === rowID) || {})
  },
  rowsInRowIdAndValueFormat(state, getters) {
    return getters.getRulesTableRows
      // .filter(row => utils.isRowComplete(row))
      .map(row => ({
        rowId: row.rowId,
        isEmpty: utils.isEntireRowEmpty(row),
        value: row.conditions.map(i => {
          if (i.comparisonType === undefined || i.comparisonType === 'Value') {
            if (i.conditionType === VALUE_NUMERIC_RANGE || i.conditionType === VALUE_DATE_RANGE) {
              return `${i.operator}-${i.lowerBoundValue}:${i.upperBoundValue}`
            } else if (i.conditionType === VALUE_LIST || i.conditionType === VALUE_MULTI_SELECT) {
              return `${i.operator}-${JSON.stringify(i.values)}`
            } else if (i.conditionType === VALUE_DATE) {
              return `${i.operator}-binding${localization.formatDateUTC(i.value)}`
            }
            return `${i.operator}-${i.value}`
          } else {
            if (i.binding && i.binding.id) {
              return `${i.operator}-${i.binding.id}`
            } else {
              return `${i.operator}-0`
            }
          }
        }).join()
      }))
  },
  duplicateRows(state, getters) {
    const dupeRows = getters.duplicateRowsValues
    const rows = getters.rowsInRowIdAndValueFormat
      .filter(row => {
        const rowIsDuplicate = dupeRows[row.value] >= 2
        return rowIsDuplicate && !row.isEmpty
      })

    return rows
      .map(row => getters.getRulesTable.rows.find(r => r.rowId === row.rowId) || {})
  },
  duplicateRowsValues(state, getters) {
    const object = {}
    const result = []

    getters.rowsInRowIdAndValueFormat.forEach(({ value }) => {
      if (!object[value]) object[value] = 0
      object[value] += 1
    })

    for (let prop in object) {
      if (object[prop] >= 2) {
        result.push(prop)
      }
    }
    return object
  },
  duplicateRowIDs(state, getters) {
    return getters.duplicateRows.map(({ rowId }) => rowId)
  },
  isRuleInvalid(state, getters) {
    return getters.incompletedRowIds.length > 0 || getters.duplicateRowIDs.length > 0 || getters.getRowsWithoutEmpties.length === 0
  },
  getRulesTableRowsLength(state) {
    return state.rulesTable.rows && (state.rulesTable.rows.length || 0)
  },
  isShowErrorsDisabled(state, getters) {
    return getters.getRulesTableRowsLength === 0 || (getters.incompletedRowIds.length === 0 && getters.duplicateRowIDs.length === 0)
  },
  colspan(state, getters) {
    // 2 for ending col and checkbox column
    return getters.getSelectedConditions.length + getters.getSelectedOutcomes.length + 2
  },
  getRuleContext(state) {
    return state.ruleContext
  },
  getRuleUsedIn(state) {
    state.ruleUsedIn = localStorage.getItem('ruleUsedIn')
    return state.ruleUsedIn
  },
  isRulesTableEdited(state) {
    return state.isEdited
  },
  getAvailableBundleProducts(state) {
    return state.availableBundleProducts
  },
  getRulesTableIsLoadingRules(state) {
    return state.isLoadingRules
  }
}
