// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import { mapGetters } from 'vuex'

export default {
  name: 'vue-overlay',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    bodyClass: {
      type: String,
      default: 'modal-blur'
    },
    clickOutsideEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVisibleMutable: false
    }
  },
  mounted() {
    this.isVisibleMutable = this.isVisible
  },
  computed: {
    ...mapGetters({ isBusy: 'getIsBusy' })
  },
  watch: {
    isVisible(value) {
      if (value) {
        const backEventHandler = function() {
          document.body.classList.remove(this.bodyClass)
          window.removeEventListener('popstate', backEventHandler, false)
        }
        window.addEventListener('popstate', backEventHandler, false)
        document.body.classList.add(this.bodyClass)
        document.documentElement.style.overflow = 'hidden'
        this.isVisibleMutable = true
      } else {
        document.body.classList.remove(this.bodyClass)
        document.documentElement.style.overflow = ''
        setTimeout(() => {
          this.isVisibleMutable = false
        }, 300)
      }
    }
  },
  beforeDestroy() {
    document.body.classList.remove(this.bodyClass)
    document.documentElement.style.overflow = ''
  }
}
