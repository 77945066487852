// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
function generateDecisionMatrixId() {
  let id = 0

  return {
    getId: () => {
      id = id + 1
      return id 
    }
  }
}

export default generateDecisionMatrixId()
export const modelUIIds = generateDecisionMatrixId()