// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      

export default {
  name: 'checkbox-input',
  props: {
    labelText: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: false
    }
  },
  created() {
    this.checked = typeof this.value === 'string' ? this.value === 'true' : this.value
  },
  computed: {
    id() {
      return this._uid
    }
  },
  watch: {
    value() {
      this.checked = typeof this.value === 'string' ? this.value === 'true' : this.value
    }
  }
}
