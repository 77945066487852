// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import axios from 'axios'
import * as featureFlags from '@/common/utils/featureFlagHelper'
import AboutModal from '@/modules/misc/about-modal'
import Toast from '@/common/popups-and-indicators/toast'
import MainHeader from '@/common/layout/main-header'
import NavigationBar from '@/common/layout/navigation-bar'
import VueOverlay from '@/common/layout/vue-overlay'
import { initializeFS } from '@/utils/fullstory'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { AUTH_SET_IS_AUTHENTICATED, AUTH_SET_IS_AUTHOR, AUTH_SET_USER, I18N_SET_CURRENT_LANGUAGE, CPQ_MANAGER_TOAST, ROUTER_SET_FROM_ROUTE, TRANSLATION_SET_CURRENT_LANGUAGE, TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE } from '@/store/types'
import { FLAGS_SET_FLAGS } from '@/store/modules/flags/types'
import { getFeatureFlagEnvID } from '@/common/constants/featureFlagCredentials'
import { getCustomerPortalUrl } from '@/constants/customerPortalUrl'
import featureFlagMixin from './mixins/featureFlagMixin'
import handleIdleMixin from '@/common/mixins/handleIdleMixin'
import taskHubMixin from './mixins/taskHubMixin'
import ErrorPage from '@/common/misc/error-page'
import SupportForm from '@/common/form-controls/support-form'
import signalRConnection from '@/store/signalRConnection'
import TextInput from '@/common/form-controls/text-input'
import TextArea from '@/common/form-controls/text-area'
import * as auth from '@/common/auth'
import { getUserInfo } from './common/auth'
import { propogateToken } from '@/common/utils/tokenPropogater'
import Chatbot from '@/modules/misc/chatbot'

export default {
  name: 'app',
  components: {
    Chatbot,
    Toast,
    MainHeader,
    NavigationBar,
    ErrorPage,
    SupportForm,
    VueOverlay,
    TextInput,
    TextArea
  },
  mixins: [ featureFlagMixin, handleIdleMixin, taskHubMixin ],
  data() {
    return {
      isEnabledSelfService: false,
      isFeedbackOverlayVisible: false,
      isOverlayVisible: false,
      isSupportFormOpen: false,
      isToastVisible: false,
      mutationObserver: null,
      supportCaseLink: null,
      isChatbotOpen: false,
      chatbot: [],
      isChatbotSending: false
    }
  },
  beforeCreate() {
    if (
      window.location.hostname !== 'localhost' &&
      window.location.protocol === 'http:'
    ) {
      window.location.replace(window.location.href.replace('http', 'https'))
    }
  },
  async created() {
    this.handleIdle()
    featureFlags.init({
      environmentID: getFeatureFlagEnvID(
        window.location.hostname,
        'cpqmanager'
      ),
      onChange: (oldFlags, params) => {
        // Occurs whenever flags are changed
        this.FLAGS_SET_FLAGS({
          allFlags: featureFlags.getAllFlags(),
          params: params
        })
      },
      anonymous: true
    })
    window.addEventListener('mouseup', this.forwardOrBackButtonClick)
    window.addEventListener('mousedown', this.forwardOrBackButtonClick)
    await this.getUserAndAuthStatus()

    if (this.getUserTenantId && await this._isFeatureEnabled('translations')) {
      await this.fetchCurrentLanguages()
      this.fetchDefaultLanguage().then(async (data) => {
        await this.fetchCpqTenantUserPreferences()
        const languageCode = this.userPreferences && this.userPreferences.preferredLanguage
          ? this.userPreferences.preferredLanguage
          : null
        if (languageCode) {
          let language = this.languageListFormatted.find(lang => lang.code === languageCode)
          if (language) {
            this.TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE(language)
            window.localStorage.languageCode = language.code
            window.postMessage({
              type: 'CHANGE_LANGUAGE',
              languageCode: language.code
            }, '*')
          } else {
            this.TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE(this.getDefaultLanguage)
            window.localStorage.languageCode = this.getDefaultLanguage
            window.postMessage({
              type: 'CHANGE_LANGUAGE',
              languageCode: this.getDefaultLanguage
            }, '*')
          }
        } else {
          this.TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE(this.getDefaultLanguage)
          window.localStorage.languageCode = this.getDefaultLanguage
          window.postMessage({
            type: 'CHANGE_LANGUAGE',
            languageCode: this.getDefaultLanguage
          }, '*')
        }
      })
    }
  },
  mounted() {
    if (!window.localStorage.getItem('languageCode')) {
      window.localStorage.languageCode = 'en-US'
    }
    const languageCode = window.localStorage.getItem('languageCode')
    document.documentElement.setAttribute('lang', languageCode)
    this.I18N_SET_CURRENT_LANGUAGE(languageCode)
    this.TRANSLATION_SET_CURRENT_LANGUAGE(languageCode)
    // add listener for tab switching. if they logout or session expires before they come back to the tab, reload the page
    window.addEventListener('visibilitychange', this.$checkAuth)
    // Create an observer instance and start observing the target node for mutations
    const observerCallback = mutations => {
      mutations.forEach(mutation => {
        this.isOverlayVisible = mutation.target && mutation.target.classList.contains('body-darken')
      })
    }
    this.mutationObserver = new MutationObserver(observerCallback)
    this.mutationObserver.observe(document.body, { attributes: true })
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.$checkAuth)
    this.mutationObserver.disconnect()
    window.removeEventListener('mouseup', this.forwardOrBackButtonClick)
    window.removeEventListener('mousedown', this.forwardOrBackButtonClick)
  },
  computed: {
    ...mapGetters({
      featureFlags: 'flags_getAllFlags',
      isAuthor: 'getIsAuthor',
      isBusy: 'getIsBusy',
      isUserAuthenticated: 'getIsAuthenticated',
      currentLanguage: 'getCurrentLanguage',
      getDefaultLanguage: 'getDefaultLanguage',
      languageList: 'getLanguageList',
      hasUserBeenLoaded: 'hasUserBeenLoaded',
      newAuthorHotfix: 'getNewAuthorHotfix',
      newAuthorVersion: 'getNewAuthorVersion',
      toast: 'getToast',
      user: 'getUser',
      getUserTenantId: 'getUserTenantId',
      userPreferences: 'getUserPreferences'
    }),
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },
    chatbotDialog() {
      return this.chatbot.map((chatbot, index) => {
        const id = `chatbot-chat-${index}`
        const question = `Q: ${chatbot.question}\n`.replaceAll('\n', '<br>')
        const answer = `A: ${chatbot.answer.replace(/\n\n$/, '').replace(/\n$/, '')}\n`.replaceAll('\n', '<br>')

        if (chatbot.sources) {
          // chatbot.sources.forEach(async source => {
          //   const response = await axios.get(source)
          //   console.log({ response })
          // })

          const chatbotSources = chatbot.sources.map(source => `<p><a href="${source}" target="_blank">Source</a></p>`).join('<br>')

          return `
            <div id="${id}">
              <p>${question}</p>
              <p>${answer}</p>
              ${chatbotSources}
            </div>
          `
        } else {
          return `
            <div id="${id}">
              <p>${question}</p>
              <p>${answer}</p>
            </div>
          `
        }
      }).join('<br>')
    },
    languageListFormatted() {
      let langaugeArray = this.languageList.map(language => {
        return { code: language.cultureCode, name: language.displayName + ' - (' + language.cultureCode + ')', id: language.id }
      })
      return langaugeArray
    }
  },
  methods: {
    ...mapActions([
      'downloadAuthor',
      'downloadHotfix',
      'fetchAuthorInstallVersion',
      'fetchCurrentLanguages',
      'fetchDefaultLanguage',
      'fetchOutputsModelId',
      'fetchPackages',
      'fetchTenant',
      'fetchUser',
      'fetchCpqTenantUserPreferences'
    ]),
    ...mapMutations([
      AUTH_SET_IS_AUTHENTICATED,
      AUTH_SET_IS_AUTHOR,
      AUTH_SET_USER,
      I18N_SET_CURRENT_LANGUAGE,
      CPQ_MANAGER_TOAST,
      ROUTER_SET_FROM_ROUTE,
      FLAGS_SET_FLAGS,
      TRANSLATION_SET_CURRENT_LANGUAGE,
      TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE
    ]),
    async authenticateUser() {
      await auth.waitForAuthentication()
      const _isAuthenticated = await auth.isAuthenticated()
      this.AUTH_SET_IS_AUTHENTICATED(_isAuthenticated)
    },
    changeLanguage(languageCode) {
      this.I18N_SET_CURRENT_LANGUAGE(languageCode)
      this.TRANSLATION_SET_CURRENT_LANGUAGE(languageCode)
      if (window && window.inline_manual_player) {
        window.inline_manual_player.setLanguage(languageCode.substring(0, 2))
        window.inline_manual_player.update()
      }
    },
    closeSupportForm() {
      this.isSupportFormOpen = false
    },
    async downloadAuthorHotfixFile() {
      await this.downloadHotfix()
      this.fetchAuthorInstallVersion(this.$t)
    },
    async downloadAuthorVersionFile() {
      await this.downloadAuthor()
      this.fetchAuthorInstallVersion(this.$t)
    },
    forwardOrBackButtonClick(e) {
      if (e.buttons === 16) this.$router.go(1)
      else if (e.buttons === 8) this.$router.go(-1)
    },
    async getUserAndAuthStatus() {
      return new Promise(async (resolve, reject) => {
        try {
          // if (axios.defaults.headers.common['Authorization'] === undefined) {
          await this.authenticateUser()
          const oktaTokens = await auth.getAccessToken()
          const accessToken = oktaTokens?.accessToken
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
          propogateToken(accessToken)
          const user = await getUserInfo()
          this.AUTH_SET_USER(user)
          if (!this.lastLoginUpdated) {
            // this.updateLastLogin(user.tenant)
            this.lastLoginUpdated = true
          }
          // }
          // if (this.isUserAuthenticated) {
          //   if (this.hasUserBeenLoaded) return
          //   // get access token from OKTA
          //   const { accessToken } = await auth.getAccessToken()
          //   axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
          //   // getTenantApi().defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
          //   // get user from OKTA
          //   const user = await auth.getUserInfo()
          //   if (!user) this.logout()
          //   this.AUTH_SET_USER(user)
          // } else {
          //   this.AUTH_SET_USER({})
          //   auth.loginLog('getUserAndAuthStatus() router.push(\'/login\'), this.isUserAuthenticated=' + this.isUserAuthenticated)
          //   this.$router.push('/login')
          // }
          resolve()
        } catch (e) {
          console.error(e)
          reject(e)
        }
      })
    },
    getPortalUrl() {
      return getCustomerPortalUrl(window.location.hostname)
    },
    hideGuidedHelp() {
      window.inline_manual_player.hidePanel()
    },
    hideToastMessage() {
      this.CPQ_MANAGER_TOAST(null)
    },
    async logout() {
      await auth.logout()
      await this.authenticateUser()
    },
    navigateToGuidedHelp(origin) {
      if (origin === 'form-submit') this.showToast()
      this.closeSupportForm()
      if (window.inline_manual_player) this.showPanel()
      // this.openGuidedHelp()
    },
    sendMessage() {
      // sends CPQSupport message with client name and email
      const oktaInfo = JSON.parse(localStorage.getItem('okta-token-storage'))
      const name = oktaInfo && oktaInfo.idToken && oktaInfo.idToken.claims.name
      const email =
        oktaInfo && oktaInfo.idToken && oktaInfo.idToken.claims.email
      setTimeout(() => {
        const iframe = document.querySelector('.inmplayer-article-item-body iframe')
        iframe.contentWindow.postMessage({ name, email }, '*')
      }, 1400)
    },
    showPanel() {
      window.inline_manual_player.showPanel()
    },
    showAbout() {
      this.$modal.show(
        AboutModal,
        {},
        {
          classes: ['about-modal-container'],
          clickToClose: true
        }
      )
    },
    showToast() {
      this.isToastVisible = true
      setTimeout(() => {
        this.isToastVisible = false
      }, 3000)
    },
    supportCaseHandler() {
      this.supportCaseLink.removeEventListener('click', this.supportCaseHandler)
      this.hideGuidedHelp()
      this.toggleSupportForm()
    },
    toggleSupportForm() {
      this.isSupportFormOpen = !this.isSupportFormOpen
    },
    isChatbotEnabled() {
      if (!featureFlags.hasFeature('prototype-cgpt'))
        return false

      // const authorizedTenants = [
      //   '461500b0-7f18-4da5-8827-570ae39e9cda', // blaw
      //   '332991d8-5602-474d-8fb9-eb7d26c1537a', // blawtest
      //   'cec252a8-24de-4413-9395-44bdcfcff5bb', // jbenner
      //   '6493ca3c-4e5c-44af-be26-9cd3131e0bbd', // jketron
      //   '87091e89-2c5f-4fdc-acaa-c74d0ea58f91', // PlatformTest
      //   'end-of-list'
      // ]
      // if (!authorizedTenants.includes(this.getUserTenantId))
      //   return false

      return true
    },
    openChatbot() {
      this.isChatbotOpen = true
      setTimeout(() => {
        if (this.$el.querySelector('.app-chatbot-dialog')) {
          this.$el.querySelector('.app-chatbot-dialog').style.bottom = '0'
          this.$el.querySelector('.app-chatbot-dialog__text-input--input .text-input__input').focus()
        }
      }, 0)
    },
    closeChatbot() {
      if (this.$el.querySelector('.app-chatbot-dialog')) this.$el.querySelector('.app-chatbot-dialog').style.bottom = '-60rem'
      setTimeout(() => {
        this.isChatbotOpen = false
      }, 500)
    },
    getNewestChatbotDialogId() {
      const ids = Array.from(document.querySelectorAll('.app-chatbot-dialog_body-response div'))
        .filter(div => div.id)
        .map(div => div.id)
      const numericIds = ids.map(id => Number(id.replace('chatbot-chat-', '')))
      const newestNumericId = numericIds.sort().pop()

      return `chatbot-chat-${newestNumericId}`
    },
    async sendChatbotQuestion() {
      if (this.isChatbotSending || !this.chatbot.question) return

      this.isChatbotSending = true

      try {
        const url = 'https://cpq-chatbot.devlkube.eastus2-dev.cincomcloud.com/chatbot'
        const postData = { query: this.chatbot.question }
        const config = { auth: { username: process.env.VUE_APP_CHATBOT_USERNAME, password: process.env.VUE_APP_CHATBOT_PASSWORD } }
        const { data: { answer, sources } } = await axios.post(url, postData, config)
        this.chatbot.push({
          question: this.chatbot.question,
          answer,
          sources
        })
      } catch (error) {
        console.error(error)
        this.chatbot.push({
          question: this.chatbot.question,
          answer: error.message
        })
      } finally {
        this.chatbot.question = ''
        this.isChatbotSending = false

        // We're doing a set timeout here because the element isn't available initially.
        setTimeout(() => {
          const newestDialogId = this.getNewestChatbotDialogId()
          const newestDialog = document.getElementById(newestDialogId)
          newestDialog.scrollIntoView()
        }, 0)
      }
    }
  },
  watch: {
    async $route(to, from) {
      if (window.inline_manual_player) {
        window.inline_manual_player.update()
      }
      this.ROUTER_SET_FROM_ROUTE(from)
      await this.getUserAndAuthStatus()
    },
    getUserTenantId(value) {
      if (!value) return

      featureFlags.init({
        environmentID: getFeatureFlagEnvID(window.location.hostname, 'cpqmanager'),
        onChange: () => {},
        anonymous: false
      })
    },
    async user(newValue, oldValue) {
      if (newValue && newValue.userid && (!oldValue || newValue.userid !== oldValue.userid)) {
        const user = newValue
        const tenant = await this.fetchTenant()
        signalRConnection.start()
        this.fetchOutputsModelId()
        this.isEnabledSelfService = Boolean(tenant.selfServiceEnabled)
        // Author
        this.fetchUser({ userId: user.userid }).then(data => {
          if (this.isAuthor) this.fetchAuthorInstallVersion(this.$t)
        })
        // Authenticate user in Fullstory
        if (process.env.NODE_ENV === 'production') {
          if (!window.FS) initializeFS(window, document, window['_fs_namespace'], 'script', 'user')
          window.FS.identify(user.userid, { displayName: user.name, email: user.email })
        }
        featureFlags.identify(user.email.toLowerCase().includes('selenium') ? 'selenium' : user.tenant)
      }
    }
  }
}
