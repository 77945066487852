// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import i18next from 'i18next'
import Locize from 'i18next-locize-backend'
import VueI18Next from '@panter/vue-i18next'
import {
  LOCIZE_CONFIG
} from './translationConfig'

const initI18Next = (lang = '') => {
  if (lang) {
    LOCIZE_CONFIG.lng = lang
  }
  i18next.use(Locize)
    .init(LOCIZE_CONFIG)
  return new VueI18Next(i18next)
}

export default initI18Next