// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import { SET_HTTP_REQUEST_PENDING, SET_HTTP_REQUEST_COMPLETE } from '@/store/types'

const state = {
  httpPendingRequestCount: 0
}

const getters = {
  getIsBusy(state) {
    return state.httpPendingRequestCount > 0
  }
}

const mutations = {
  [SET_HTTP_REQUEST_PENDING](state) {
    state.httpPendingRequestCount++
  },
  [SET_HTTP_REQUEST_COMPLETE](state) {
    state.httpPendingRequestCount--
  }
}

const actions = {
  setHttpRequestComplete({ commit }) {
    commit(SET_HTTP_REQUEST_COMPLETE)
  },
  setHttpRequestPending({ commit }) {
    commit(SET_HTTP_REQUEST_PENDING)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
