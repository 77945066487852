// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export default {
  getProducts(state) {
    return state.products
  },
  getCurrentProduct(state) {
    return state.currentProduct
  },
  getUnassignedProduct(state) {
    return state.unassignedProducts
  },
  getConfigurableUnassignedProducts(state) {
    return state.unassignedProducts.filter(({ isConfigurable }) => isConfigurable)
  },
  getAllProducts(state, getters) {
    return [...getters.getCurrentModelProducts, ...getters.getUnassignedProduct]
  },
  getAllConfigurableProducts(state, getters) {
    return [...getters.getCurrentModelProducts, ...getters.getConfigurableUnassignedProducts]
  },
  getPricedItems(state) {
    return state.pricedItems
  },
  getCurrentPricedItem(state) {
    return state.currentPricedItem
  },
  getUnassignedPricedItems(state) {
    return state.unassignedPricedItems
  },
  getPriceLists(state) {
    return state.priceLists
  },
  getCurrentPriceList(state) {
    return state.currentPriceList
  },
  getPriceListItems(state) {
    return state.priceListItems
  },
  getPriceSheetItems(state) {
    return state.priceSheetItems
  },
  getCostSheetItems(state) {
    return state.costSheetItems
  },
  getOptions(state) {
    return state.options
  },
  getCurrentOption(state) {
    return state.currentOption
  },
  getGroups(state) {
    return state.groups
  },
  getModelGroups(state, getters) {
    return getters.getGroups // getters.getGroups.length === 0 ? getters.getCurrentModelOptionGroups.concat(getters.getCurrentModelProductGroups) : getters.getGroups
  },
  getGroupById(state) {
    return state.currentGroup
  },
  getOptionGroups(state) {
    return state.groups.filter(group => group.pricedItemType === 'Option')
  },
  getProductGroups(state) {
    return state.groups.filter(group => group.pricedItemType === 'BasicProduct' || group.pricedItemType === 'ConfiguredProduct')
  },
  getConfigurableProductGroups(state) {
    return state.groups.filter(group => group.pricedItemType === 'ConfiguredProduct')
  },
  getBasicProductGroups(state) {
    return state.groups.filter(group => group.pricedItemType === 'BasicProduct')
  },
  getCurrencies(state) {
    return state.currencies
  },
  getCustomFields(state) {
    return state.customFields
  },
  getCurrentCustomField(state) {
    return state.currentCustomField
  },
  getColumnSettings(state) {
    return state.columnSettings
  }
}