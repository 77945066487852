// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import { clickOutside } from '@/common/utils/directives'
import { FRENCH, USA_ENGLISH } from '@/common/constants/locales'
import i18next from 'i18next'
import NotificationBadge from '../notification-badge'
import { mapGetters } from 'vuex'
import featureFlagMixin from '@/mixins/featureFlagMixin'

export default {
  name: 'main-header',
  directives: {
    clickOutside
  },
  components: {
    NotificationBadge
  },
  mixins: [featureFlagMixin],
  props: {
    hasAbout: {
      type: Boolean,
      default: false
    },
    hasProfile: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: ''
    },
    hasDocumentOutputPrivilege: {
      type: Boolean,
      default: false
    },
    hasIntegrationPrivilege: {
      type: Boolean,
      default: false
    },
    hasSelfServicePrivilege: {
      type: Boolean,
      default: false
    },
    hasHelpIcon: {
      type: Boolean,
      default: false
    },
    helpUrls: {
      type: Array,
      default: () => []
    },
    homeRouteName: {
      type: String,
      default: 'dashboard'
    },
    isManager: {
      type: Boolean,
      default: false
    },
    isDeployment: {
      type: Boolean,
      default: false
    },
    isCCA: {
      type: Boolean,
      default: false
    },
    newAuthorVersion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      docUrl: this.helpUrls && this.helpUrls.length ? this.helpUrls[0].url : null,
      isUserActionsOpen: false,
      isSupportOpen: false,
      isSettingsVisible: false,
      selectedLanguageCode: USA_ENGLISH
    }
  },
  mounted() {
    if (window.localStorage.getItem('languageCode')) {
      this.selectedLanguageCode = window.localStorage.languageCode
    }
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  computed: {
    ...mapGetters({
      tenant: 'getTenant'
    }),
    languages() {
      return [
        { code: USA_ENGLISH, text: this.$t2('language_english', 'English', this.$t) },
        { code: FRENCH, text: this.$t2('language_french', 'French', this.$t) }
      ]
    },
    isFrench() {
      return this.selectedLanguageCode.startsWith(FRENCH)
    }
  },
  methods: {
    about() {
      this.$emit('about')
      this.closeUserActions()
    },
    changeLanguage(languageCode) {
      i18next.changeLanguage(languageCode, (err, t) => {
        if (err) return console.warn('Failed to set language.', err)
        this.selectedLanguageCode = languageCode
        this.$emit('langChanged', languageCode)
      })
      document.documentElement.setAttribute('lang', languageCode)
      window.localStorage.languageCode = languageCode
      this.docUrl = this.helpUrls && this.helpUrls.length ? this.helpUrls.find(item => item.languageCode === languageCode).url : null
      this.closeUserActions()
    },
    closeUserActions() {
      this.isUserActionsOpen = false
    },
    closeSupport() {
      this.isSupportOpen = false
    },
    closeSettings() {
      this.isSettingsVisible = false
    },
    goHome() {
      this.$router.push({ name: this.homeRouteName })
    },
    goToCustomFields() {
      this.pushRoute('customFields')
    },
    goToDeployment() {
      this.pushRoute('deployment')
    },
    goToDocumentOutput() {
      this.pushRoute('documentOutputSettings')
    },
    goToIntegration() {
      this.pushRoute('integrations')
    },
    goToParameters() {
      this._isFeatureEnabled('manager-terminology') ? this.pushRoute('globalVariables') : this.pushRoute('parameters')
    },
    goToProfilePage() {
      this.closeUserActions()
      this.pushRoute('profile')
    },
    goToSelfServiceUISettings() {
      this.pushRoute('self-service-settings')
    },
    goToTranslations() {
      this.pushRoute('translations')
    },
    goToSupportHelp() {
      this.closeSupport()
      window.open(window.KUBE_SETTINGS.OnlineHelp + '/index.html?lang=' + (this.isFrench ? 'fr' : 'en'), '_blank')
    },
    goToSupportCase() {
      this.closeSupport()
      this.$emit('supportCase')
    },
    pushRoute(routeName) {
      let posssibleError = this.$router.push({ name: routeName })
      if (posssibleError) {
        posssibleError.catch(_ => {})
      }
      this.isSettingsVisible = false
    },
    toggleIsUserActionsOpen() {
      this.isUserActionsOpen = !this.isUserActionsOpen
    },
    toggleIsSupportOpen() {
      this.isSupportOpen = !this.isSupportOpen
    },
    toggleSettings() {
      this.isSettingsVisible = !this.isSettingsVisible
    },
    ADAArrowSettings() {
      /* istanbul ignore next */
      let menu = document.querySelector('.settings__menu')
      /* istanbul ignore next */
      let firstItem = menu.children[0].children[0]
      firstItem.focus()
    },
    keyPressed(e, item) {
      e.preventDefault()
      /* istanbul ignore next */
      if (e.code === 'ArrowUp') {
        switch (item) {
          case 'Parameters':
            this.pageToFocus(0)
            break
          case 'IntegrationPrivilege':
            this.pageToFocus(1)
            break
          case 'DocumentOutput':
            this.pageToFocus(2)
            break
          case 'SelfServiceSettings':
            if (this.hasDocumentOutputPrivilege) this.pageToFocus(3)
            else this.pageToFocus(2)
            break
          default:
            break
        }
      } else if (e.code === 'ArrowDown') {
        switch (item) {
          case 'CustomFields':
            this.pageToFocus(1)
            break
          case 'Parameters':
            this.pageToFocus(2)
            break
          case 'IntegrationPrivilege':
            this.pageToFocus(3)
            break
          case 'DocumentOutput':
            if (this.hasSelfServicePrivilege) this.pageToFocus(4)
            break
          default:
            break
        }
      } else if (e.code === 'Enter' || e.code === 'Space') {
        switch (item) {
          case 'CustomFields':
            this.goToCustomFields()
            break
          case 'Parameters':
            this.goToParameters()
            break
          case 'IntegrationPrivilege':
            this.goToIntegration()
            break
          case 'DocumentOutput':
            this.goToDocumentOutput()
            break
          case 'SelfServiceSettings':
            this.goToSelfServiceUISettings()
            break
          case 'Translations':
            this.goToTranslations()
            break
          default:
            break
        }
      }
    },
    pageToFocus(item) {
      /* istanbul ignore next */
      let menu = document.querySelector('.settings__menu')
      /* istanbul ignore next */
      let settingsPages = menu.children[0]
      settingsPages.children[item].focus()
    },
    receiveMessage(event) {
      if (event.data.type === 'CHANGE_LANGUAGE') {
        this.changeLanguage(event.data.languageCode)
      }
    }
  }
}
