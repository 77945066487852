// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import {
  RULES_TABLE_SET_MODEL_TYPE,
  RULES_TABLE_SET_MATRIX_ARRAY,
  RULES_TABLE_SET_CONDITION_ID,
  RULES_TABLE_SET_CONDITION_COMPARISON_TYPE,
  RULES_TABLE_SET_CONDITION_BINDING,
  RULES_TABLE_SET_LIST_ASSIGNMENT,
  RULES_TABLE_ADD_CONDITION_COLUMN,
  RULES_TABLE_REMOVE_CONDITION_COLUMN,
  RULES_TABLE_REMOVE_OUTCOME_COLUMN,
  RULES_TABLE_ADD_OUTCOME_COLUMN,
  RULES_TABLE_ADD_ROWS,
  RULES_TABLE_REMOVE_ROW,
  RULES_TABLE_SET_ROWS_LENGTH,
  RULES_TABLE_SET_CURRENT_RULE,
  RULES_TABLE_SET_CURRENT_SECTION_RULE,
  RULES_TABLE_SET_STRING_ASSIGNMENT,
  RULES_TABLE_SET_NUMERIC_ASSIGNMENT,
  RULES_TABLE_SET_BOOLEAN_ASSIGNMENT,
  RULES_TABLE_SET_DATE_ASSIGNMENT,
  RULES_TABLE_SET_VOLUME_ADJUSTMENT_ASSIGNMENT,
  RULES_TABLE_SET_ADD_PRODUCTS_ASSIGNMENT,
  RULES_TABLE_SET_ADD_SOLUTIONS_ASSIGNMENT,
  RULES_TABLE_SET_REMOVE_PRODUCTS_ASSIGNMENT,
  RULES_TABLE_SET_REMOVE_SOLUTIONS_ASSIGNMENT,
  RULES_TABLE_SET_CUSTOM_DATA_TYPE,
  RULES_TABLE_SET_CONDITIONS_ENTITIES,
  RULES_TABLE_SET_OUTCOMES_ENTITIES,
  RULES_TABLE_SET_BINDINGS_ENTITIES,
  RULES_TABLE_SET_ENTITIES_DETAILS,
  RULES_TABLE_SET_NEW_OPERATOR_TO_COLUMN,
  RULES_TABLE_SET_CELL_OPERATOR,
  RULES_TABLE_SET_IGNORE_CASE,
  RULES_TABLE_SET_CELL_RANGE_START_OPERATOR,
  RULES_TABLE_SET_CELL_RANGE_END_OPERATOR,
  RULES_TABLE_SET_CELL_RANGE_END_VALUE,
  RULES_TABLE_SET_CELL_RANGE_START_VALUE,
  RULES_TABLE_SET_RULE_CONTEXT,
  RULES_TABLE_SET_RULE_USEDIN,
  RULES_TABLE_SET_ROW_VALIDITY,
  RULES_TABLE_SET_NAME_AND_DESCRIPTION,
  RULES_TABLE_RESET_DEFAULT_STATE,
  RULES_TABLE_SET_QUANTITY,
  RULES_TABLE_SET_QUANTITY_TYPE,
  RULES_TABLE_SET_AVAILABLE_BUNDLE_PRODUCTS,
  RULES_TABLE_IS_LOADING_RULES,
  SET_TEMP_SECTION_VISIBILITY_TABLE,
  SET_TEMP_SECTION_ENABLEMENT_TABLE,
  SET_TEMP_SECTION_REQUIREMENT_TABLE,
  SET_TEMP_SECTION_SPECIAL_REQUEST_TABLE,
  SET_TEMP_RECOMMENDATIONS_TABLE,
  SET_TEMP_FILTERS_TABLE,
  SET_TEMP_VISIBILITY_TABLE,
  SET_TEMP_ENABLEMENT_TABLE,
  SET_TEMP_REQUIREMENT_TABLE,
  SET_TEMP_SPECIAL_REQUEST_TABLE,
  RULES_TABLE_SET_AVAILABILITY,
  RULES_TABLE_SET_LIST_VALUE,
  RULES_TABLE_SET_OUTCOME_TYPE,
  RULES_TABLE_SET_ROW_OBJECT_TYPE,
  RULES_TABLE_SET_QUANTITY_BINDING,
  RULES_TABLE_SET_RELATIONSHIP_CHILD_MODEL_ID,
  RULES_TABLE_SET_PRODUCT_OUTCOMES
} from '@/store/types'
import deepClone from '@/utils/deepClone'
import generateDecisionMatrixId from '@/utils/generateDecisionMatrixId'
import decisionItemFactory from '@/utils/getDefaultRuleItem'
import { VARIABLE_LIST } from '@/constants/variableTypes'
import getAvailabilityOutcomeValues from '@/store/modules/rulesTable/utils/getAvailabilityOutcomeValues'
import { OPERATOR_ENUM } from '@/constants/operators'
import outcomeTypes from '@/modules/misc/rules-table/outcomeTypes'
import quantityTypes from '@/modules/misc/rules-table/quantityTypes'
import { VALUE_MULTI_SELECT } from '@/constants/valuesTypes'
import * as utils from '@/utils/ruleUtils'
import {
  BUNDLE_AVAILABILITY,
  CONSTRAINT,
  SALES_BOM,
  MFG_BOM,
  SECTION_VISIBILITY,
  SECTION_ENABLEMENT,
  SECTION_REQUIRED,
  VISIBILITY,
  ENABLEMENT,
  MODEL,
  REQUIRED,
  RECOMMENDATIONS,
  FILTERS
} from '@/constants/ruleTableTypes'
import defaultState from './defaultState'

export default {
  [RULES_TABLE_RESET_DEFAULT_STATE](state) {
    // CANNOT Override state here because Vue sets
    // reactivity upon initialization of this store module
    // You WILL lose reactivity if you override state
    const keysToKeep = [
      'tempVisibilityTable',
      'tempEnablementTable',
      'tempRequirementTable',
      'tempSectionVisibilityTable',
      'tempSectionEnablementTable',
      'tempSectionRequirementTable',
      'tempRecommendationsTable',
      'tempFiltersTable',
      'relationshipChildModelId',
      'ruleContext',
      'modelType'
    ]
    const s = defaultState()
    Object.keys(s).forEach(key => {
      if (!keysToKeep.includes(key)) {
        state[key] = s[key]
      }
    })
  },
  [RULES_TABLE_IS_LOADING_RULES](state, isLoadingRules) {
    state.isLoadingRules = isLoadingRules
  },
  [RULES_TABLE_SET_AVAILABLE_BUNDLE_PRODUCTS](state, products) {
    state.availableBundleProducts = products
  },
  [RULES_TABLE_SET_MODEL_TYPE](state, modelType) {
    state.modelType = modelType
  },
  [RULES_TABLE_SET_RULE_CONTEXT](state, context) {
    state.ruleContext = context
  },
  [RULES_TABLE_SET_RULE_USEDIN](state, usedIn) {
    state.ruleUsedIn = usedIn
    localStorage.setItem('ruleUsedIn', state.ruleUsedIn)
  },
  [RULES_TABLE_SET_RELATIONSHIP_CHILD_MODEL_ID](state, modelId) {
    state.relationshipChildModelId = modelId
  },
  [SET_TEMP_SECTION_VISIBILITY_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempSectionVisibilityTable', tempRulesTable === undefined ? deepClone(state.sectionRulesTable) : tempRulesTable)
  },
  [SET_TEMP_SECTION_ENABLEMENT_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempSectionEnablementTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_SECTION_REQUIREMENT_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempSectionRequirementTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_SECTION_SPECIAL_REQUEST_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempSectionSpecialRequestTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_VISIBILITY_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempVisibilityTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_ENABLEMENT_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempEnablementTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_REQUIREMENT_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempRequirementTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_SPECIAL_REQUEST_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempSpecialRequestTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_RECOMMENDATIONS_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempRecommendationsTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [SET_TEMP_FILTERS_TABLE](state, tempRulesTable) {
    Vue.set(state, 'tempFiltersTable', tempRulesTable === undefined ? deepClone(state.rulesTable) : tempRulesTable)
  },
  [RULES_TABLE_SET_NAME_AND_DESCRIPTION](state, { name, description }) {
    state.rulesTable = {
      ...state.rulesTable,
      name,
      description
    }
  },
  [RULES_TABLE_SET_PRODUCT_OUTCOMES](state, products) {
    state.productOutcomes = products
  },
  [RULES_TABLE_SET_CONDITIONS_ENTITIES](state, entities) {
    state.conditionsEntities = entities
  },
  [RULES_TABLE_SET_OUTCOMES_ENTITIES](state, entities) {
    state.outcomesEntities = entities
  },
  [RULES_TABLE_SET_BINDINGS_ENTITIES](state, entities) {
    state.bindingsEntities = entities
  },
  [RULES_TABLE_SET_ENTITIES_DETAILS](state, entitiesDetails) {
    state.entitiesDetails = entitiesDetails
  },
  [RULES_TABLE_SET_MATRIX_ARRAY](state, array) {
    state.rowsLength = array.length
    state.rulesTable.rows = array
  },
  [RULES_TABLE_SET_AVAILABILITY](state, { newValue, id, rowId }) {
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    const valueCell = row.outcomes[0].values.find(x => x.id === id)
    Vue.set(valueCell, 'availability', newValue)
  },
  [RULES_TABLE_SET_LIST_VALUE](state, { rowId, id, value, column, sku }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    const cell = row.conditions[column]

    const cellData = sku ? { id, sku, value } : { id, value }
    if ([OPERATOR_ENUM.Contains, OPERATOR_ENUM.DoesNotContain].includes(cell.operator) || cell.isMultiSelect || cell.conditionType === VALUE_MULTI_SELECT) {
      const index = cell.values.findIndex(value => value.id === id)
      index === -1
        ? cell.values.push(cellData)
        : cell.values.splice(index, 1)
    } else {
      Vue.set(cell, 'values', [ cellData ])
    }
  },
  
  // [RULES_TABLE_SET_BINDING](state, { rowId, id, bindingValue, sku}){
  //   state.isEdited = true
  //   const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
  //   const cell = row.conditions[column]

  //   const cellData = sku ? { id, sku, value } : { id, value }
    
  //   Vue.set(cell, 'values', [ cellData ])
    
  // },
  [RULES_TABLE_SET_CONDITION_ID](state, { rowId, column, id, booleanValue = null }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    if (booleanValue !== null) {
      Vue.set(row.conditions[column], 'booleanValue', booleanValue)
    }
    Vue.set(row.conditions[column], 'value', id)
  },
  [RULES_TABLE_SET_CONDITION_COMPARISON_TYPE](state, { rowId, column, comparisonType }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'comparisonType', comparisonType)
  },
  [RULES_TABLE_SET_CONDITION_BINDING](state, { rowId, column, binding }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'binding', binding)
  },
  [RULES_TABLE_SET_CELL_RANGE_START_VALUE](state, { rowId, column, value }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'lowerBoundValue', value)
  },
  [RULES_TABLE_SET_CELL_RANGE_END_VALUE](state, { rowId, column, value }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'upperBoundValue', value)
  },
  [RULES_TABLE_SET_IGNORE_CASE](state, { rowId, column, value }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    row.conditions[column].ignoreCase = value
  },
  [RULES_TABLE_SET_CELL_RANGE_START_OPERATOR](state, { rowId, column, operatorValue }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'lowerBoundOperator', operatorValue)
  },
  [RULES_TABLE_SET_CELL_RANGE_END_OPERATOR](state, { rowId, column, operatorValue }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    Vue.set(row.conditions[column], 'upperBoundOperator', operatorValue)
  },
  [RULES_TABLE_SET_LIST_ASSIGNMENT](state, { rowId, id, value, outcomeIndex }) {
    state.isEdited = true
    const isMultiSelect = state.rulesTable.outcomes[outcomeIndex].isMultiSelect
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)

    if (isMultiSelect) {
      const index = outcome.values.findIndex(x => x.id === id)
      index === -1
        ? outcome.values.push({ id, value })
        : outcome.values.splice(index, 1)
    } else {
      outcome.values = [{ id, value }]
    }
  },
  [RULES_TABLE_SET_BOOLEAN_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_NUMERIC_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    // if (value === null) {
    //   Vue.set(outcome, 'value', 0) // #14826 See rules-table-assignment-cell.displayValue()
    // } else {
    Vue.set(outcome, 'value', value)
    // }
  },
  [RULES_TABLE_SET_STRING_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    if (value === null) {
      Vue.set(outcome, 'value', ' ')
    } else {
      Vue.set(outcome, 'value', value)
    }
  },
  [RULES_TABLE_SET_DATE_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_VOLUME_ADJUSTMENT_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_ADD_PRODUCTS_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_ADD_SOLUTIONS_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_REMOVE_PRODUCTS_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_REMOVE_SOLUTIONS_ASSIGNMENT](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'value', value)
  },
  [RULES_TABLE_SET_CUSTOM_DATA_TYPE](state, { rowId, value, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'customDataType', value)
  },
  [RULES_TABLE_SET_QUANTITY](state, { rowId, quantity, outcomeIndex }) {
    const quantityNumber = parseInt(quantity)
    if (isNaN(quantityNumber)) return
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    outcome.quantity = parseInt(quantity)
  },
  [RULES_TABLE_SET_QUANTITY_BINDING](state, { rowId, outcomeIndex, binding }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    outcome.quantityBinding = binding
  },
  [RULES_TABLE_SET_QUANTITY_TYPE](state, { rowId, quantityType, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    outcome.quantityType = quantityType
  },
  [RULES_TABLE_SET_OUTCOME_TYPE](state, { rowId, outcomeType, assignmentType, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'outcomeType', outcomeType)

    if (outcomeType === outcomeTypes.NO_VALUE) {
      switch (assignmentType) {
        case 'Numeric':
        case 'String':
        case 'Date':
          Vue.set(outcome, 'value', null)
          break
        case 'List':
          Vue.set(outcome, 'values', [])
          break
        case 'Boolean':
          break
        default:
          Vue.set(outcome, 'value', null)
      }
    }

    if (assignmentType === 'List') return

    if (outcomeType === outcomeTypes.VALUE && !outcome.value) {
      switch (assignmentType) {
        case 'Numeric':
          Vue.set(outcome, 'value', 0)
          break
        case 'String':
          Vue.set(outcome, 'value', '')
          break
        case 'Boolean':
          Vue.set(outcome, 'value', false)
          break
        default:
          Vue.set(outcome, 'value', '')
      }
    }
  },
  [RULES_TABLE_SET_ROW_OBJECT_TYPE](state, { rowId, object, outcomeIndex }) {
    state.isEdited = true
    const outcome = utils.getOutcome(rowId, state.rulesTable.rows, outcomeIndex)
    Vue.set(outcome, 'binding', object.binding)
  },
  [RULES_TABLE_SET_ROW_VALIDITY](state, { rowId, value }) {
    state.isEdited = true
    const row = utils.findRowByRowId(rowId, state.rulesTable.rows)
    row.outcome = value
  },
  [RULES_TABLE_ADD_CONDITION_COLUMN](state, { condition, conditionEntity }) {
    state.isEdited = true
    state.rulesTable.rows.forEach(row => {
      const conditionX = deepClone(condition)
      row.conditions.push(conditionX)
    })
    state.rulesTable.conditions.push(deepClone(conditionEntity))
  },
  [RULES_TABLE_REMOVE_CONDITION_COLUMN](state, id) {
    const index = state.rulesTable.conditions.findIndex(c => c.binding.id === id || c.binding.systemProperty === id || c.binding.customFieldId === id)
    state.isEdited = true
    state.rulesTable.rows.forEach(row => {
      row.conditions.splice(index, 1)
    })

    state.rulesTable.conditions.splice(index, 1)
  },
  [RULES_TABLE_REMOVE_OUTCOME_COLUMN](state, id) {
    const index = state.rulesTable.outcomes.findIndex(c => c.binding.id === id || c.binding.systemProperty === id || c.binding.customFieldId === id)
    state.isEdited = true
    state.rulesTable.rows.forEach(row => {
      row.outcomes.splice(index, 1)
    })

    state.rulesTable.outcomes.splice(index, 1)
  },
  [RULES_TABLE_ADD_OUTCOME_COLUMN](state, { id, modelItemType, entities }) {
    const entity = utils.getEntityById(entities, id, modelItemType)
    const outcomeForHeader = {
      binding: entity.binding,
      isMultiSelect: entity.isMultiSelect,
      modelItemType: modelItemType
    }

    state.isEdited = true
    state.rulesTable.outcomes.push(deepClone(outcomeForHeader))

    const outcomeForRow = utils.getOutcomeForRow(entity)
    
    state.rulesTable.rows.forEach(row => {
      row.outcomes.push(deepClone(outcomeForRow))
    })
  },
  [RULES_TABLE_ADD_ROWS](state, rowsAdded) {
    const emptyConditions = utils.getDefaultRuleItemsForEntities(state.rulesTable.conditions)

    const mapContextToKey = {
      [SALES_BOM]: {
        'List': 'values',
        'Boolean': 'value',
        'Numeric': 'value',
        'String': 'value',
        'Date': 'value'
      },
      [MFG_BOM]: {
        'List': 'values',
        'Boolean': 'value',
        'Numeric': 'value',
        'String': 'value',
        'Date': 'value'
      },
      [MODEL]: {
        'List': 'values',
        'Boolean': 'value',
        'Numeric': 'value',
        'String': 'value',
        'Date': 'value'
      },
      [RECOMMENDATIONS]: 'values',
      [FILTERS]: 'values',
      [BUNDLE_AVAILABILITY]: 'values',
      [SECTION_VISIBILITY]: 'outcome',
      [SECTION_ENABLEMENT]: 'outcome',
      [SECTION_REQUIRED]: 'outcome',
      [CONSTRAINT]: 'outcome',
      [VISIBILITY]: 'outcome',
      [ENABLEMENT]: 'outcome',
      [REQUIRED]: 'outcome'
    }

    const mapContextToValue = {
      [SALES_BOM]: {
        'List': [],
        'Boolean': false,
        'Numeric': 0,
        'String': '',
        'Date': new Date(new Date(Date.now())
          .setUTCHours(0, 0, 0, 0))
          .toISOString()
      },
      [MFG_BOM]: {
        'List': [],
        'Boolean': false,
        'Numeric': 0,
        'String': '',
        'Date': new Date(new Date(Date.now())
          .setUTCHours(0, 0, 0, 0))
          .toISOString()
      },
      [MODEL]: {
        'List': [],
        'Boolean': false,
        'Numeric': 0,
        'String': '',
        'Date': new Date(new Date(Date.now())
          .setUTCHours(0, 0, 0, 0))
          .toISOString()
      },
      [RECOMMENDATIONS]: [],
      [FILTERS]: [],
      [BUNDLE_AVAILABILITY]: getAvailabilityOutcomeValues,
      [SECTION_VISIBILITY]: true,
      [SECTION_ENABLEMENT]: true,
      [SECTION_REQUIRED]: false,
      [CONSTRAINT]: true,
      [VISIBILITY]: true,
      [ENABLEMENT]: true,
      [REQUIRED]: false
    }

    let key, value

    // Multi-level dictionary
    // We needed more flexibility because of assignment dataType
    if (state.ruleContext === MODEL) {
      key = mapContextToKey[state.ruleContext][state.rulesTable.outcomes[0].binding.dataType]
      value = mapContextToValue[state.ruleContext][state.rulesTable.outcomes[0].binding.dataType]
    } else { // Single Level Dictionary
      key = mapContextToKey[state.ruleContext]
      value = mapContextToValue[state.ruleContext]
    }

    const isMultiOutcomeRule = state.ruleContext === MODEL || state.ruleContext === SALES_BOM || state.ruleContext === MFG_BOM

    let outcomes
    if (isMultiOutcomeRule) {
      outcomes = state.rulesTable.outcomes.map(outcome => {
        key = mapContextToKey[state.ruleContext][outcome.binding.dataType]
        value = mapContextToValue[state.ruleContext][outcome.binding.dataType]

        return {
          [key]: value,
          outcomeType: utils.getOutcomeTypeForRow(outcome, outcome.binding.dataType),
          dataType: outcome.binding.dataType,
          customDataType: outcome.binding.customDataType,
          binding: null,
          quantity: 1,
          quantityType: quantityTypes.VALUE,
          quantityBinding: null
        }
      })
    } else if (state.ruleContext === RECOMMENDATIONS || state.ruleContext === FILTERS) {
      outcomes = state.rulesTable.outcomes.map(outcome => {
        key = mapContextToKey[state.ruleContext]
        value = mapContextToValue[state.ruleContext]

        return {
          [key]: value,
          outcomeType: outcomeTypes.VALUE,
          dataType: outcome.binding.dataType,
          binding: null,
          quantity: 1,
          quantityType: quantityTypes.VALUE,
          quantityBinding: null
        }
      })
    } else if (state.ruleContext === BUNDLE_AVAILABILITY) {
      outcomes = state.rulesTable.outcomes.map(outcome => {
        key = mapContextToKey[state.ruleContext]
        const getValues = mapContextToValue[state.ruleContext]

        return {
          dataType: outcome.binding.dataType,
          [key]: getValues(state, outcome)
        }
      })
    }

    for (let i = 0; i < rowsAdded; i++) {
      let newRow
      switch (state.ruleContext) {
        case MODEL:
        case SALES_BOM:
        case MFG_BOM:
        case RECOMMENDATIONS:
        case FILTERS:
        case BUNDLE_AVAILABILITY:
          newRow = { outcomes: deepClone(outcomes), conditions: deepClone(emptyConditions), rowId: generateDecisionMatrixId.getId() }
          break
        default:
          newRow = { [key]: value, conditions: deepClone(emptyConditions), rowId: generateDecisionMatrixId.getId() }
          break
      }

      state.rulesTable.rows.push(newRow)
    }

    state.rowsLength += parseInt(rowsAdded)
  },
  [RULES_TABLE_REMOVE_ROW](state, rowId) {
    // Iterative Binary Search
    const rows = state.rulesTable.rows
    let start = 0
    let end = rows.length - 1

    // Iterate while start not meets end
    while (start <= end) {
      // Find the mid index
      let mid = Math.floor((start + end) / 2)

      // If element is present at mid, return True
      if (rows[mid].rowId === rowId) {
        rows.splice(mid, 1)
        state.rowsLength = state.rowsLength - 1
        break
      } else if (rows[mid].rowId < rowId) {
        start = mid + 1
      } else {
        end = mid - 1
      }
    }
  },
  [RULES_TABLE_SET_ROWS_LENGTH](state, length) {
    state.rowsLength = length
  },
  [RULES_TABLE_SET_CURRENT_RULE](state, rulesTable) {
    state.rulesTable = rulesTable
  },
  [RULES_TABLE_SET_CURRENT_SECTION_RULE](state, rulesTable) {
    state.sectionRulesTable = rulesTable
  },
  [RULES_TABLE_SET_CELL_OPERATOR](state, { rowId, column, operatorValue }) {
    state.isEdited = true
    const { conditions } = utils.findRowByRowId(rowId, state.rulesTable.rows)
    const cell = conditions[column]
    const binding = state.rulesTable.conditions[column].binding
    const id = binding.id || binding.systemProperty || binding.customFieldId
    const { modelItemType } = state.rulesTable.conditions[column]
    const { dataType = VARIABLE_LIST, objectType } = utils.getEntityById(state.entitiesDetails, id, modelItemType)
    const conditionType = utils.getValueTypeByOperatorAndEntityType(dataType, operatorValue, cell.conditionType, cell.conditionType === VALUE_MULTI_SELECT)

    Vue.set(conditions, column, decisionItemFactory({
      objectType,
      id,
      dataType,
      conditionType,
      value: cell.value,
      binding: cell.binding,
      comparisonType: cell.comparisonType,
      operator: operatorValue,
      lowerBoundValue: cell.lowerBoundValue,
      upperBoundValue: cell.upperBoundValue,
      values: cell.values
    }))
  },
  [RULES_TABLE_SET_NEW_OPERATOR_TO_COLUMN](state, { conditionID, value }) {
    state.isEdited = true
    const index = state.rulesTable.conditions.findIndex(entity => entity.binding.id === conditionID || entity.binding.systemProperty === conditionID)
    Vue.set(state.rulesTable.conditions, index, { ...state.rulesTable.conditions[index], operator: value })

    state.rulesTable.rows.forEach(({ conditions }) => {
      const {
        conditionType: vT,
        value: v,
        lowerBoundValue,
        upperBoundValue,
        values
      } = conditions[index]

      const binding = state.rulesTable.conditions[index].binding
      const id = binding.id || binding.systemProperty || binding.customFieldId
      const { modelItemType } = state.rulesTable.conditions[index]

      const { dataType = VARIABLE_LIST, objectType } = utils.getEntityById(state.entitiesDetails, id, modelItemType)
      const conditionType = utils.getValueTypeByOperatorAndEntityType(dataType, value, vT, vT === VALUE_MULTI_SELECT)

      Vue.set(conditions, index, decisionItemFactory({
        objectType,
        id,
        dataType,
        conditionType,
        value: v,
        operator: value,
        lowerBoundValue,
        upperBoundValue,
        values
      }))
    })
  }
}