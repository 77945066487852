// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import {
  MODEL_DESIGNER_SET_MODELS,
  MODEL_DESIGNER_SET_CURRENT_MODEL,
  MODEL_DESIGNER_SET_CURRENT_MODEL_HEADERS,
  MODEL_DESIGNER_SET_CONSTRAINTS,
  MODEL_DESIGNER_SET_CURRENT_FORMULA,
  MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS,
  MODEL_DESIGNER_SET_CURRENT_MODEL_INFO,
  MODEL_DESIGNER_SET_CURRENT_CONSTRAINT,
  MODEL_DESIGNER_SET_OPTIONS_GROUPS,
  MODEL_DESIGNER_SET_METADATA_UIS,
  MODEL_DESIGNER_SET_METADATA,
  MODEL_DESIGNER_SET_METADATA_STEPS,
  MODEL_DESIGNER_SET_METADATA_ACTIVE_STEP_ID,
  MODEL_DESIGNER_SET_METADATA_SECTIONS,
  MODEL_DESIGNER_SET_PARAMETERS,
  MODEL_DESIGNER_SET_CURRENT_PARAMETER,
  MODEL_DESIGNER_SET_CURRENT_VARIABLE,
  MODEL_DESIGNER_SET_BUNDLES,
  MODEL_DESIGNER_SET_EDIT_BUNDLE,
  MODEL_DESIGNER_SET_VARIABLES,
  MODEL_DESIGNER_SET_BOM,
  MODEL_DESIGNER_SET_BOMS,
  MODEL_DESIGNER_SET_CURRENT_BOM,
  MODEL_DESIGNER_SET_BOM_ENTITIES,
  MODEL_DESIGNER_SET_CURRENT_ENTITY,
  MODEL_DESIGNER_SET_ENTITIES,
  MODEL_DESIGNER_DECISION_RULES,
  MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE,
  MODEL_DESIGNER_SET_SELECTED_ENTITY,
  MODEL_DESIGNER_CLEAR_CURRENT_MODEL_DETAILS,
  MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS,
  MODEL_DESIGNER_SET_FORMULAS,
  MODEL_DESIGNER_SET_FORMULAS_SEQUENCE,
  MODEL_DESIGNER_SET_SYSTEM_PROPERTIES,
  MODEL_DESIGNER_SET_INTEGRATIONS,
  MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID,
  MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID,
  MODEL_DESIGNER_SET_GUIDED_UIS,
  MODEL_DESIGNER_SET_CURRENT_GUIDED_UI,
  MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION
} from '@/store/types'

export default {
  [MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID](state, modelId) {
    state.outputsModelId = modelId
  },
  [MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID](state, modelId) {
    state.languagesModelId = modelId
  },
  [MODEL_DESIGNER_SET_INTEGRATIONS](state, integrations) {
    state.integrations = integrations
  },
  [MODEL_DESIGNER_SET_MODELS](state, models) {
    state.models = models
  },
  [MODEL_DESIGNER_SET_EDIT_BUNDLE](state, bundle) {
    state.editingBundle = bundle 
  },
  [MODEL_DESIGNER_SET_CURRENT_FORMULA](state, formula) {
    state.currentFormula = formula
  },
  [MODEL_DESIGNER_SET_CURRENT_MODEL](state, currentModel) {
    state.currentModel = currentModel
  },
  [MODEL_DESIGNER_SET_CURRENT_MODEL_HEADERS](state, { name, description, isMasterModel }) {
    state.currentModelDetails = {
      ...state.currentModelDetails,
      name,
      description,
      isMasterModel
    }
  },
  [MODEL_DESIGNER_SET_CONSTRAINTS](state, constraints) {
    state.constraints = constraints
  },
  [MODEL_DESIGNER_SET_CURRENT_CONSTRAINT](state, currentConstraint) {
    state.currentConstraint = currentConstraint
  },
  [MODEL_DESIGNER_SET_OPTIONS_GROUPS](state, groupOptionsModel) {
    state.groupOptionsModel = groupOptionsModel
  },
  [MODEL_DESIGNER_SET_METADATA_UIS](state, modelUis) {
    state.modelUis = modelUis
  },
  [MODEL_DESIGNER_SET_METADATA](state, metadata) {
    state.metadata = metadata
  },
  [MODEL_DESIGNER_SET_METADATA_SECTIONS](state, sections) {
    const step = state.metadata.steps && state.metadataActiveStepId ? state.metadata.steps.find(step => step.id === state.metadataActiveStepId) : null
    if (step) {
      Vue.set(step, 'sections', sections)
    } else {
      Vue.set(state.metadata, 'sections', sections)
    }
  },
  [MODEL_DESIGNER_SET_METADATA_STEPS](state, steps) {
    state.metadata.steps = steps
  },
  [MODEL_DESIGNER_SET_METADATA_ACTIVE_STEP_ID](state, activeStepId) {
    state.metadataActiveStepId = activeStepId
  },
  [MODEL_DESIGNER_SET_BUNDLES](state, bundles) {
    state.bundles = bundles
  },
  [MODEL_DESIGNER_SET_VARIABLES](state, variables) {
    state.variables = variables
  },
  [MODEL_DESIGNER_SET_CURRENT_VARIABLE](state, currentVariable) {
    state.currentVariable = currentVariable
  },
  [MODEL_DESIGNER_SET_BOM](state, currentBom) {
    state.currentBom = currentBom
  },
  [MODEL_DESIGNER_SET_CURRENT_BOM](state, currentBom) {
    state.currentBom = currentBom
  },
  [MODEL_DESIGNER_SET_BOMS](state, boms) {
    state.boms = boms
  },
  [MODEL_DESIGNER_SET_BOM_ENTITIES](state, bomEntities) {
    state.bomEntities = bomEntities
  },
  [MODEL_DESIGNER_DECISION_RULES](state, decisionRules) {
    state.decisionRules = decisionRules
  },
  [MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE](state, decisionRulesSequence) {
    state.decisionRulesSequence = decisionRulesSequence
  },
  [MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS](state, details) {
    state.currentModelDetails = details
  },
  [MODEL_DESIGNER_CLEAR_CURRENT_MODEL_DETAILS](state) {
    state.currentModelDetails = null
  },
  [MODEL_DESIGNER_SET_CURRENT_MODEL_INFO](state, modelInfo) {
    state.currentModelInfo = modelInfo
  },
  [MODEL_DESIGNER_SET_CURRENT_ENTITY](state, entity) {
    state.currentEntity = entity
  },
  [MODEL_DESIGNER_SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MODEL_DESIGNER_SET_SELECTED_ENTITY](state, id, selectedBoolean) {
    const entity = state.entities.find(entity => entity.id === id) || {}
    Vue.set(entity, 'selected', selectedBoolean)
  },
  [MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS](state, { constraintId, isEnabled }) {
    const constraintIndex = state.constraints.findIndex(constraint => constraint.id === constraintId)
    state.constraints[constraintIndex].isEnabled = isEnabled
  },
  [MODEL_DESIGNER_SET_PARAMETERS](state, parameters) {
    state.parameters = parameters
  },
  [MODEL_DESIGNER_SET_CURRENT_PARAMETER](state, currentParameter) {
    state.currentParameter = currentParameter
  },
  [MODEL_DESIGNER_SET_FORMULAS](state, formulas) {
    state.formulas = formulas
  },
  [MODEL_DESIGNER_SET_FORMULAS_SEQUENCE](state, formulasSequence) {
    state.formulasSequence = formulasSequence
  },
  [MODEL_DESIGNER_SET_SYSTEM_PROPERTIES](state, systemProperties) {
    state.systemProperties = systemProperties
  },
  [MODEL_DESIGNER_SET_GUIDED_UIS](state, uis) {
    state.guidedUis = uis
  },
  [MODEL_DESIGNER_SET_CURRENT_GUIDED_UI](state, ui) {
    state.currentGuidedUI = ui
  },
  [MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION](state, precision) {
    state.precision = precision
  }
}
