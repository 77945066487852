// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import { ERROR_CODE_TO_ERROR } from '../constants/errorCodes'

export function getLocizeErrorsFromErrorResponse({ data, headers, status }, t) {
  let errorList = []
  if (status === 404) {
    const error = ERROR_CODE_TO_ERROR[404]
    errorList.push(getErrorTranslationObject(error.name, error.code, error.locizeKey, {}, '', t))
  }
  if (data && typeof data.errors === 'object') {
    data.errors.forEach(({ code, additionalInfo, message }) => {
      const error = ERROR_CODE_TO_ERROR[code]
      if (code === -1) {
        errorList.push(getErrorTranslationObject(error.name, error.code, error.locizeKey, { correlationId: headers.correlationid }, message, t))
      } else if (error !== undefined) {
        errorList.push(getErrorTranslationObject(error.name, error.code, error.locizeKey, getParsedVariables(additionalInfo), message, t))
      } else {
        errorList.push(getErrorTranslationObject('unknown', code, 'unknown_error', getParsedVariables(additionalInfo), message, t))
      }
    })
  } else {
    // if data is string
    errorList.push(getErrorTranslationObject('unknown', 0, 'unknown_error', [], data, t))
  }
  return errorList
}

export function getLocizeErrorsFromErrorObject(errorObject, t, filterCount = 0) {
  let errorList = []
  if (typeof errorObject.errors === 'object') {
    errorObject.errors.forEach(({ code, additionalInfo, message }) => {
      const error = ERROR_CODE_TO_ERROR[code]
      const errorMessage = error !== undefined && code !== -1
        ? getErrorTranslationObject(error.name, error.code, error.locizeKey, getParsedVariables(additionalInfo), message, t).translatedText
        : message
      errorList.push(errorMessage)
    })
  } else {
    errorList.push(errorObject.requestName + ': ' + errorObject.message)
  }
  return errorList
}

function getParsedVariables(additionalOptions) {
  if (typeof additionalOptions === 'string') {
    return {
      additionalOptions: additionalOptions
    }
  } else {
    return additionalOptions
  }
}

function getErrorTranslationObject(name, code, locizeKey, variables, defaultText, t) {
  let variablesModified = {}

  // this is to fix an issue where the backend passes data in true camal case "MaxFileSize"
  // and we need it as "maxFileSize"
  Object.keys(variables).forEach(variable => {
    variablesModified[toLowerCamelCase(variable)] = variables[variable]
  })

  // adding additional properties that can be used when translating
  variablesModified['defaultMsgText'] = defaultText
  variablesModified['errorCode'] = code

  // perform the translation
  const rowAndColumnText = variablesModified.row ? t('error_row') + ': ' + variablesModified.row + ' ' + t('error_column') + ': ' + variablesModified.column : ' '
  let translatedText = t(locizeKey, variablesModified) + ' ' + rowAndColumnText + '<br/><br/>'
  Object.keys(variables).forEach(key => {
    if (variables[key] && variables[key].trim()) {
      translatedText += variables[key] + '<br/>'
    }
  })
  // Prevent untranslated message - Unknown Error ({errorCode}): {defaultMsgText} 
  translatedText = translatedText.replace('{{defaultMsgText}}', defaultText).replace('{defaultMsgText}', defaultText)
  translatedText = translatedText.replace('{{errorCode}}', code).replace('{errorCode}', code)

  // return a structured translated text
  return {
    translatedText: translatedText,
    defaultText: defaultText,
    locizeKey: locizeKey,
    variables: variablesModified,
    code: code,
    name: name
  }
}

function toLowerCamelCase(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}
