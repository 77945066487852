// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { mapActions, mapGetters } from 'vuex'
import SimpleDropdown from '@/common/form-controls/dropdowns/simple-dropdown'
import Toast from '@/common/popups-and-indicators/toast'
import { blurInmplayer, unBlurInmplayer } from '@/common/utils/modalBlur'
import featureFlagMixin from '@/mixins/featureFlagMixin'

export default {
  name: 'BaseCurrencyModal',
  props: {
    refreshCurrencies: { type: Function, default: () => { } }
  },
  components: {
    SimpleDropdown,
    Toast
  },
  mixins: [ featureFlagMixin ],
  data() {
    return {
      dialogStatus: null,
      isProcessing: false,
      currency: {
        id: null,
        code: null,
        name: null,
        exchangeRate: null,
        precision: null,
        dateEffective: null,
        isBase: false,
        isActive: true
      }
    }
  },
  async created() {
    if (this.currencies.find(currency => currency.isBase)) this.currency = this.currencies.find(currency => currency.isBase)
  },
  mounted() {
    blurInmplayer()
  },
  destroyed() {
    unBlurInmplayer()
  },
  computed: {
    ...mapGetters({
      currencies: 'getCurrencies'
    }),
    currenciesNamesWithCodes() {
      return this.currencies.map(currency => {
        const currencyNameWithCode = { ...currency }
        currencyNameWithCode.originalName = currencyNameWithCode.originalName || currencyNameWithCode.name
        currencyNameWithCode.name = currencyNameWithCode.originalName + ' (' + currencyNameWithCode.code + ')'
        return currencyNameWithCode
      }).filter(currency => currency.isActive)
    }
  },
  methods: {
    ...mapActions([
      'updateCurrency'
    ]),
    setSelectedCurrency(event) {
      this.currency = event
    },
    saveCurrency() {
      this.isProcessing = true
      this.currency.isBase = true
      this.currency.name = this.currency.originalName || this.currency.name
      this.updateCurrency({ payload: this.currency })
        .then(() => {
          this.refreshCurrencies()
          this.$emit('close')
        })
        .catch(error => {
          this.refreshCurrencies()
          this.dialogStatus = {
            type: 'error',
            message: '<b>' + this.$t('base-currency_save-error_message', 'Error setting base currency to tenant') + '</b>',
            additionalInfo: error.response && error.response.data && error.response.data.errors ? error.response.data.errors.map(error => error.message).join('\n') : error.response.data
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    setSearchFocus() {
      this.$nextTick(() => {
        if (document.querySelector('input.dropdown-header-textbox__input')) document.querySelector('input.dropdown-header-textbox__input').focus()
      })
    }
  }
}
