// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import * as contexts from '@/constants/ruleTableTypes'

export default function getBuildVariationsRouteByContext({ context, tenantId, relationshipChildModelId, modelId, entityId, bundleId, groupId, decisionRuleId, constraintId, bomId, isFeatureEnabledBOM }) {
  switch (context) {
    case contexts.SECTION_VISIBILITY:
    case contexts.SECTION_ENABLEMENT:
    case contexts.SECTION_REQUIRED:
    case contexts.VISIBILITY:
    case contexts.ENABLEMENT:
    case contexts.RENDERING:
    case contexts.REQUIRED:
      return `/tenants/${tenantId}/models/${modelId}/boolean-decision-rules/variations`
    case contexts.MODEL:
      return `/tenants/${tenantId}/models/${modelId}/decision-rules/${decisionRuleId}/variations`
    case contexts.SALES_BOM:
      return isFeatureEnabledBOM
        ? `tenants/${tenantId}/models/${modelId}/boms/${bomId}/decision-rules/${entityId}/variations`
        : `tenants/${tenantId}/models/${modelId}/bom-item-decision-rules/${entityId}/variations`
    case contexts.MFG_BOM:
      return `tenants/${tenantId}/models/${modelId}/bom-item-decision-rules/${entityId}/variations`
    case contexts.CONSTRAINT:
      return `/tenants/${tenantId}/models/${modelId}/constraint-tables/${constraintId}/variations`
    case contexts.RECOMMENDATIONS:
    case contexts.FILTERS:
      return `/tenants/${tenantId}/models/${modelId}/relationships/${relationshipChildModelId}/product-decision-rules/variations`
    case contexts.BUNDLE_AVAILABILITY:
      return `/tenants/${tenantId}/models/${modelId}/bundles/${bundleId}/availabilities/${groupId}/variations`
    default:
      return undefined
  }
}