// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
export const ERROR_CODE_TO_ERROR = {
  constraint: {
    6: {
      name: 'constraintName',
      code: 6,
      locizeKey: 'error_constraint_name_duplicate'
    },
    1521: {
      name: 'invalidDataPoint',
      code: 1521,
      locizeKey: 'error_multi-select-not-supported'
    }
  },
  customField: {
    1016: {
      name: 'customFieldName',
      code: 1016,
      locizeKey: 'error_custom_field_name_duplicate'
    },
    1017: {
      name: 'customFieldNotFound',
      code: 1017,
      locizeKey: 'error_custom_field_not-found'
    },
    1018: {
      name: 'invalidTextFieldMaxLength ',
      code: 1018,
      locizeKey: 'error_invalid-text-field-max-length'
    },
    1019: {
      name: 'defaultValue',
      code: 1019,
      locizeKey: 'error_custom_field_default_value_exceeds_max_length'
    },
    1023: {
      name: 'invalidNumericCustomFieldValue',
      code: 1023,
      locizeKey: 'error_invalid-numeric-customfield-value'
    },
    1026: {
      name: 'defaultValue',
      code: 1026,
      locizeKey: 'error_custom_field_default_value_exceeds_decimal'
    },
    1027: {
      name: 'defaultValue',
      code: 1027,
      locizeKey: 'error_custom_field_default_value_exceeds_integer'
    },
    1037: {
      name: 'duplicateListItem',
      code: 1037,
      locizeKey: 'error_custom_field_duplicate_list_item_invalid'
    }
  },
  model: {
    6: {
      name: 'optionGroup',
      code: 6,
      locizeKey: 'error_option_group_duplicate'
    }
  },
  parameter: {
    6: {
      name: 'parameterName',
      code: 6,
      locizeKey: 'parameter_duplicate-name_error-message'
    }
  },
  integrations: {
    6: {
      name: 'integrationName',
      code: 6,
      locizeKey: 'integration_duplicate-name_error-message'
    }
  },
  variable: {
    6: {
      name: 'variableName',
      code: 6,
      locizeKey: 'duplicate_variable_name_error'
    },
    '-1': {
      name: 'variableName',
      code: -1,
      locizeKey: 'error_general_server'
    },
    3: {
      name: 'variableName',
      code: 3,
      locizeKey: 'duplicate_variable_name_error'
    },
    10: {
      name: 'variableInvalidDelete',
      code: 10,
      locizeKey: 'invalid_variable_delete_error'
    },
    1600: {
      name: 'variableIntegration',
      code: 1600,
      locizeKey: 'variable_has_integration_error'
    },
    1601: {
      name: 'variableConstraint',
      code: 1601,
      locizeKey: 'variable_has_constraint_error'
    },
    1602: {
      name: 'variableIsCondition',
      code: 1602,
      locizeKey: 'variable_is_condition_in_decision_error'
    },
    1603: {
      name: 'variableParentOfDecisionRule',
      code: 1603,
      locizeKey: 'variable_is_parent_of_decision_error'
    },
    1604: {
      name: 'variableRelatedSections',
      code: 1604,
      locizeKey: 'variable_has_related_sections_error'
    },
    1605: {
      name: 'variableInFormulaExpression',
      code: 1605,
      locizeKey: 'variable_in_formula_expression_error'
    },
    1606: {
      name: 'variableParentOfSharedItem',
      code: 1606,
      locizeKey: 'variable_is_parent_of_shared_item_error'
    },
    1607: {
      name: 'variableChildOfSharedItem',
      code: 1607,
      locizeKey: 'variable_is_child_of_shared_item_error'
    }
  },
  formulaEditor: {
    5000: {
      name: 'invalidCharacter',
      code: 5000,
      locizeKey: 'formula-error_invalid-character'
    },
    5001: {
      name: 'OnlyOneDotAllowedInDecimal',
      code: 5001,
      locizeKey: 'formula-error_only-one-dot-allowed-in-decimal'
    },
    5002: {
      name: 'FailedToCreateNumericValToken',
      code: 5002,
      locizeKey: 'formula-error_failed-to-create-numeric-val-token'
    },
    5003: {
      name: 'MustHaveOperandAfterOperator',
      code: 5003,
      locizeKey: 'formula-error_must-have-operand-after-operator'
    },
    5004: {
      name: 'DataTypeNotSupportedInFormulas',
      code: 5004,
      locizeKey: 'formula-error_datatype-not-supported-in-formulas'
    },
    5005: {
      name: 'missingSquareBracket',
      code: 5005,
      locizeKey: 'formula-error_missing-square-bracket'
    },
    5006: {
      name: 'NumberOfParametersOutOfRange',
      code: 5006,
      locizeKey: 'formula-error_number-of-parameters-out-of-range'
    },
    5007: {
      name: 'InvalidParameters',
      code: 5007,
      locizeKey: 'formula-error_invalid-parameters'
    },
    5008: {
      name: 'InvalidNumberOfParameters',
      code: 5008,
      locizeKey: 'formula-error_invalid-number-of-parameters'
    },
    5009: {
      name: 'OnlyAllowedSingleOpeningAndClosingBracketPerTag',
      code: 5009,
      locizeKey: 'formula-error_only-allowed-single-opening-and-closing-bracket-per-tag'
    },
    5010: {
      name: 'UnknownTagError',
      code: 5010,
      locizeKey: 'formula-error_unknown-tag-error'
    },
    5011: {
      name: 'IntegrationNonExistent',
      code: 5011,
      locizeKey: 'formula-error_integration-nonexistent'
    },
    5012: {
      name: 'SuppliedTagIsNotSupported',
      code: 5012,
      locizeKey: 'formula-error_supplied-tag-is-not-supported'
    },
    5013: {
      name: 'AtLeastOneParameterRequired',
      code: 5013,
      locizeKey: 'formula-error_at-least-one-parameter-required'
    },
    5014: {
      name: 'UnbalancedParenthesis ',
      code: 5014,
      locizeKey: 'formula-error_unbalanced-parenthesis'
    },
    5015: {
      name: 'GroupNamedNonExistent ',
      code: 5015,
      locizeKey: 'formula-error_group-named-nonexistent'
    },
    5016: {
      name: 'FailedToCreateStringValToken',
      code: 5016,
      locizeKey: 'formula-error_failed-to-create-string-val-token'
    },
    5017: {
      name: 'ExceedsMaxAmountOfColonPerTag',
      code: 5017,
      locizeKey: 'formula-error_exceeds-max-amount-of-colon-per-tag'
    },
    5018: {
      name: 'NotSuppliedMinAmountOfColonPerTag',
      code: 5018,
      locizeKey: 'formula-error_not-supplied-min-amount-of-colon-per-tag'
    },
    5019: {
      name: 'MissingTagNameAfterSeparator',
      code: 5019,
      locizeKey: 'formula-error_missing-tag-name-after-separator'
    },
    5020: {
      name: 'InvalidBooleanMember',
      code: 5020,
      locizeKey: 'formula-error_invalid-boolean-member'
    },
    5021: {
      name: 'VariableNamedNonExistent',
      code: 5021,
      locizeKey: 'formula-error_variable-named-nonexistent'
    },
    5022: {
      name: 'ParameterNamedNonExistent',
      code: 5022,
      locizeKey: 'formula-error_parameter-named-nonexistent'
    },
    5023: {
      name: 'CustomFieldNamedNonExistent',
      code: 5023,
      locizeKey: 'formula-error_custom-field-named-nonexistent'
    },
    5024: {
      name: 'CustomFieldTagMustHaveSecondColonAndGroupName',
      code: 5024,
      locizeKey: 'formula-error_custom-field-tag-must-have-second-colon-and-group-name'
    },
    5025: {
      name: 'InvalidFunctionParameterDataType',
      code: 5025,
      locizeKey: 'formula-error_invalid-function-parameter-data-type'
    },
    5026: {
      name: 'ActualParameterSequenceDoesNotMatchExpected',
      code: 5026,
      locizeKey: 'formula-error_actual-parameter-sequence-does-not-match-expected'
    },
    5028: {
      name: 'ProductCustomFieldMustBeInAggregateFunction',
      code: 5028,
      locizeKey: 'formula-error_product-custom-field-must-be-in-aggregate-function'
    },
    5029: {
      name: 'LineIntegrationMustBeInAggregateFunction',
      code: 5029,
      locizeKey: 'formula-error_line-integration-must-be-in-aggregate-function'
    },
    5030: {
      name: 'SystemPropertyMustBeInAggregateFunction',
      code: 5030,
      locizeKey: 'formula-error_system-property-must-be-in-aggregate-function'
    }
  },
  currency: {
    'aa': {
      name: 'missingCurrencyCode',
      code: 'aa',
      locizeKey: 'error_missing-currency-code'
    },
    'bb': {
      name: 'missingCurrencyName',
      code: 'bb',
      locizeKey: 'error_missing-currency-name'
    },
    'cc': {
      name: 'missingCurrencyDate',
      code: 'cc',
      locizeKey: 'error_missing-currency-date'
    },
    'dd': {
      name: 'duplicateCurrencyDate',
      code: 'dd',
      locizeKey: 'error_duplicate-currency-date'
    },
    1064: {
      name: 'duplicateCurrencyName',
      code: 1064,
      locizeKey: 'error_duplicate-currency-name'
    }
  },
  '-1': {
    name: 'generalError',
    code: -1,
    locizeKey: 'error_general_server'
  },
  4: {
    name: 'invalid_file',
    code: 4,
    locizeKey: 'invalid_file_format_error'
  },
  5: {
    name: 'modelNotFound',
    code: 5,
    locizeKey: 'error_model-not-found'
  },
  8: {
    name: 'file_limit',
    code: 8,
    locizeKey: 'file_size_limit_exceeded_error'
  },
  // 12: {}, "message": "Invalid cell data format." For later if we implement upload DecisionRules via Excel on the FE
  // 13: {}, "message": "Invalid header metadata."  Same ^^^
  1000: {
    name: 'sku',
    code: 1000,
    locizeKey: 'priced-item_duplicate-sku_error'
  },
  1001: {
    name: 'name',
    code: 1001,
    locizeKey: 'error_product_name_duplicate'
  },
  1002: {
    name: 'optionCode',
    code: 1002,
    locizeKey: 'error_option_code_duplicate'
  },
  1003: {
    name: 'productId',
    code: 1003,
    locizeKey: 'error_product-does-not-exist'
  },
  1004: {
    name: 'groupNotFound',
    code: 1004,
    locizeKey: 'error_group-not-found'
  },
  1005: {
    name: 'optionNotFound',
    code: 1005,
    locizeKey: 'error_option-not-found'
  },
  1006: {
    name: 'imageNotFound',
    code: 1006,
    locizeKey: 'error_image-not-found'
  },
  1007: {
    name: 'currencyNotFound',
    code: 1007,
    locizeKey: 'error_currency-not-found'
  },
  1008: {
    name: 'duplicateCurrencyCode',
    code: 1008,
    locizeKey: 'error_duplicate-currency-code'
  },
  1009: {
    name: 'priceListNotFound',
    code: 1009,
    locizeKey: 'error_price-list-not-found'
  },
  1010: {
    name: 'priceName',
    code: 1010,
    locizeKey: 'error_price_list_name_duplicate'
  },
  1011: {
    name: 'invalidDateRange',
    code: 1011,
    locizeKey: 'error_invalid-date-range'
  },
  1012: {
    name: 'fileUploadInvalidData',
    code: 1012,
    locizeKey: 'error_file_upload_invalid-data'
  },
  1013: {
    name: 'dataTruncated',
    code: 1013,
    locizeKey: 'error_data-truncated'
  },
  1014: {
    name: 'requireFieldNotProvided',
    code: 1014,
    locizeKey: 'error_required-field-value-not-provided'
  },
  1020: {
    name: 'invalidTextAreaNumberOfLines',
    code: 1020,
    locizeKey: 'error_invalid-textarea-number-of-lines'
  },
  1021: {
    name: 'invalidBooleanCustomFieldValue',
    code: 1021,
    locizeKey: 'error_invalid-boolean-custom-field-value'
  },
  1022: {
    name: 'invalidDateCustomFieldValue',
    code: 1022,
    locizeKey: 'error_invalid-date-custom-field-value'
  },
  1024: {
    name: 'invalidNumberOfDecimalPlaces',
    code: 1024,
    locizeKey: 'error_invalid-number-of-decimal-places'
  },
  1025: {
    name: 'invalidNumberOfIntegerPlaces',
    code: 1025,
    locizeKey: 'error_invalid-number-of-integer-places'
  },
  1058: {
    name: 'maximumLengthExceeded',
    code: 1058,
    locizeKey: 'error_file_upload_maximum_length_exceeded'
  },
  1043: {
    name: 'invalidGroupPricedItemType',
    code: 1043,
    locizeKey: 'error_file_upload_invalid-group-priced-item-type'
  },
  1028: {
    name: 'fileUpload',
    code: 1028,
    locizeKey: 'error_file_upload_required-custom-field-value-not-provided'
  },
  1029: {
    name: 'groupDeletion',
    code: 1029,
    locizeKey: 'error_group_deletion'
  },
  1030: {
    name: 'groupDeletion',
    code: 1030,
    locizeKey: 'error_groups_deletion'
  },
  1034: {
    name: 'defaultPriceListCannotBeDeleted',
    code: 1034,
    locizeKey: 'error_default-price-list-cannot-be-deleted'
  },
  1039: {
    name: 'fileUploadInvalidImportColumn',
    code: 1039,
    locizeKey: 'error_invalid-import-column'
  },
  1042: {
    name: 'name',
    code: 1042,
    locizeKey: 'error_duplicate-group-added'
  },
  1054: {
    name: 'priceListItemGroup',
    code: 1054,
    locizeKey: 'error_price-list-item-group'
  },
  1055: {
    name: 'invalidListPrice',
    code: 1055,
    locizeKey: 'error_invalid-list-price'
  },
  1056: {
    name: 'priceListImportFail',
    code: 1056,
    locizeKey: 'error_price-list-import-failed'
  },
  1057: {
    name: 'invalidImportTemplate',
    code: 1057,
    locizeKey: 'error_invalid-import-template'
  },
  1067: {
    name: 'currencyInUse',
    code: 1067,
    locizeKey: 'error_currency-in-use'
  },
  1500: {
    name: 'modelName',
    code: 1500,
    locizeKey: 'error_model_name_duplicate'
  },
  1501: {
    name: 'tooManyVariations',
    code: 1501,
    locizeKey: 'error_too_many_variations'
  },
  1510: {
    name: 'duplicateEntity',
    code: 1510,
    locizeKey: 'error_duplicate_entity'
  },
  1514: {
    name: 'duplicateSharedItem',
    code: 1514,
    locizeKey: 'error_duplicate-shared-item'
  },
  1516: {
    name: 'duplicateDecisionRule',
    code: 1516,
    locizeKey: 'decision-rule_duplicate-name_error-message'
  },
  1518: {
    name: 'invalidDefaultInstances',
    code: 1518,
    locizeKey: 'relationships_invalid-default-instances_error'
  },
  1519: {
    name: 'invalidDefaultSkuIds',
    code: 1519,
    locizeKey: 'relationships_invalid-default-sku-ids_error'
  },
  1523: {
    name: 'configuredProductIsRequired',
    code: 1523,
    locizeKey: 'assortments_configured-product-is-required_error-message'
  },
  1524: {
    name: 'configuredProductDoesNotExist',
    code: 1524,
    locizeKey: 'assortments_configured-product-does-not-exist_error-message'
  },
  1525: {
    name: 'groupIsRequired',
    code: 1525,
    locizeKey: 'assortments_group-is-required_error-message'
  },
  1526: {
    name: 'groupDoesNotExist',
    code: 1526,
    locizeKey: 'assortments_group-does-not-exist_error-message'
  },
  1527: {
    name: 'optionIsRequired',
    code: 1527,
    locizeKey: 'assortments_option-is-required_error-message'
  },
  1528: {
    name: 'optionDoesNotExist',
    code: 1528,
    locizeKey: 'assortments_option-does-not-exist_error-message'
  },
  1529: {
    name: 'invalidAssortmentType',
    code: 1529,
    locizeKey: 'assortments_invalid-assortment-type_error-message'
  },
  1530: {
    name: 'cannotHaveMultipleStandardAssortments',
    code: 1530,
    locizeKey: 'assortments_cannot-have-multiple-standard-assortments_error-message'
  },
  1531: {
    name: 'duplicateConfiguredProduct',
    code: 1531,
    locizeKey: 'assortments_duplicate-configured-product_error-message'
  },
  1532: {
    name: 'duplicateGroup',
    code: 1532,
    locizeKey: 'assortments_duplicate-group_error-message'
  },
  1533: {
    name: 'duplicateBundleName',
    code: 1533,
    locizeKey: 'bundle_duplicate-name_error-message'
  },
  1537: {
    name: 'invalidOption',
    code: 1537,
    locizeKey: 'assortments_invalid-option_error-message'
  },
  1538: {
    name: 'duplicateOption',
    code: 1538,
    locizeKey: 'assortments_duplicate-option_error-message'
  },
  1542: {
    name: 'modelProductIsRequired',
    code: 1542,
    locizeKey: 'model_product-is-required_error-message'
  },
  1543: {
    name: 'modelProductGroupIsRequired',
    code: 1543,
    locizeKey: 'model_product-group-is-required_error-message'
  },
  // 1700 - 1799 ==> Configurator error codes
  1700: {
    name: 'formulaInvalidValue',
    code: 1700,
    locizeKey: 'error_attempt_to_resolve_member_with_invalid_value'
  },
  1701: {
    name: 'formulaInvalidDataType',
    code: 1701,
    locizeKey: 'error_outcome_data_type_does_not_match_parent_data_type'
  },
  1702: {
    name: 'formulaUnsupportedParent',
    code: 1702,
    locizeKey: 'error_obsolete_formula_member'
  },
  1703: {
    name: 'formulaUnsupportedBoundMemberDataType',
    code: 1703,
    locizeKey: 'error_unsupported-bound-member-data-type'
  },
  3003: {
    name: 'invalidAzureDomainName',
    code: 3003,
    locizeKey: 'error_invalid-azure-domain-name'
  },
  3005: {
    name: 'integrationDoesNotExist',
    code: 3005,
    locizeKey: 'error_integration-does-not-exist'
  },
  3006: {
    name: 'integrationNameAlreadyExists',
    code: 3006,
    locizeKey: 'error_integration-already-exists'
  },
  3007: {
    name: 'integrationUrlAlreadyExists',
    code: 3007,
    locizeKey: 'error_integration-url-already-exists'
  },
  3008: {
    name: 'tenantDoesNotExist',
    code: 3008,
    locizeKey: 'error_tenant_does_not_exist'
  },
  3009: {
    name: 'userDoesNotExist',
    code: 3009,
    locizeKey: 'error_user_does_not_exist'
  },
  3010: {
    name: 'userAlreadyExists',
    code: 3010,
    locizeKey: 'error_user_already_exists'
  },
  404: {
    name: 'notFound',
    code: 404,
    locizeKey: 'server-error_404-not-found_message'
  },
  3022: {
    name: 'invalidImportPermissions',
    code: 3022,
    locizeKey: 'users_invalid-import-permissions_error-message'
  },
  3028: {
    name: 'apiPermissionDirectoryReadAllRequired',
    code: 3028,
    locizeKey: 'error_api-permission-directory-read-all-required'
  },
  1547: {
    name: 'duplicateGuidedUI',
    code: 1547,
    locizeKey: 'guided-ui_duplicate-name_error-message'
  },
  25024: {
    name: 'duplicateReseller',
    code: 25024,
    locizeKey: 'users_duplicate-reseller_error-message'
  },
  150003: {
    name: 'incorrectLanguageCode',
    code: 150003,
    locizeKey: 'translation_incorrect-language-code_error-message'
  },
  150005: {
    name: 'incorrectCultureCode',
    code: 150005,
    locizeKey: 'translation_incorrect-culture-code_error-message'
  },
  150007: {
    name: 'cannotDeleteDefault',
    code: 150007,
    locizeKey: 'languages_cannot-delete-default_error-message'
  },
  150013: {
    name: 'defaultLanguageDoesNotExist',
    code: 150013,
    locizeKey: 'languages_default-language-does-not-exist-message'
  }
}