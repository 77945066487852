// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED                      

import { entityMap } from '@/utils/ruleUtils'

// only for rules table
export default function getModelItemsForRules(table, addedEntities = [], includeProducts = false) {
  let payload = {
    parameters: [],
    headerIntegrations: [],
    lineIntegrations: [],
    configuredProductGroups: [],
    basicProductGroups: [],
    optionGroups: [],
    bomItemGroups: [],
    variables: [],
    systemGroups: [],
    systemProperties: [],
    systemObjects: [],
    productCustomFields: [],
    customFields: [],
    products: [],
    relationships: [],
    adjustments: [],
    optionCustomFields: [],
    optionSystemProperties: [],
    bomSections: [],
    includeProducts: includeProducts
  }

  const { conditions = [], outcomes = [] } = (table || {})

  conditions.forEach(({ modelItemType, binding }) => {
    const id = binding.id || binding.systemProperty || binding.customFieldId
    if (payload[entityMap[modelItemType]]) {
      payload[entityMap[modelItemType]].push(id)
    }
  })

  outcomes.forEach(({ modelItemType, binding }) => {
    const id = binding.id || binding.systemProperty || binding.customFieldId
    if (payload[entityMap[modelItemType]]) {
      payload[entityMap[modelItemType]].push(id)
    }
  })

  addedEntities.forEach(({ id, modelItemType }) => {
    if (payload[entityMap[modelItemType]]) {
      payload[entityMap[modelItemType]].push(id)
    }
  })

  return payload
}