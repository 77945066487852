// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
export default function defaultState() {
  return {
    availableBundleProducts: [],
    rowsLength: 4,
    assignment: {}, // our current assignment
    sectionRulesTable: {},
    rulesTable: {}, // current decision rule containing matrix
    error: '', // error associated with table
    conditionsEntities: {},
    outcomesEntities: {},
    bindingsEntities: {},
    entitiesDetails: {},
    productOutcomes: [],
    relationshipChildModelId: '',
    ruleContext: '',
    ruleUsedIn: '',
    modelType: '',
    isEdited: false,
    isLoadingRules: true,
    tempSectionVisibilityTable: { conditions: [], rows: [{ outcome: true, conditions: [], rowId: 0 }] },
    tempSectionEnablementTable: { conditions: [], rows: [{ outcome: true, conditions: [], rowId: 0 }] },
    tempSectionRequirementTable: { conditions: [], rows: [{ outcome: false, conditions: [], rowId: 0 }] },
    tempVisibilityTable: { conditions: [], rows: [{ outcome: true, conditions: [], rowId: 0 }] }, // table that is being modified in session
    tempEnablementTable: { conditions: [], rows: [{ outcome: true, conditions: [], rowId: 0 }] },
    tempRequirementTable: { conditions: [], rows: [{ outcome: false, conditions: [], rowId: 0 }] },
    tempRecommendationsTable: {
      outcomes: [
        {
          isMultiSelect: true,
          binding: {
            bindingType: 'Products',
            dataType: 'List'
          },
          modelItemType: 'Products'
        }
      ],
      conditions: [],
      rows: [
        {
          outcomes: [
            { values: [], outcomeType: 'Value', dataType: 'List', binding: null, quantity: 1, quantityType: 'Value', quantityBinding: null }
          ],
          conditions: [],
          rowId: 0
        }
      ]
    },
    tempFiltersTable: {
      outcomes: [
        {
          isMultiSelect: true,
          binding: {
            bindingType: 'Products',
            dataType: 'List'
          },
          modelItemType: 'Products'
        }
      ],
      conditions: [],
      rows: [
        {
          outcomes: [
            { values: [], outcomeType: 'Value', dataType: 'List', binding: null, quantity: 1, quantityType: 'Value', quantityBinding: null }
          ],
          conditions: [],
          rowId: 0
        }
      ]
    }
  }
}