// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED

// Translation
export const TRANSLATION_SET_TOTAL_LANGUAGES = 'TRANSLATION_SET_TOTAL_LANGUAGES'
export const TRANSLATION_SET_AVAILABLE_TRANSLATIONS = 'TRANSLATION_SET_AVAILABLE_TRANSLATIONS'
export const TRANSLATION_SET_ACTUAL_TRANSLATIONS = 'TRANSLATION_SET_ACTUAL_TRANSLATIONS'
export const TRANSLATION_SET_TRANSLATED_PERCENTAGE = 'TRANSLATION_SET_TRANSLATED_PERCENTAGE' 
export const TRANSLATION_SET_LANGUAGE_LIST = 'TRANSLATION_SET_LANGUAGE_LIST'
export const TRANSLATION_SET_TIMEZONE_LIST = 'TRANSLATION_SET_TIMEZONE_LIST'
export const TRANSLATION_SET_CURRENT_LANGUAGE = 'TRANSLATION_SET_CURRENT_LANGUAGE'
export const TRANSLATION_SET_LANGUAGES = 'TRANSLATION_SET_LANGUAGES'
export const TRANSLATION_SET_LANGUAGE_VALUES = 'TRANSLATION_SET_LANGUAGE_VALUES'
export const TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE = 'TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE'