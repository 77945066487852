// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED

// This should be moved to common at some point soon, but there is a weird bug that has to be addressed with bearer tokens before it can
// Not related to current story to will do that some other day
import { getLocalizationClient } from '@/api/localizationApi'
import handleError from '@/api/handleError'
import { TRANSLATION_SET_ACTUAL_TRANSLATIONS, TRANSLATION_SET_TOTAL_LANGUAGES, TRANSLATION_SET_TRANSLATED_PERCENTAGE, TRANSLATION_SET_AVAILABLE_TRANSLATIONS,
  TRANSLATION_SET_LANGUAGE_LIST, TRANSLATION_SET_TIMEZONE_LIST, TRANSLATION_SET_CURRENT_LANGUAGE, TRANSLATION_SET_LANGUAGES, TRANSLATION_SET_LANGUAGE_VALUES,
  TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE } from '@/common/store/types'
import * as Query from '@/utils/query'

const CONTEXT_ID = window.KUBE_SETTINGS.localizationContextId

const state = {
  availableTranslations: null,
  actualTranslations: null,
  defaultLanguage: null,
  cpqPreferredLanguage: null,
  languageList: [],
  timezoneList: [],
  translatedPercentage: null,
  totalLanguages: null,
  // Need to integrate and use the language interface I have in stash
  languages: null,
  languageValues: {}
}

const getters = {
  getAvailableTranslations: state => state.availableTranslations,
  getActualTranslations: state => state.actualTranslations,
  getDefaultLanguage: state => state.defaultLanguage,
  getCPQPreferredLanguage: state => state.cpqPreferredLanguage,
  getLanguageList: state => state.languageList,
  getTimezoneList: state => state.timezoneList,
  getLocalizationContextId(state) {
    return window.KUBE_SETTINGS.localizationContextId
  },
  getTranslatedPercentage: state => state.translatedPercentage,
  getTotalLanguages: state => state.totalLanguages,
  getLanguages: state => state.languages,
  getLanguageValues: state => state.languageValues

}

const mutations = {
  [TRANSLATION_SET_TOTAL_LANGUAGES](state, totalLanguages) {
    state.totalLanguages = totalLanguages
  },
  [TRANSLATION_SET_AVAILABLE_TRANSLATIONS](state, availableTranslations) {
    state.availableTranslations = availableTranslations
  },
  [TRANSLATION_SET_ACTUAL_TRANSLATIONS](state, actualTranslations) {
    state.actualTranslations = actualTranslations
  },
  [TRANSLATION_SET_TRANSLATED_PERCENTAGE](state, translatedPercentage) {
    state.translatedPercentage = translatedPercentage
  },
  [TRANSLATION_SET_LANGUAGE_LIST](state, languageList) {
    state.languageList = languageList
  },
  [TRANSLATION_SET_TIMEZONE_LIST](state, timezoneList) {
    state.timezoneList = timezoneList
  },
  [TRANSLATION_SET_CURRENT_LANGUAGE](state, currentLanguage) {
    state.currentLanguage = currentLanguage
  },
  [TRANSLATION_SET_LANGUAGES](state, languages) {
    state.languages = languages
  },
  [TRANSLATION_SET_LANGUAGE_VALUES](state, languageValues) {
    state.languageValues = languageValues
  },
  [TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE](state, language) {
    state.cpqPreferredLanguage = language
  }
}

function formatLanguageName({ cultureCode, displayName }) {
  // Disabling because the escape characters are needed
  // eslint-disable-next-line
  const regex = new RegExp('(?<language>.+) \((?<extended>.+)\)')

  if (!regex.test(displayName)) {
    // e.g., "English - (en)"
    return `${displayName} - (${cultureCode})`
  }

  const match = displayName.match(regex)

  // e.g., "English - United States - (en-US)"
  return `${match.groups.language} - ${match.groups.extended} - (${cultureCode})`
}

const actions = {
  fetchLocalizationStatistics({ commit, getters }, languageCodes = []) {
    return new Promise((resolve, reject) => {
      getLocalizationClient().post(`contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/actions/statistics`, languageCodes)
        .then(response => {
          commit(TRANSLATION_SET_TOTAL_LANGUAGES, response.data.totalLanguages)
          commit(TRANSLATION_SET_AVAILABLE_TRANSLATIONS, response.data.availableTranslations)
          commit(TRANSLATION_SET_ACTUAL_TRANSLATIONS, response.data.actualTranslations)
          commit(TRANSLATION_SET_TRANSLATED_PERCENTAGE, response.data.translatedPercentage)
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchLocalizationLanguages({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getLocalizationClient().get(`contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/languages`)
        .then(response => {
          commit(TRANSLATION_SET_LANGUAGE_LIST, response.data.items)
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentLanguages({ commit, getters }, { pageInfo = null, sortInfo = null, searchText = '' } = {}) {
    let queryObject = {}
    // if (window.localStorage.languageCode) queryObject.userLanguage = window.localStorage.languageCode
    if (state.currentLanguage) queryObject.userLanguage = state.currentLanguage
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getLocalizationClient().get(`contexts/${getters.getLocalizationContextId}/tenants/${getters.getUserTenantId}/languages?${query}`)
        .then((response) => {
          response.data.items = response.data.items.map(item => {
            return {
              ...item,
              name: formatLanguageName(item),
              language: formatLanguageName(item)
            }
          })
          commit(TRANSLATION_SET_LANGUAGE_LIST, response.data.items)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchDefaultLanguage({ getters }) {
    return new Promise((resolve, reject) => {
      const url = `contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/languages/default`
      getLocalizationClient().get(`${url}`)
        .then(response => {
          state.defaultLanguage = response.data.cultureCode
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchTimezones({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getLocalizationClient().get('time-zones')
        .then((response) => {
          commit(TRANSLATION_SET_TIMEZONE_LIST, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  importLocalizationData({ getters }, { excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getLocalizationClient().post(`contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/actions/import`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          // handleError(error)
          reject(error)
        })
    })
  },
  exportLocalizationData({ getters }, languageIds = []) {
    return new Promise((resolve, reject) => {
      getLocalizationClient().post(`contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/actions/export`, languageIds, {
        responseType: 'blob'
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  syncLocalizationData({ getters }) {
    return new Promise((resolve, reject) => {
      getLocalizationClient().post(`contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/actions/synchronize`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchTenantLanguages({ commit, getters, dispatch }, { userLanguage = '', onlyActive = true } = {}) {
    if (getters.getUserTenantId) {
      return new Promise((resolve, reject) => {
        // const url = `contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/languages`
        const url = `contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/languages`
        const searchParams = new URLSearchParams({ onlyActive: onlyActive.toString() })

        if (userLanguage && userLanguage.length > 0) {
          searchParams.append('userLanguage', userLanguage)
        } else if (state.currentLanguage && state.currentLanguage.cultureCode && state.currentLanguage.cultureCode.length > 0) {
          searchParams.append('userLanguage', state.currentLanguage.cultureCode)
        }

        getLocalizationClient().get(`${url}?${searchParams}`)
          .then(response => {
            commit(TRANSLATION_SET_LANGUAGES, response.data.items)
            resolve(response.data)
          })
          .catch((error) => {
            handleError(error, dispatch)
            reject(error)
          })
      })
    }
  },
  fetchTenantLabelTranslations({ getters, dispatch }) {
    // Get default language here eventually? Or some other language control here
    // if(getters.getTenantId && getters.getDefaultLanguage){
    if (getters.getUserTenantId) {
      return new Promise((resolve, reject) => {
        // URL for Label keys/values
        const labelUrl = `contexts/${CONTEXT_ID}/translations`
        let languageCode = ''
        let language = state.currentLanguage
        if (language) {
          languageCode = language.cultureCode
        }
        if (languageCode.length <= 0) {
          languageCode = 'en'
        }
        const labelBody = {
          tenantId: getters.getUserTenantId,
          // Need to get runtime environment id
          environmentId: getters.environmentId,
          cultureCode: languageCode,
          applicationTypes: [
            'cpqPortal'
          ]
        }
        
        getLocalizationClient().post(`${labelUrl}`, labelBody)
          .then(response => {
            // This translates the data from what backend sends to what i18n needs to work
            let items = response.data.values
            let messagesObject = {}
            items.forEach(item => { 
              messagesObject[item.externalId] = item.value 
            })
            state.languageValues = messagesObject
            resolve(messagesObject)
          })
          .catch((error) => {
            handleError(error, dispatch)
            reject(error)
          })
      })
    }
  },
  fetchTenantProductTranslations({ getters, dispatch }) {
    if (getters.getUserTenantId) {
      return new Promise((resolve, reject) => {
        // URL for Product keys/values
        const productUrl = `contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/translations`

        let languageCode = ''
        let language = state.currentLanguage
        if (language) {
          languageCode = language.cultureCode
        }
        if (languageCode.length <= 0) {
          languageCode = 'en'
        }

        const productBody = {
          tenantId: getters.getUserTenantId,
          // Need to get runtime environment id
          environmentId: getters.environmentId,
          cultureCode: languageCode,
          applicationTypes: [
            'cpqPortal'
          ]
        }
    
        getLocalizationClient().post(`${productUrl}`, productBody)
          .then(response => {
            // This translates the data from what backend sends to what i18n needs to work
            let items = response.data.values
            let messagesObject = {}
            items.forEach(item => { 
              messagesObject[item.externalId] = item.value 
            })
            state.languageValues = messagesObject
            resolve(messagesObject)
          })
          .catch((error) => {
            handleError(error, dispatch)
            reject(error)
          })
      })
    }
  },
  fetchTenantLanguageValues({ getters, dispatch }, { language }) {
    if (getters.getUserTenantId) {
      return new Promise((resolve, reject) => {
        const url = `contexts/${CONTEXT_ID}/tenants/${getters.getUserTenantId}/languages/${language.id}/values`
        getLocalizationClient().get(`${url}`)
          .then(response => {
            state.languageValues = response.data
            resolve(response.data)
          })
          .catch((error) => {
            handleError(error, dispatch)
            reject(error)
          })
      })
    }
  },
  setAndUpdateCurrentLanguage({ getters, commit, dispatch }, currentLanguage) {
    if (getters.getUserTenantId) {
      if (typeof currentLanguage === 'string' && state.languages) {
        let language = state.languages.filter(item => item.id === currentLanguage)[0]
        if (language) {
          commit(TRANSLATION_SET_CURRENT_LANGUAGE, language)
          // Retranslate out language dropdowns by getting the languages back with a different user language
          dispatch('fetchTenantLanguages')
        } else {
          console.log('Language Id not found in language array')
        }
      } else {
        // Need to finalize and integrate in Language object here, but this will do for now
        commit(TRANSLATION_SET_CURRENT_LANGUAGE, currentLanguage)
        // Retranslate out language dropdowns by getting the languages back with a different user language
        dispatch('fetchTenantLanguages')
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
