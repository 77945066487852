// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { FLAGS_SET_FLAGS } from './types'

const state = {
  flags: {
    allFlags: {},
    params: {}
  },
  flagsBeenSet: false
}

const getters = {
  flags_getAllFlags: state => state.flags && state.flags.allFlags,
  flags_getParams: state => state.flags && state.flags.params,
  flags_getFlagsBeenSet: state => state.flagsBeenSet
}

const mutations = {
  [FLAGS_SET_FLAGS](state, flags) {
    state.flags = flags

    if (!state.flagsBeenSet)
      state.flagsBeenSet = true
  }
}

export default {
  state,
  getters,
  mutations
}