// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      

export const verticalReposition = {
  inserted(el, { offset = 43 }) /* istanbul ignore next */ {
    const browserInnerHeight = window.innerHeight
    const { height, top } = el.getBoundingClientRect()

    const bottomSpace = browserInnerHeight - top
    const topSpace = top - offset

    if (bottomSpace < height && topSpace > height) {
      el.classList.add('dropup')
      el.style.marginBottom = `${offset / 8}rem`
    }
  }
}

export const clickOutside = {
  bind (el, binding, vnode) /* istanbul ignore next */ {
    el.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.event)
  },
  unbind (el) /* istanbul ignore next */ {
    document.body.removeEventListener('click', el.event)
  }
}