// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED                      

import {
  BUNDLE_AVAILABILITY,
  CONSTRAINT,
  SALES_BOM,
  MFG_BOM,
  SECTION_VISIBILITY,
  SECTION_ENABLEMENT,
  SECTION_REQUIRED,
  SECTION_SPECIAL_REQUEST,
  VISIBILITY,
  ENABLEMENT,
  RENDERING,
  MODEL,
  REQUIRED,
  RECOMMENDATIONS,
  FILTERS
} from '@/constants/ruleTableTypes'

export default function getTableByContext(state) {
  const ruleContext = state.ruleContext

  switch (ruleContext) {
    case RENDERING:
    case VISIBILITY: {
      return state.tempVisibilityTable
    }
    case SECTION_VISIBILITY: {
      return state.tempSectionVisibilityTable
    }
    case SECTION_ENABLEMENT: {
      return state.tempSectionEnablementTable
    }
    case SECTION_REQUIRED: {
      return state.tempSectionRequirementTable
    }
    case SECTION_SPECIAL_REQUEST: {
      return state.tempSectionSpecialRequestTable
    }
    case ENABLEMENT: {
      return state.tempEnablementTable
    }
    case RECOMMENDATIONS: {
      return state.tempRecommendationsTable
    }
    case FILTERS: {
      return state.tempFiltersTable
    }
    case REQUIRED: {
      return state.tempRequirementTable
    }
    case BUNDLE_AVAILABILITY:
    case CONSTRAINT:
    case MODEL:
    case SALES_BOM:
    case MFG_BOM: {
      return state.rulesTable
    }
    default: {
      console.error('getTableByContext() - Unknown context \'' + ruleContext + '\'')
      return {}
    }
  }
}