// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import {
  CPQ_MANAGER_TOAST
} from '@/store/types'

const state = {
  toast: null
}

const getters = {
  getToast(state) {
    return state.toast
  }
}

const mutations = {
  [CPQ_MANAGER_TOAST](state, cpqToast) {
    state.toast = cpqToast
  }
}

export default {
  getters,
  mutations,
  state
}