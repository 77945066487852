export default {
  methods: {
    handleIdle() {
      const document = window.document
      let idleTime
      let vm = this
      let events = ['mousemove', 'keypress', 'click', 'touchstart']
      let resetIdleTime = (e) => {
        idleTime = 0
      }

      let idleInterval = setInterval(() => {
        idleTime += 1
        if (idleTime >= 59) {
          clearInterval(idleInterval)
          events.forEach(event => {
            document.removeEventListener(event, resetIdleTime)
          })
          vm.$auth.signOut()
        }
      }, 60000) // interval 1 minute

      events.forEach(event => {
        document.addEventListener(event, resetIdleTime)
      })
    }
  }
}
