// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                   
export function blurInmplayer(vm, error) /* istanbul ignore next */ {
  const inmplayerLaunchers = document.getElementsByClassName('inmplayer-launcher')
  for (let inmplayerLauncher of inmplayerLaunchers) {
    inmplayerLauncher.classList.add('blurred')
  }
}

export function unBlurInmplayer(vm, error) /* istanbul ignore next */ {
  const inmplayerLaunchers = document.getElementsByClassName('inmplayer-launcher')
  for (let inmplayerLauncher of inmplayerLaunchers) {
    inmplayerLauncher.classList.remove('blurred')
  }
}