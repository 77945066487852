// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const devURL = 'cpqmanager.devlkube.eastus2-dev.cincomcloud.com'
export const devBlueGreenURL = 'cpqmanager-test.devlkube.eastus2-dev.cincomcloud.com'
export const uatBlueGreenURL = 'cpqmanager-test.qakube.eastus2-dev.cincomcloud.com'
export const uatURL = 'cpqmanager.qakube.eastus2-dev.cincomcloud.com'
export const crmURL = 'cpqmanager-crm.qakube.eastus2-dev.cincomcloud.com'

export const internalProdURL = 'cpqmanager.prodkube.prod.cincomcloud.com'
export const internalCentralUSProdURL = 'cpqmanager.prodkube.centralus-prod.cincomcloud.com'
export const internalDemoProdURL = 'cpqmanager.prodkube.prod.cincomcloud.com'
export const preProdURL = 'cpqmanager-preprod.prodkube.prod.cincomcloud.com'
export const preProdBlueGreenURL = 'cpqmanager-preprod-test.prodkube.prod.cincomcloud.com'
export const prodURL = 'cpqmanager.cincomcpq.com'
export const demoProdURL = 'cpqmanager.prodkube.cincomcpq.com'

export const localURL = 'localhost'