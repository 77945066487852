// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const ModelsRoutes = [{
  path: '/models/',
  name: 'models',
  component: () =>
    import(/* webpackChunkName: "models" */ './models'),
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models',
      iconClass: 'fa-boxes'
    }],
    requiresAuth: true
  }
},
{
  path: '/models/',
  name: 'addModel',
  component: () =>
    import(/* webpackChunkName: "models" */ './models'),
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId?',
  name: 'modelDetail',
  component: () =>
    import(/* webpackChunkName: "modelDetail" */ './model-detail'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail',
      iconClass: 'fa-boxes'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/constraints/',
  name: 'constraints',
  component: () =>
    import(/* webpackChunkName: "constraints" */ './constraints'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'constraints',
      displayLocizeKey: 'breadcrumbs_model_constraints',
      iconClass: 'fa-project-diagram'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/constraints/constraint-info/:constraintId?',
  name: 'constraintInfo',
  component: () =>
    import(/* webpackChunkName: "constraintInfo" */ './constraint-info'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'constraints',
        displayLocizeKey: 'breadcrumbs_model_constraints'
      },
      {
        name: 'constraintInfo',
        displayLocizeKey: 'breadcrumbs_model_constraint_info',
        iconClass: 'fa-project-diagram'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/constraints/constraint-editor/:constraintId',
  name: 'constraintEditor',
  component: () =>
    import('./constraint-editor'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'constraints',
        displayLocizeKey: 'breadcrumbs_model_constraints'
      },
      {
        name: 'constraintEditor',
        iconClass: 'fa-project-diagram'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/constraints/constraint-editor-rules/:constraintId',
  name: 'constraintRuleTable',
  component: () =>
    import('./constraint-rule-table'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'constraints',
      displayLocizeKey: 'breadcrumbs_model_constraints'
    },
    {
      name: 'constraintRuleTable'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/assortments/',
  name: 'assortments',
  component: () =>
    import('./assortment-editor'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'assortments',
      displayLocizeKey: 'breadcrumbs_model_assortments',
      iconClass: 'fa-ballot-check'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/uis',
  name: 'uis',
  component: () =>
    import('./model-uis'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'uis',
        displayLocizeKey: 'breadcrumbs_uis',
        iconClass: 'fa-ruler-combined'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/uis/direct-sales-ui',
  name: 'directSalesUi',
  component: () =>
    import('./model-uis/model-ui'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'uis',
        displayLocizeKey: 'breadcrumbs_uis'
      },
      {
        name: 'directSalesUi',
        displayLocizeKey: 'tenant_portal-for-direct-sales_label',
        iconClass: 'fa-ruler-combined'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/uis/self-service-ui',
  name: 'selfServiceUi',
  component: () =>
    import('./model-uis/model-ui'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'uis',
      displayLocizeKey: 'breadcrumbs_uis'
    },
    {
      name: 'selfServiceUi',
      displayLocizeKey: 'breadcrumbs_self-service-ui',
      iconClass: 'fa-ruler-combined'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/uis/dealer-ui',
  name: 'dealerUi',
  component: () =>
    import('./model-uis/model-ui'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'uis',
      displayLocizeKey: 'breadcrumbs_uis'
    },
    {
      name: 'dealerUi',
      displayLocizeKey: 'tenant_portal-for-resellers_label',
      iconClass: 'fa-ruler-combined'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/bundles',
  name: 'bundles',
  component: () =>
    import(/* webpackChunkName: "bundles" */ './bundles/bundles'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'bundles',
        displayLocizeKey: 'breadcrumbs_bundles',
        iconClass: 'fa-burger-soda'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/bundles/bundle-info/',
  name: 'addBundle',
  component: () =>
    import(/* webpackChunkName: "bundle-info" */ './bundles/bundle-info'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'bundles',
        displayLocizeKey: 'breadcrumbs_bundles'
      },
      {
        name: 'addBundle',
        displayLocizeKey: 'breadcrumbs_bundle-add',
        iconClass: 'fa-burger-soda'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/bundles/bundle-detail/:bundleId?',
  name: 'editBundle',
  component: () =>
    import(/* webpackChunkName: "bundle-info" */ './bundles/bundle-detail'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'bundles',
        displayLocizeKey: 'breadcrumbs_bundles'
      },
      {
        name: 'editBundle',
        displayLocizeKey: 'breadcrumbs_bundle-edit',
        iconClass: 'fa-burger-soda'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/formulas',
  name: 'formulas',
  component: () =>
    import(/* webpackChunkName: "formulas" */ './formulas/formulas'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'formulas',
        displayLocizeKey: 'breadcrumbs_formulas',
        iconClass: 'fa-function'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/formulas/formula-info/',
  name: 'addFormula',
  component: () =>
    import(/* webpackChunkName: "formula-info" */ './formulas/formula-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'formulas',
      displayLocizeKey: 'breadcrumbs_formulas'
    },
    {
      name: 'addFormula',
      displayLocizeKey: 'breadcrumbs_formula-add'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/formulas/formula-info/:formulaId?',
  name: 'editFormula',
  component: () =>
    import(/* webpackChunkName: "formula-info" */ './formulas/formula-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'formulas',
      displayLocizeKey: 'breadcrumbs_formulas'
    },
    {
      name: 'editFormula',
      displayLocizeKey: 'breadcrumbs_formula-edit'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/decision-rules',
  name: 'decisionRules',
  component: () =>
    import(/* webpackChunkName: "decision-rules" */ './decision-rules/decision-rules'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'decisionRules',
        displayLocizeKey: 'breadcrumbs_decision-rules',
        iconClass: 'fa-chart-network'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/decision-rules/decision-rule-info/',
  name: 'addDecisionRule',
  component: () =>
    import(/* webpackChunkName: "decision-rule-info" */ './decision-rules/decision-rule-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'decisionRules',
      displayLocizeKey: 'breadcrumbs_decision-rules'
    },
    {
      name: 'addDecisionRule',
      displayLocizeKey: 'breadcrumbs_decision-rule-add'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/decision-rules/decision-rule-editor/:decisionRuleId?',
  name: 'editDecisionRule',
  component: () =>
    import(/* webpackChunkName: "decision-rule-editor" */ './decision-rules/decision-rule-editor'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'decisionRules',
        displayLocizeKey: 'breadcrumbs_decision-rules'
      },
      {
        name: 'editDecisionRule',
        displayLocizeKey: 'breadcrumbs_decision-rule-edit',
        iconClass: 'fa-chart-network'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/variables/',
  name: 'variables',
  component: () =>
    import(/* webpackChunkName: "variables" */ './variables'),
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'variables',
        displayLocizeKey: 'breadcrumbs_variables',
        iconClass: 'fa-superscript'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/variables/variable-info/',
  name: 'addVariable',
  component: () =>
    import(/* webpackChunkName: "variableInfo" */ './variable-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'variables',
      displayLocizeKey: 'breadcrumbs_variables'
    },
    {
      name: 'addVariable',
      displayLocizeKey: 'breadcrumbs_variable_add'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/variables/variable-info/:variableId?',
  name: 'editVariable',
  component: () =>
    import(/* webpackChunkName: "variableInfo" */ './variable-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    },
    {
      name: 'modelDetail'
    },
    {
      name: 'variables',
      displayLocizeKey: 'breadcrumbs_variables'
    },
    {
      name: 'editVariable',
      displayLocizeKey: 'breadcrumbs_variable_edit'
    }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/boms/',
  name: 'boms',
  component: () =>
    import(/* webpackChunkName: "boms" */ './boms'),
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    }, {
      name: 'modelDetail'
    }, {
      name: 'boms',
      displayLocizeKey: 'breadcrumbs_boms',
      iconClass: 'fa-receipt'
    }],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/boms/bom-add/',
  name: 'addBom',
  component: () =>
    import(/* webpackChunkName: "bomAdd" */ './bom-add'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      }, {
        name: 'modelDetail'
      }, {
        name: 'boms',
        displayLocizeKey: 'breadcrumbs_boms'
      }, {
        name: 'addBom',
        displayLocizeKey: 'breadcrumbs_bom_add',
        iconClass: 'fa-receipt'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/bom/:bomId?',
  name: 'bomInfo',
  component: () =>
    import(/* webpackChunkName: "bom" */ './bom-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    }, {
      name: 'modelDetail'
    }, {
      name: 'bomInfo',
      displayLocizeKey: 'breadcrumbs_bom'
    }],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/boms/bom-nested/:bomId?',
  name: 'bomNestedInfo',
  component: () =>
    import(/* webpackChunkName: "bom" */ './bom-nested-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    }, {
      name: 'modelDetail'
    }, {
      name: 'boms',
      displayLocizeKey: 'breadcrumbs_boms'
    }, {
      name: 'bomNestedInfo',
      displayLocizeKey: 'breadcrumbs_bom',
      iconClass: 'fa-receipt'
    }],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/boms/bom-nested-custom/:bomId?',
  name: 'bomNestedCustomInfo',
  component: () =>
    import(/* webpackChunkName: "bom" */ './bom-nested-custom-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'models',
      displayLocizeKey: 'breadcrumbs_models'
    }, {
      name: 'modelDetail'
    }, {
      name: 'boms',
      displayLocizeKey: 'breadcrumbs_boms'
    }, {
      name: 'bomNestedCustomInfo',
      displayLocizeKey: 'breadcrumbs_bom',
      iconClass: 'fa-receipt'
    }],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/relationships',
  name: 'relationships',
  component: () =>
    import(/* webpackChunkName: "relationships" */ './relationships'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'relationships',
        displayLocizeKey: 'breadcrumbs_relationships',
        iconClass: 'fa-handshake'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/guided-uis',
  name: 'guidedUIs',
  component: () =>
    import(/* webpackChunkName: "guided-uis" */ './guided-uis'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'guidedUIs',
        displayLocizeKey: 'breadcrumbs_guided-selling',
        iconClass: 'fa-compass'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/guided-uis/:id',
  name: 'guidedUI',
  component: () =>
    import(/* webpackChunkName: "guided-ui" */ './guided-uis/guided-ui'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'guidedUIs',
        displayLocizeKey: 'breadcrumbs_guided-selling'
      },
      {
        name: 'guidedUI',
        iconClass: 'fa-compass'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/models/model/:modelId/guided-uis/guided-selling-info/',
  name: 'addGuided',
  component: () =>
    import(/* webpackChunkName: "decision-rule-info" */ './guided-uis/guided-selling-info'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'models',
        displayLocizeKey: 'breadcrumbs_models'
      },
      {
        name: 'modelDetail'
      },
      {
        name: 'guidedUIs',
        displayLocizeKey: 'breadcrumbs_guided-selling'
      },
      {
        name: 'addGuided',
        displayLocizeKey: 'breadcrumbs_guided-add',
        iconClass: 'fa-compass'
      }
    ],
    requiresAuth: true
  }
}
]
