// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import Vuex from 'vuex'

import tenant from './modules/tenant'
import master from './modules/masterData'
import modelDesigner from './modules/modelDesigner'
import configurator from './modules/configurator'
import errors from './modules/errors'
import view from './modules/view'
import auth from './modules/auth'
import flags from './modules/flags/flags'
import httpRequestCount from './modules/httpRequestCount'
import supportTicket from './modules/supportTicket'
import i18n from './modules/i18n'
import document from './modules/document'
import rulesTable from './modules/rulesTable/rulesTable'
import selfServiceDesigner from './modules/selfServiceDesigner'
import task from './modules/task'
import cpqTenant from './modules/cpqTenant'

// I will be trying to move the api to the common library as well, but there is a weird issue with the bearer token that
// is making me leave the API in the main project for now
import localization from '../common/store/modules/localization'

Vue.use(Vuex)

const index = new Vuex.Store({
  modules: {
    tenant,
    master,
    modelDesigner,
    configurator,
    errors,
    view,
    auth,
    httpRequestCount,
    supportTicket,
    i18n,
    document,
    rulesTable,
    selfServiceDesigner,
    task,
    flags,
    localization,
    cpqTenant
  }
})

export default index
