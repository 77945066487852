// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const ParametersRoutes = [
  {
    path: '/parameters/',
    name: 'parameters',
    component: () => import(/* webpackChunkName: "parameters" */ './parameters'),
    meta: {
      breadcrumbs: [{
        name: 'parameters',
        displayLocizeKey: 'breadcrumbs_parameters',
        iconClass: 'fa-value-absolute'
      }],
      requiresAuth: true
    }
  }, {
    path: '/parameters/parameter-info/',
    name: 'addParameter',
    component: () => import(/* webpackChunkName: "parameterInfo" */ './parameter-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'parameters',
        displayLocizeKey: 'breadcrumbs_parameters'
      }, {
        name: 'addParameter',
        displayLocizeKey: 'breadcrumbs_parameters_add'
      }],
      requiresAuth: true
    }
  }, {
    path: '/parameters/parameter-info/:parameterId?',
    name: 'editParameter',
    component: () => import(/* webpackChunkName: "parameterInfo" */ './parameter-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'parameters',
        displayLocizeKey: 'breadcrumbs_parameters'
      }, {
        name: 'editParameter',
        displayLocizeKey: 'breadcrumbs_parameters_edit'
      }],
      requiresAuth: true
    }
  },
  // global-variables used when feature flag 'manager-terminology'
  {
    path: '/global-variables/',
    name: 'globalVariables',
    component: () => import(/* webpackChunkName: "globalVariables" */ './parameters'),
    meta: {
      breadcrumbs: [{
        name: 'globalVariables',
        displayLocizeKey: 'breadcrumbs_global-variables',
        iconClass: 'fa-value-absolute'
      }],
      requiresAuth: true
    }
  }, {
    path: '/global-variables/global-variable-info/',
    name: 'addGlobalVariable',
    component: () => import(/* webpackChunkName: "globalVariableInfo" */ './parameter-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'globalVariables',
        displayLocizeKey: 'breadcrumbs_global-variables'
      }, {
        name: 'addGlobalVariable',
        displayLocizeKey: 'breadcrumbs_global-variables-add'
      }],
      requiresAuth: true
    }
  }, {
    path: '/global-variables/global-variable-info/:parameterId?',
    name: 'editGlobalVariable',
    component: () => import(/* webpackChunkName: "global-variableInfo" */ './parameter-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'globalVariables',
        displayLocizeKey: 'breadcrumbs_global-variables'
      }, {
        name: 'editGlobalVariable',
        displayLocizeKey: 'breadcrumbs_global-variables-edit'
      }],
      requiresAuth: true
    }
  }
]
