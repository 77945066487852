// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { getCpqTenantClient } from '@/api/cpqTenantApi'
import handleError from '@/api/handleError'
import { CPQ_TENANT_SET_USER_PREFERENCES } from '@/store/types'

const state = {
  userPreferences: null
}

const getters = {
  getUserPreferences: state => state.userPreferences
}

const mutations = {
  [CPQ_TENANT_SET_USER_PREFERENCES](state, userPreferences) {
    state.userPreferences = userPreferences
  }
}

const actions = {
  fetchCpqTenantUserPreferences({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return getCpqTenantClient().get(`tenants/${getters.getUserTenantId}/users/${getters.getUser.userid}/preferences`)
        .then(response => {
          commit(CPQ_TENANT_SET_USER_PREFERENCES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  setCpqTenantUserPreferences({ commit, getters }, preferences) {
    return new Promise((resolve, reject) => {
      const url = `tenants/${getters.getUserTenantId}/users/${getters.getUser.userid}/preferences`
      return getCpqTenantClient().put(url, preferences)
        .then(response => {
          commit(CPQ_TENANT_SET_USER_PREFERENCES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default { state, getters, mutations, actions }
