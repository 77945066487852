// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import {
  devURL,
  uatURL,
  crmURL,
  devBlueGreenURL,
  uatBlueGreenURL,
  internalProdURL,
  demoProdURL,
  internalDemoProdURL,
  preProdURL,
  prodURL,
  localURL,
  internalCentralUSProdURL
} from '@/common/constants/hostNames'

const demoPortalUrl = 'https://cincom.force.com/CincomTeamSync'
const prodPortalUrl = 'https://cincom.force.com/SyncPortal'

export const getCustomerPortalUrl = hostname => {
  return hostnameToCustomerPortalUrl[hostname] || demoPortalUrl
}

const hostnameToCustomerPortalUrl = {
  [localURL]: demoPortalUrl,
  [devURL]: demoPortalUrl,
  [devBlueGreenURL]: demoPortalUrl,
  [uatBlueGreenURL]: demoPortalUrl,
  [uatURL]: demoPortalUrl,
  [crmURL]: demoPortalUrl,
  [internalDemoProdURL]: demoPortalUrl,
  [demoProdURL]: demoPortalUrl,
  [internalProdURL]: prodPortalUrl,
  [internalCentralUSProdURL]: prodPortalUrl,
  [preProdURL]: prodPortalUrl,
  [prodURL]: prodPortalUrl
}