// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import { TASK_SET_SIGNALR_CONNECTION_STATE } from '@/store/types'
import { SIGNALR_CONNECTION_STATE_OPEN, SIGNALR_CONNECTION_STATE_CLOSED, SIGNALR_CONNECTION_STATE_FAILED } from '@/store/signalRConnectionStates'
import store from '@/store'
import { getAccessToken as _getAccessToken, logout, waitForAuthentication, useLoginLogging } from '@/common/auth'

const BASE_URL = window.KUBE_SETTINGS.TaskServiceURL
let stopWasCalled = false

const getAccessToken = async (count) => {
  if (useLoginLogging) {
    console.log('signalRConnection getAccessToken() count =', count)
  }
  let retryCount = count !== undefined ? count + 1 : 0
  return new Promise((resolve, reject) => {
    _getAccessToken()
      .then(data => {
        if (data === undefined) {
          setTimeout(async () => {
            if (retryCount > 10) {
              console.log('failed to get access token 10 times.. logging out.')
              await logout()
            }
            if (useLoginLogging) {
              console.log('signalRConnection getAccessToken() retryCount =', retryCount)
            }
            resolve(getAccessToken(retryCount))
          }, 500)
        } else {
          if (useLoginLogging) {
            console.log('signalRConnection getAccessToken() accessToken =', data.accessToken)
          }
          resolve(data.accessToken)
        }
      })
      .catch((error) => {
        console.error('Error getting access token', error)
        reject(error)
      })
  })
}

const taskHub = new HubConnectionBuilder()
  .withUrl(`${BASE_URL}/taskHub`, {
    transport: HttpTransportType.WebSockets,
    skipNegotiation: true,
    accessTokenFactory: () => getAccessToken()
  })
  .withAutomaticReconnect()
  .build()

taskHub.onclose(async () => {
  if (useLoginLogging) {
    console.log('signalRConnection taskHub.onclose()')
  }
  store.commit(TASK_SET_SIGNALR_CONNECTION_STATE, SIGNALR_CONNECTION_STATE_CLOSED)
  if (!stopWasCalled) {
    if (useLoginLogging) {
      console.log('signalRConnection taskHub.onclose() calling start()')
    }
    await start()
  }
})

let signalRRetryCount = 0

const start = async () => {
  if (useLoginLogging) {
    console.log('signalRConnection start() signalRRetryCount =', signalRRetryCount)
  }
  await waitForAuthentication()
  return new Promise(async (resolve, reject) => {
    try {
      if (useLoginLogging) {
        console.log('signalRConnection start() calling taskHub.start()')
      }
      await taskHub.start()
      stopWasCalled = false
      store.commit(TASK_SET_SIGNALR_CONNECTION_STATE, SIGNALR_CONNECTION_STATE_OPEN)
      console.info('SignalR started...')
      resolve()
    } catch (error) {
      signalRRetryCount++
      console.error('Error starting SignalR Connection', signalRRetryCount)
      if (signalRRetryCount < 5) {
        setTimeout(() => {
          if (useLoginLogging) {
            console.log('signalRConnection start() calling start() signalRRetryCount =', signalRRetryCount)
          }
          start()
        }, 2000) 
      } else {
        store.commit(TASK_SET_SIGNALR_CONNECTION_STATE, SIGNALR_CONNECTION_STATE_FAILED)
        console.error('SignalR Connection retry count exceeded', error)
        reject(new Error('Error starting SignalR Connection'))
      }
    }
  })
}

const stop = async () => {
  if (useLoginLogging) {
    console.log('signalRConnection stop()')
  }
  taskHub.stop()
  stopWasCalled = true
}

export default {
  taskHub,
  start,
  stop
}
