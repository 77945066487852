// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { TASK_SET_SIGNALR_CONNECTION_STATE, TASK_SET_SIGNALR_MESSAGE_STATE, TASK_SET_TASK } from '@/store/types'
import { SIGNALR_CONNECTION_STATE_CLOSED } from '@/store/signalRConnectionStates'
import { SIGNALR_MESSAGE_STATE_GOOD, SIGNALR_MESSAGE_STATE_ERROR } from '@/store/signalRMessageStates'
import signalRConnection from '@/store/signalRConnection'
import store from '@/store'
import { useLoginLogging } from '@/common/auth'

const state = {
  signalRConnectionState: SIGNALR_CONNECTION_STATE_CLOSED,
  signalRMessageState: null,
  task: {}
}

const getters = {
  getSignalRConnectionState(state) {
    return state.signalRConnectionState
  },
  getSignalRMessageState(state) {
    return state.signalRMessageState
  },
  getTask(state) {
    return state.task
  }
}

const mutations = {
  [TASK_SET_SIGNALR_CONNECTION_STATE](state, connectionState) {
    state.signalRConnectionState = connectionState
  },
  [TASK_SET_SIGNALR_MESSAGE_STATE](state, messageState) {
    state.signalRMessageState = messageState
  },
  [TASK_SET_TASK](state, task) {
    state.task = task
  }
}

const actions = {
  promoteEnvironment({ getters }, { commitMessage, modelIds, headerModelId, lineModelId, outputModelId, languageModelId, publishPriceLists, publishCostLists, publishLanguages, localizationVersion }) {
    if (useLoginLogging) {
      console.log('task.js promoteEnvironment()')
    }
    return signalRConnection.taskHub
      .invoke('CreateTask',
        getters.getUserTenantId,
        {
          taskType: 'promoteEnvironment',
          properties: {
            environmentToPromote: {
              commitMessage: commitMessage,
              modelIds: modelIds,
              headerModelId: headerModelId,
              lineModelId: lineModelId,
              outputModelId: outputModelId,
              languageModelId: languageModelId,
              publishPriceLists: publishPriceLists,
              publishCostLists: publishCostLists,
              publishLanguages: publishLanguages,
              localizationVersion: localizationVersion
            }
          }
        }
      )
      .catch(err => console.error(err.toString()))
  },
  addDemoModels({ getters }, modelIds) {
    if (useLoginLogging) {
      console.log('task.js addDemoModels()')
    }
    return signalRConnection.taskHub
      .invoke('CreateTask',
        getters.getUserTenantId,
        {
          taskType: 'addDemos',
          properties: {
            modelIds
          }
        }
      )
      .catch(err => console.error(err.toString()))
  }
}
  
const webSocketResponseEvents = () => {
  if (useLoginLogging) {
    console.log('task.js webSocketResponseEvents()')
  }
  if (!signalRConnection) return
  signalRConnection.taskHub.on('TaskCompleted', data => {
    store.commit(TASK_SET_SIGNALR_MESSAGE_STATE, { requestName: 'CreateTask', status: SIGNALR_MESSAGE_STATE_GOOD, taskType: data.taskType })
    store.commit(TASK_SET_TASK, data)
  })
  signalRConnection.taskHub.on('TaskFailed', data => {
    store.commit(TASK_SET_SIGNALR_MESSAGE_STATE, { requestName: 'CreateTask', status: SIGNALR_MESSAGE_STATE_ERROR, taskType: data.taskType })
  })
}
webSocketResponseEvents()

export default {
  state,
  mutations,
  getters,
  actions
}
