// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'flags_getAllFlags',
      'flags_getFlagsBeenSet'
    ]),
    _isSystemDown() {
      return this._isFeatureEnabled('_system-down')
    }
  },
  methods: {
    // call this method with the flag name and it will return whether
    // the feature is on in the current environment
    _isFeatureEnabled(flagName) {
      return this.flags_getAllFlags.find(v => v.name === flagName) && this.flags_getAllFlags.find(v => v.name === flagName).isEnabled
    }
  }
}
