// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import auth from '@/store/modules/auth'
import { getTenantApi } from '@/api/tenantApi'

const featureFlagHelper = {
  tenantId: null,
  environment: null,
  onChangeHandlers: [],
  flags: [],
  cacheFlags: true,
  init: async function (params) {
    this.tenantId = auth.getters.getUserTenantId() ? auth.getters.getUserTenantId() : ''
    this.environment = params.environmentID
    this.onChangeHandlers.push(params.onChange)
    this.cacheFlags = params.cacheFlags ? params.cacheFlags : true
    if (params.anonymous) {
      await getTenantApi().get('/feature-flags').then(response => {
        this.flags = response.data
        this.onChangeHandlers.forEach(v => v())
      })
    }
    if (this.cacheFlags && this.tenantId) {
      await getTenantApi().get(`/tenants/${this.tenantId}/feature-flags`).then(response => {
        this.flags = response.data
        this.onChangeHandlers.forEach(v => v())
      })
    }
  },

  getAllFlags: function () {
    return this.flags
  },

  identify: function (tenant) {
    this.tenantId = tenant
    this.onChangeHandlers.forEach(v => {
      v()
    })
  },

  hasFeature: function (feature) {
    // Note cannot use optional chaining in cosmo, maybe due to webpack/acorn versions.
    const featureFlag = this.flags.find(v => v.name === feature)
    return featureFlag ? featureFlag.isEnabled : false
  }
}

export function init (params) {
  return featureFlagHelper.init(params)
}
export function getAllFlags () {
  return featureFlagHelper.getAllFlags()
}
export function identify (tenant) {
  return featureFlagHelper.identify(tenant)
}
export function hasFeature (feature) {
  return featureFlagHelper.hasFeature(feature)
}
export function createFeatureFlagInstance () {
  return featureFlagHelper
}
