// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import state from './modelDesigner/state'
import getters from './modelDesigner/getters'
import actions from './modelDesigner/actions'
import mutations from './modelDesigner/mutations'

export default {
  state,
  getters,
  actions,
  mutations
}