// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      

export default {
  name: 'dropdown-header-textbox',

  props: {
    manualTextOverride: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: ''
    }
  },
  computed: {
    getPlaceholder() {
      if (this.placeholderText === '') {
        return this.$t2('dropdown_search-values_placeholder', 'Search Values', this.$t)
      } else {
        return this.placeholderText
      }
    }
  }
}