// (c) Cincom Systems, Inc. <2018> - <2021>
// ALL RIGHTS RESERVED
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import defaultState from './defaultState'

/**
 * @author Alex H - Everett S - Piotr B - Lukasz F: September - October 2018
 * @description The Decision Rules Store Module contains state for decision rules editor
 * @description It was separated from local state because of the complexity of the component and it's interactions / requirements
 */

const state = defaultState

export default {
  state,
  getters,
  mutations,
  actions
}
