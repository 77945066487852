// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import configuratorApi from '@/api/configuratorApi'
import handleError from '@/api/handleError'
import * as Query from '@/utils/query'
import { CONFIGURATOR_SET_CARTS } from '@/store/types'

const CATALOG_API = 'catalog'

const state = {
  carts: []
}

const getters = {
  getCarts(state) {
    return state.carts
  }
}

const mutations = {
  [CONFIGURATOR_SET_CARTS](state, carts) {
    state.carts = carts
  }
}

const actions = {
  fetchCarts({ commit, getters }, { environmentId, pageInfo, sortInfo, searchText = '' }) {
    let queryObject = {}
    if (environmentId && environmentId !== '00000000-0000-0000-0000-000000000000') queryObject.environmentId = environmentId
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      configuratorApi.get(`/tenants/${getters.getUserTenantId}/carts?${query}`)
        .then(({ data }) => {
          commit(CONFIGURATOR_SET_CARTS, data.items)
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchProductCatalog({ getters }) {
    return new Promise((resolve, reject) => {
      configuratorApi
        .get(`tenants/${getters.getUserTenantId}/${CATALOG_API}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
