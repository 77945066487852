// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export default {
  props: {
    bigMessage: {
      type: String
    },
    mediumMessage: {
      type: String
    }
  }
}