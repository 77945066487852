// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const OPTIONAL = 'Optional'
export const AVAILABLE = 'Available'
export const NOT_AVAILABLE = 'NotAvailable'

export default {
  OPTIONAL,
  AVAILABLE,
  NOT_AVAILABLE
}