// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import * as contexts from '@/constants/ruleTableTypes'

export default function getUpdateRuleHeaderRouteByContext({ context, tenantId, modelId, decisionRuleId, constraintId }) {
  switch (context) {
    case contexts.MODEL:
      return `/tenants/${tenantId}/models/${modelId}/decision-rules/${decisionRuleId}/header`
    case contexts.CONSTRAINT:
      return `/tenants/${tenantId}/models/${modelId}/constraints/${constraintId}/header`
    default:
      return undefined
  }
}