// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
export default {
  models: [],
  outputsModelId: null,
  languagesModelId: null,
  bundles: [],
  currentFormula: {},
  currentGuidedUI: {},
  currentModel: null,
  currentModelDetails: null,
  currentModelInfo: null,
  constraints: [],
  currentConstraint: {},
  editingBundle: {},
  formulas: [],
  formulasSequence: [],
  groups: [],
  groupOptionsModel: [],
  metadata: {},
  metadataActiveStepId: null,
  modelUis: [],
  guidedUis: [],
  variables: [],
  currentVariable: null,
  boms: [],
  currentBom: null,
  currentEntity: null,
  decisionRules: [],
  decisionRulesSequence: [],
  entities: [],
  bomEntities: [],
  parameters: [],
  currentParameter: null,
  existingFormulas: [],
  integrations: [],
  systemProperties: [],
  precision: {}
}
