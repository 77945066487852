// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const VARIABLE_LIST = 'List'
export const VARIABLE_BOOLEAN = 'Boolean'
export const VARIABLE_STRING = 'String'
export const VARIABLE_NUMERIC = 'Numeric'
export const VARIABLE_DATE = 'Date'
export const VARIABLE_CUSTOM = 'Custom'

export default {
  LIST: VARIABLE_LIST,
  BOOLEAN: VARIABLE_BOOLEAN,
  STRING: VARIABLE_STRING,
  NUMERIC: VARIABLE_NUMERIC,
  DATE: VARIABLE_DATE,
  CUSTOM: VARIABLE_CUSTOM
}