// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const IntegrationRoutes = [
  {
    path: '/integrations/',
    name: 'integrations',
    component: () =>
      import(/* webpackChunkName: "integrations" */ './integrations'),
    meta: {
      breadcrumbs: [{
        name: 'integrations',
        displayLocizeKey: 'breadcrumbs_integration',
        iconClass: 'fa-plug'
      }],
      requiresAuth: true
    }
  },
  {
    path: '/integrations/integration-info/',
    name: 'addIntegration',
    component: () =>
      import(/* webpackChunkName: "integrationInfo" */ './integration-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'integrations',
        displayLocizeKey: 'breadcrumbs_integration'
      },
      {
        name: 'addIntegration',
        displayLocizeKey: 'breadcrumbs_integration_add'
      }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/integrations/integration-info/:integrationId?',
    name: 'editIntegration',
    component: () =>
      import(/* webpackChunkName: "integrationInfo" */ './integration-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'integrations',
        displayLocizeKey: 'breadcrumbs_integration'
      },
      {
        name: 'editIntegration',
        displayLocizeKey: 'breadcrumbs_integration_edit'
      }
      ],
      requiresAuth: true
    }
  }
]
