// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import { OktaAuth } from '@okta/okta-auth-js'
import router from '../router'
import store from '@/store'
import { createFeatureFlagInstance } from '@/common/utils/featureFlagHelper'
import { getFeatureFlagEnvID } from '@/common/constants/featureFlagCredentials'
import i18next from 'i18next'

// let redirectUri = document.location.origin + '/implicit/callback'
// let redirectUri = document.location.origin + '/authorization-callback'
const redirectUris = [document.location.origin + '/implicit/callback', document.location.origin + '/authorization-callback']

let useOktaLogin = true // Set by setUseOktaLogin()
export let useLoginLogging = true // Set by featureFlagHelper.init(), used by loginLog()
let flagsLoaded = false

let receivedTokenError = false // Set by validateAccessAsync() and getReceivedTokenErrorAndReset()
let loggingOut = false // Set by logout()
let loggingOutFromAuthTimeout = false // Set/Used by waitForAuthentication()
let oktaAuthError = false // Set by login() and callback()
let oktaAuthSubscribed = false // Used by setTokens()
let currentUser = '' // Set by finishLogin()/validateAccessAsync(), used by setOriginalUri()/checkForOriginalUri()

export const authConfig = {
  clientId: window.KUBE_SETTINGS.clientId,
  issuer: window.KUBE_SETTINGS.issuer,
  redirectUri: redirectUris[0], // Set by setRedirectUri() and initOkta()
  scopes: ['openid', 'profile', 'email'],
  pkce: true
}

const noValidateRouteNames = ['callback', 'login', 'logout', 'authorization-callback']

const featureFlag = createFeatureFlagInstance()
featureFlag.identify('default_user')
featureFlag.init({
  environmentID: getFeatureFlagEnvID(
    window.location.hostname,
    window.location.hostname.split('.')[0].split('-')[0]
  ),
  cacheFlags: false,
  onChange: (oldFlags, params) => { // Occurs whenever flags are changed
    useLoginLogging = featureFlag.hasFeature('login-logging')
    loginLogWriteOutputQueue()
    flagsLoaded = true
  }
})

let oktaAuth = new OktaAuth(authConfig)
oktaAuth.options.redirectUri = undefined

/** Extracts query variable from url */
function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

/** false if not user.email === loginHint */
function hintMatchesSessionUser() {
  loginLog('hintMatchesSessionUser()')
  return new Promise(async (resolve, reject) => {
    const loginHint = getQueryVariable('login_hint')
    if (loginHint != null) {
      await getUserInfo()
        .then(user => {
          loginLog('user: ', user)
          loginLog('loginHint: ', loginHint)
          resolve(user ? user.email === loginHint : false)
        })
        .catch(err => {
          console.error(err)
          loginLog('hintMatchesSessionUser() getUserInfo() catch() err.message = ' + err.message)
          reject(err)
        })
    }
    resolve(true)
  })
}

/** Sets originalUri in session storage */
function setOriginalUri(originalUriValue) {
  loginLog('setOriginalUri() originalUriValue=', originalUriValue)
  if (!originalUriValue || originalUriValue === 'null') {
    sessionStorage.setItem('originalUri', '')
    return
  }
  let inNoValidateRouteNames = false
  noValidateRouteNames.forEach(routeName => {
    if (originalUriValue.toLowerCase().includes('/' + routeName.toLowerCase())) {
      inNoValidateRouteNames = true
      loginLog('setOriginalUri() noValidateRouteName=', routeName)
    }
  })
  if (inNoValidateRouteNames) {
    sessionStorage.setItem('originalUri', '/')
    loginLog('setOriginalUri() inNoValidateRouteNames=true originalUriValue= /')
  } else {
    sessionStorage.setItem('originalUri', originalUriValue)
    sessionStorage.setItem('originalUser', currentUser)
    loginLog('setOriginalUri() inNoValidateRouteNames=false originalUriValue=', originalUriValue)
  }
}

/** Checks for and goes to originalUri if not null */
export function checkForOriginalUri(next) {
  loginLog('checkForOriginalUri() next=', next)

  const originalUser = sessionStorage.getItem('originalUser')
  const originalUri = sessionStorage.getItem('originalUri')
  loginLog('checkForOriginalUri() originalUri=' + originalUri + ', originalUser=' + originalUser + ', currentUser=' + currentUser)
  if (currentUser) {
    setOriginalUri(null)
  }
  if (originalUri && originalUri !== 'null' && originalUri !== '/') {
    if (next) {
      if (!originalUser || (originalUser === 'null') || (originalUser === 'undefined') || (originalUser === currentUser)) {
        loginLog('checkForOriginalUri() calling next({path: \'' + originalUri + '\'}) originalUri')
        next({ path: originalUri })
      } else {
        loginLog('checkForOriginalUri() calling next({path: \'/\'}) hardcoded /')
        next({ path: '/' })
      }
    } else {
      if (!originalUser || (originalUser === 'null') || (originalUser === 'undefined') || (originalUser === currentUser)) {
        loginLog('checkForOriginalUri() set window.location to originalUri \'' + originalUri + '\'')
        window.location.replace(originalUri)
      } else {
        loginLog('checkForOriginalUri() set window.location to \'/\'')
        window.location.replace(window.location.origin + '/')
      }
    }
    return true
  } else {
    loginLog('checkForOriginalUri() returning without calling next()')
    return false
  }
}

/** Calls oktaAuth.authStateManager.subscribe() - prevents browser console message:
 *    [okta-auth-sdk] WARN: updateAuthState is an asynchronous method with no return,
 *    please subscribe to the latest authState update with authStateManager.subscribe(handler) method before calling updateAuthState.
 */
async function oktaAuthSubscribe() {
  if (oktaAuth && oktaAuth.authStateManager) {
    loginLog('oktaAuthSubscribe() subscribing to authStateManager')
    oktaAuth.authStateManager.subscribe(authState => {
      // handle latest authState
    })
    loginLog('oktaAuthSubscribe() updating authStateManager')
    await oktaAuth.authStateManager.updateAuthState()
    oktaAuthSubscribed = true
  }
}

const renewKey = 'accessToken'
const oneMinute = 60 * 1000
const renewInterval = 5 * oneMinute
let timeToRenew = false
function setRenewTimeout() {
  timeToRenew = false
  setTimeout(() => {
    timeToRenew = true
  }, renewInterval)
}
setRenewTimeout()

/** Calls oktaAuth.isAuthenticated() and goes to login(), originalUri, or next() */
export async function validateAccessAsync(to, from, next) {
  const validateAccessId = Math.floor(Math.random() * 10000)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') to: name=' + to.name + ', path=' + to.path + ', fullPath=' + to.fullPath + ', login_hint=' + to.query.login_hint)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') from: name=' + from.name + ', path=' + from.path + ', fullPath=' + from.fullPath + ', login_hint=' + from.query.login_hint)
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') redirectUri=' + getRedirectUri())

  // Keep token renewed as long as active.
  if (timeToRenew) {
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling tokenManager.renew(\'' + renewKey + '\')')
    try {
      await oktaAuth.tokenManager.renew(renewKey)
    } catch (err) {
    } finally {
      if (loggingOut) {
        timeToRenew = false
      } else {
        setRenewTimeout()
      }
    }
  }

  // Check whether or not logging out is in progress.
  if (loggingOut) {
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling next() 1 loggingOut=', loggingOut)
    next()
    return
  }
  
  // Calling initOkta() just in case has never been called.
  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling initOkta()')
  initOkta(0)

  // Check whether or not the 'to' route is an authenticated route.
  if (noValidateRouteNames.includes(to.name)) {
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling next() 2 (noValidateRouteNames)')
    next()
    return
  }

  // Get the current session.
  let session
  try {
    session = await oktaAuth.session.get()
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session id/status = ' + session.id + '/' + session.status + ', session object =', session)
  } catch (err) {
    receivedTokenError = true
    console.error(err)
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 3 (oktaAuth.session.get() catch() err.message = ' + err.message + ')')
    await logout(next)
    return 
  }
  currentUser = session.login

  // Seeing if user is already athenticated.
  let isAuthenticated = false
  try {
    isAuthenticated = await oktaAuth.isAuthenticated()
  } catch (err) {
    console.error(err)
    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 4 (oktaAuth.isAuthenticated() catch() err.message = ' + err.message + ')')
    await logout(next)
    return 
  }

  if (!isAuthenticated) {
    if (!session || session.status !== 'ACTIVE') {
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling login() 5 (!isAuthenticated !ACTIVE)')
      login(next)
      return 
    }

    let response
    try {
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling getWithoutPrompt()')
      response = await oktaAuth.token.getWithoutPrompt()
    } catch (err) {
      receivedTokenError = true
      console.error(err)
      loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 6 (!isAuthenticated getWithoutPrompt() catch() err.message = ' + err.message + ')')
      await logout(next)
      return 
    }

    loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') setting tokens from response of getWithoutPrompt()')
    const tokens = response.tokens
    await setTokens(tokens)
  } else {
    // If session expired then logout.
    // if (!session || session.status !== 'ACTIVE') {
    //   let errCaught
    //   await oktaAuth.session.refresh()
    //     .then((session) => {
    //       // Existing session is now refreshed.
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session.refresh() successful, session id/status = ' + session.id + '/' + session.status + ', session object =', session)
    //       errCaught = false
    //     })
    //     .catch((err) => {
    //       // There was a problem refreshing (the user may not have an existing session).
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') session.refresh() catch(), err.messsage = ' + err.message + ', err object =', err)
    //       loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling logout() 7 (session expired)')
    //       logout(next)
    //       errCaught = true
    //     })
    //   if (errCaught) {
    //     return
    //   }
    // }
  }

  loginLog('validateAccessAsync(validateAccessId=' + validateAccessId + ') calling finishLogin()')
  finishLogin(next)
}

/** Goes to next() or 'dashboard', else logout() or originalUri */
export async function finishLogin(next) {
  loginLog('finishLogin() checking hintMatchesSessionUser()')
  const hintMatchesSession = await hintMatchesSessionUser()
  if (!hintMatchesSession) {
    loginLog('finishLogin() detected a different user than the logged in one... redirecting..')
    await logout(next)
    return 
  }

  receivedTokenError = false

  loginLog('finishLogin() calling checkForOriginalUri(' + (next ? 'next' : next) + ')')
  if (checkForOriginalUri(next)) {
    loginLog('finishLogin() returning without calling next()')
    return
  }

  if (next) {
    loginLog('finishLogin() calling next()')
    next()
  } else {
    loginLog('finishLogin() routing to \'dashboard\'')
    router.push('dashboard')
  }
}

/** Login according to useOktaLogin, calls loginOkta() or next()/'login' */
function login(next) {
  loginLog('login() useOktaLogin=' + useOktaLogin + ', next=', next)
  oktaAuthError = false
  const to = window.location.pathname
  setOriginalUri(to)
  currentUser = ''
  if (useOktaLogin) {
    loginOkta()
    if (next) next()
  } else {
    loginLog('login() redirect to route \'login\'')
    if (next) {
      next({ name: 'login' })
    } else {
      router.push({ name: 'login' })
    }
  }
}

/** Calls oktaAuth.token.getWithRedirect() */
export function loginOkta() {
  loginLog('loginOkta()')
  const obj = {
    responseType: 'code',
    scopes: ['openid', 'profile', 'email'],
    loginHint: getQueryVariable('login_hint'),
    idp: getQueryVariable('idp')
  }
  loginLog('loginOkta() obj=', obj)
  oktaAuth.token.getWithRedirect(obj)
}

/** Calls oktaAuth.revokeAccessToken() and oktaAuth.closeSession() */
export async function logout(next) {
  loggingOut = true
  loginLog('logout()')

  if (!oktaAuthSubscribed) {
    loginLog('logout() calling oktaAuthSubscribe()')
    await oktaAuthSubscribe()
  }
  loginLog('logout() calling revokeAccessToken()')
  await oktaAuth.revokeAccessToken()

  loginLog('logout() calling tokenManager.clear()')
  oktaAuth.tokenManager.clear()

  let loginWaitTime = 0
  try {
    loginLog('logout() calling closeSession()')
    await oktaAuth.closeSession()
  } catch (err) {
    // @ts-ignore
    if (err.xhr && err.xhr.status === 429) {
      loginLog('logout() Too many requests', err)
    }
    loginWaitTime = 1000
  }

  setTimeout(() => {
    loggingOut = false
    loginLog('logout() calling login()')
    login(next)
  }, loginWaitTime)
}

const logQueue = []

/** Writes argument value to the console log */
export function loginLog(log, arg2) {
  if (!flagsLoaded) {
    if (logQueue) {
      logQueue.push({ date: new Date(), log, arg2 })
    } else {
      loginLogPerformOutput(new Date(), '(always on) ' + log, arg2)
    }
  } else {
    loginLogPerformOutput(new Date(), log, arg2)
  }
}

function loginLogWriteOutputQueue() {
  while (logQueue.length > 0) {
    const logItem = logQueue.shift()
    loginLogPerformOutput(logItem.date, logItem.log, logItem.arg2)
  }
}

function loginLogPerformOutput(date, log, arg2) {
  const time = date.toString().split(' ')[4] + '.' + date.getMilliseconds().toString().padStart(3, '0')
  if (useLoginLogging) {
    if (arg2) {
      console.warn(time + ' LOGIN LOG: ' + log, arg2)
    } else {
      console.warn(time + ' LOGIN LOG: ' + log)
    }
  }
}

/** Callback from okta login used by the router */
export async function callback() {
  loginLog('callback()')
  await oktaAuth.token.parseFromUrl()
    .then(({ tokens }) => {
      if (oktaAuth && oktaAuth.authStateManager) {
        loginLog('callback() subscribing to authStateManager')
        oktaAuth.authStateManager.subscribe(authState => {
          // handle latest authState
        })
        loginLog('callback() updating authStateManager')
        oktaAuth.authStateManager.updateAuthState()
      }
  
      loginLog('callback() tokens=', tokens)
      setTokens(tokens)

      loginLog('callback() calling finishLogin()')
      finishLogin(null)
    })
    .catch(err => {
      oktaAuthError = true
      document.write('<div style="font-size: 24px; position: absolute; top: 100px; left: 100px;">' + err.message + '</div>')
      console.error(err)
    })
}

/** Set redirectUri (0=/implicit/callback, 1=/authorization-callback) and create OktaAuth Obj */
export function initOkta(redirectUriIndex) {
  loginLog('initOkta()')
  if ((oktaAuth && oktaAuth.options.redirectUri) || !redirectUris) {
    loginLog('initOkta() returning (' +
      ((oktaAuth && oktaAuth.options.redirectUri)
        ? 'redirectUri=' + oktaAuth.options.redirectUri
        : 'redirectUris=' + redirectUris) +
      ')')
    return
  }
  authConfig.redirectUri = redirectUris[redirectUriIndex]
  const oktaAuthObj = {
    issuer: authConfig.issuer,
    clientId: authConfig.clientId,
    redirectUri: authConfig.redirectUri
  }
  loginLog('initOkta() creating OktaAuth obj=', oktaAuthObj)
  oktaAuth = new OktaAuth(oktaAuthObj)

  let timeoutButtonClicked = false
  function showTimeoutAlert() {
    // The main dialog box
    const timeoutDiv = document.createElement('div')
    timeoutDiv.id = 'timeoutDiv'
    timeoutDiv.style = `height: 291px; width: 532px; 
      position: absolute; top: 50%; left: 50%; transform:translate(-50%,-50%); 
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
      z-index: 2000;`
    const backgroundNoclickDiv = document.createElement('div')
    backgroundNoclickDiv.id = 'backgroundNoclickDiv'
    backgroundNoclickDiv.style = `height: 100vh; width: 100%;
      position: fixed; left: 0; top: 0;
      background: rgba(0, 0, 0, 0.5) !important;
      opacity: 1;
      box-sizing: border-box;
      z-index: 1999;`

    // Create a header row with an icon and text
    const timeoutHeaderIcon = document.createElement('i')
    timeoutHeaderIcon.classList.add('fas', 'fa-exclamation-triangle')
    timeoutHeaderIcon.style = `height: 36px; width: 41px;
      margin: 24px 0 0 25px;
      color: #ec971f;
      font-size: 36px;`

    const timeoutHeaderText = document.createElement('span')
    timeoutHeaderText.id = 'timeoutHeaderText'
    timeoutHeaderText.innerText = i18next.t('auth-token_expiring-header', 'Are you still there?')
    timeoutHeaderText.style = `height: 28px; width: 433px;
      margin: 28px 0 0 9px;
      color: #737373;
      font-size: 24px;`

    const timeoutHeader = document.createElement('div')
    timeoutHeader.style = 'height: 76px; width: 532px;'
    timeoutHeader.appendChild(timeoutHeaderIcon)
    timeoutHeader.appendChild(timeoutHeaderText)

    // Create a description row with text, icon, and time remaining
    const timeoutDescriptionText = document.createElement('div')
    timeoutDescriptionText.id = 'timeoutDescriptionText'
    timeoutDescriptionText.innerText = i18next.t('auth-token_expiring', 'Your session is about to expire and will timeout in....')
    timeoutDescriptionText.style = `height: 24px; width: 385px;
      margin: 24px 0 0 73.5px;
      color: #595959;
      font-size: 16px;`

    const timeoutDescriptionIcon = document.createElement('i')
    timeoutDescriptionIcon.classList.add('far', 'fa-clock')
    timeoutDescriptionIcon.style = `height: 36px; width: 41px;
      margin: 24px 0 0 152px;
      color: #595959;
      font-size: 32px;`

    const timeoutDescriptionTime = document.createElement('span')
    timeoutDescriptionTime.id = 'timeoutDescriptionTime'
    timeoutDescriptionTime.innerText = i18next.t('auth-token_expiring-in-seconds', '30 seconds', { timeLeft: 30 })
    timeoutDescriptionTime.style = `height: 28px; width: 200px;
      color: #595959;
      font-size: 32px;
      font-weight: bold;`

    const timeoutDescription = document.createElement('div')
    timeoutDescription.style = 'height: 126px; width: 532px;'
    timeoutDescription.appendChild(timeoutDescriptionText)
    timeoutDescription.appendChild(timeoutDescriptionIcon)
    timeoutDescription.appendChild(timeoutDescriptionTime)

    // Create a horizontal divider line
    const timeoutLine = document.createElement('div')
    timeoutLine.style = `height: 1px;
      margin: -20px 24px 24px 24px;
      background-color: #d9d9d9;`

    // Create a footer row with a button
    const timeoutButtonRenew = document.createElement('button')
    timeoutButtonRenew.id = 'timeoutButtonRenew'
    timeoutButtonRenew.innerText = i18next.t('auth-token_button-renew', 'Keep Working')
    timeoutButtonRenew.style = `height: 40px; width: 118px;
      background-color: #14709a;
      color: white;
      font-size: 14px;
      border-radius: 8px;`
    timeoutButtonRenew.onclick = function() {
      timeoutButtonClicked = true
    }
    
    const timeoutFooter = document.createElement('div')
    timeoutFooter.style = `height: 89px; width: 532px;
      text-align: center;`
    timeoutFooter.appendChild(timeoutButtonRenew)

    // Add all rows to the main dialog box
    timeoutDiv.appendChild(timeoutHeader)
    timeoutDiv.appendChild(timeoutDescription)
    timeoutDiv.appendChild(timeoutLine)
    timeoutDiv.appendChild(timeoutFooter)
    document.body.appendChild(timeoutDiv)

    // Disable clicks on the page unless another dialog box is already disabling
    if (!document.querySelector('.v--modal-overlay')) {
      document.body.appendChild(backgroundNoclickDiv)
    }
    document.querySelector('#timeoutButtonRenew').focus()
  }
  function removeTimeoutAlert(key) {
    const timeoutDiv = document.getElementById('timeoutDiv')
    document.body.removeChild(timeoutDiv)
    if (!document.querySelector('.v--modal-overlay')) {
      const backgroundNoclickDiv = document.getElementById('backgroundNoclickDiv')
      document.body.removeChild(backgroundNoclickDiv)
    }
  }
  function setExpiredMessageText() {
    document.getElementById('timeoutHeaderText').innerText = i18next.t('auth-token_expired', 'Session expired')
    document.getElementById('timeoutDescriptionText').innerText = i18next.t('auth-token_expired-must-login', 'Your session has expired, you must login again')
    document.getElementById('timeoutDescriptionTime').style = 'display: none;'
    document.getElementsByClassName('fa-clock')[0].style = 'display: none;'
    document.getElementById('timeoutButtonRenew').innerText = i18next.t('auth-token-okay', 'OK')
  }

  // Uncomment to preview the Okta timeout alert dialog.
  // setTimeout(() => {
  //   showTimeoutAlert()
  //   const renewLoop = setInterval(function() {
  //     if (timeoutButtonClicked) {
  //       timeoutButtonClicked = false
  //       clearInterval(renewLoop)
  //       setExpiredMessageText()
  //       const okLoop = setInterval(function() {
  //         if (timeoutButtonClicked) {
  //           timeoutButtonClicked = false
  //           clearInterval(okLoop)
  //           removeTimeoutAlert()
  //         }
  //       }, 100)
  //     }
  //   }, 100)
  // }, 20000)

  // Triggered when a token has expired
  oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
    loginLog('tokenManager.on(\'expired\') ' + key + ' is expiring')
    if (key !== 'accessToken') return
    showTimeoutAlert()

    // Countdown to 0 seconds to renew
    var timeoutLoop = setInterval(async function() {
      const expiresTime = expiredToken.expiresAt
      const currentTime = parseInt(new Date().getTime() / 1000)
      let timeLeft = expiresTime - currentTime
      if (timeLeft >= 0) { // Still a chance to renew
        loginLog('tokenManager: ' + key + ' expiring in ' + timeLeft + ' seconds')
        document.getElementById('timeoutDescriptionTime').innerText = i18next.t('auth-token_expiring-in-seconds', '' + timeLeft + ' seconds', { timeLeft: timeLeft })
        if (timeoutButtonClicked) {
          timeoutButtonClicked = false
          clearInterval(timeoutLoop)
          loginLog('tokenManager: Renew button clicked, calling tokenManager.renew(\'' + key + '\')')
          await oktaAuth.tokenManager.renew(key)
          setRenewTimeout()
          removeTimeoutAlert()
        }
      } else { // Time's up
        clearInterval(timeoutLoop)
        loginLog('tokenManager: ' + key + ' has expired')
        setExpiredMessageText()
        timeoutButtonClicked = false
        const waitLoop = setInterval(function() {
          if (timeoutButtonClicked) {
            document.getElementById('timeoutButtonRenew').onclick = null
            timeoutButtonClicked = false
            clearInterval(waitLoop)
            loginLog('tokenManager: OK button clicked')
            removeTimeoutAlert()
            logout(null) // Also revokes accessToken
          }
        }, 100)
      }
    }, 1000)
  })

  // Triggered when a token has been renewed
  oktaAuth.tokenManager.on('renewed', function (key, newToken, oldToken) {
    loginLog('tokenManager.on(\'renewed\') ' + key + ' has been renewed')
  })

  // Triggered when an OAuthError is returned via the API (typically during token renew)
  oktaAuth.tokenManager.on('error', function (err) {
    loginLog('tokenManager.on(\'error\') ' + err.tokenKey + ':', err)
    // err.name, .message, .errorCode, .errorSummary, .tokenKey, & .accessToken
  })
}

/** Calls oktaAuth.tokenManager.get('idToken') */
export function getIdToken() {
  return oktaAuth.tokenManager.get('idToken')
}
  
/** Calls oktaAuth.tokenManager.get('accessToken') */
export function getAccessToken() {
  return oktaAuth && oktaAuth.tokenManager ? oktaAuth.tokenManager.get('accessToken') : {}
}

/** Calls oktaAuth.token.getUserInfo() */
export async function getUserInfo() {
  loginLog('getUserInfo()')
  try {
    const userInfo = await oktaAuth.token.getUserInfo()
    return userInfo
  } catch (err) {
    return null
  }
}

/** Calls oktaAuth.tokenManager.setTokens(tokens) */
export async function setTokens(tokens) {
  loginLog('setTokens() tokens=', tokens)
  
  if (store._mutations.AUTH_SET_OKTA_TOKENS) {
    store.commit('AUTH_SET_OKTA_TOKENS', tokens)
  }
  localStorage.setItem('okta-token-storage', JSON.stringify(tokens))

  if (!oktaAuthSubscribed) {
    loginLog('setTokens() calling oktaAuthSubscribe()')
    await oktaAuthSubscribe()
  }
  // @ts-ignore
  return oktaAuth.tokenManager.setTokens(tokens)
}

/** Calls oktaAuth.tokenManager.getTokens() */
export function getTokens() {
  return oktaAuth.tokenManager.getTokens()
}

/** Calls oktaAuth.isAuthenticated() */
export function isAuthenticated() {
  return oktaAuth.isAuthenticated()
}

/** Turn on/off using okta login */
export function setUseOktaLogin(useOktaLoginValue) {
  useOktaLogin = useOktaLoginValue
}

/** Returns whether using okta login or not */
export function getUseOktaLogin() {
  return useOktaLogin
}

/** Sets redirectUri value */
export function setRedirectUri(redirectUriValue) {
  authConfig.redirectUri = redirectUriValue
}

/** Returns redirectUri value */
export function getRedirectUri() {
  return authConfig.redirectUri
}

/** Returns the received token error and resets it to false */
export function getReceivedTokenErrorAndReset() {
  const prevTokenError = receivedTokenError
  receivedTokenError = false
  return prevTokenError
}

/** Waits for authentication to complete, if user is not authenticated it will timeout/logout */
export function waitForAuthentication() {
  loginLog('waitForAuthentication()')
  const WAIT_TIME_IN_MILLIS = 300
  const MAX_TIME_IN_SECONDS = 60
  let retryCount = 0
  return new Promise(async (resolve, reject) => {
    try {
      (async function waitForAuthenticationTimer() {
        if ((retryCount++ * WAIT_TIME_IN_MILLIS / 1000) > MAX_TIME_IN_SECONDS) {
          loginLog('waitForAuthentication() User not authenticated - max time exceeded ' + MAX_TIME_IN_SECONDS + ' seconds')
          loggingOutFromAuthTimeout = true
          await logout(null)
          loggingOutFromAuthTimeout = false
          resolve()
        } else {
          const isAuthenticatedValue = await isAuthenticated()
          if ((loggingOut && !loggingOutFromAuthTimeout) || oktaAuthError) {
            reject(new Error('loggingOut=' + loggingOut + ', oktaAuthError=' + oktaAuthError))
          } else if (!isAuthenticatedValue) {
            if (retryCount <= 1) {
              loginLog('waitForAuthentication() User not authenticated - retrying for ' + MAX_TIME_IN_SECONDS + ' seconds...')
            }
            setTimeout(waitForAuthenticationTimer, WAIT_TIME_IN_MILLIS)
          } else {
            loginLog('waitForAuthentication() User authenticated - continuing')
            resolve()
          }
        }
      })()
    } catch (err) {
      console.error(err)
      loginLog('waitForAuthentication() waitForAuthenticationTimer() catch() err.message = ' + err.message)
      reject(err)
    }
  })
}