// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const SelfServiceSettingsRoutes = [
  {
    path: '/self-service-settings',
    name: 'self-service-settings',
    component:
      () => import('./self-service-settings'),
    meta: {
      breadcrumbs: [
        {
          name: 'self-service-settings',
          displayLocizeKey: 'breadcrumbs_theme-settings-ui',
          iconClass: 'fa-palette'
        }
      ],
      requiresAuth: true
    }
  }
]
