// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED

let axiosInstances = []

export function saveAxiosInstance(httpClient) {
  axiosInstances.push(httpClient)
}

export function propogateToken(authorization) {
  for (let instance of axiosInstances) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${authorization}`
  }
}