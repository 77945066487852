// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import RequiredAsterisk from '../required-asterisk'

export default {
  name: 'text-area',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    tabindex: {
      type: Number
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RequiredAsterisk
  },
  mounted() {
    this.autosize()
  },
  computed: {
    id() {
      return this._uid
    },
    valueField() {
      return this.placeholder
    },
    hasValue() {
      return this.value && this.value.length > 0
    }
  },
  watch: {
    value() {
      this.$nextTick(this.autosize)
    }
  },
  methods: {
    autosize() {
      const element = this.$refs.textArea

      element.style.overflow = element.offsetHeight >= element.scrollHeight
        ? 'hidden'
        : 'visible'
      element.style.height = 'auto'
      element.style.height = (element.scrollHeight + 1) + 'px'
    }
  }
}