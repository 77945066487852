// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import {
  DEFAULT_DATE_LOWER_BOUND_OPERATOR,
  DEFAULT_DATE_UPPER_BOUND_OPERATOR,
  DEFAULT_NUMERIC_UPPER_BOUND_OPERATOR,
  DEFAULT_NUMERIC_LOWER_BOUND_OPERATOR,
  OPERATOR_ENUM
} from '@/constants/operators'
import {
  VARIABLE_LIST,
  VARIABLE_BOOLEAN,
  VARIABLE_NUMERIC,
  VARIABLE_STRING,
  VARIABLE_DATE
} from '@/constants/variableTypes'
import { VALUE_NUMERIC_RANGE, VALUE_DATE_RANGE, VALUE_LIST, VALUE_MULTI_SELECT } from '@/constants/valuesTypes'
import {
  getDefaultValueTypeForEntitie,
  getDefaultValueByValueType,
  getVariableType
} from '@/utils/ruleUtils'

export default function getDefaultRuleItem({
  objectType,
  dataType,
  operator = OPERATOR_ENUM.EqualTo,
  conditionType = null,
  value = null,
  binding = null,
  comparisonType = 'Value',
  upperBoundValue = null,
  lowerBoundValue = null,
  values = [],
  isMultiSelect = false
}) {
  if (conditionType === null) {
    conditionType = getDefaultValueTypeForEntitie({ objectType, dataType, isMultiSelect })
  }

  switch (getVariableType({ objectType, dataType })) {
    case VARIABLE_LIST: {
      return listFactory(conditionType, operator, value, values, isMultiSelect, comparisonType, binding)
    }
    case VARIABLE_BOOLEAN: {
      return booleanFactory(conditionType, operator, value, comparisonType, binding)
    }
    case VARIABLE_NUMERIC: {
      return numericFactory(conditionType, operator, value, lowerBoundValue, upperBoundValue, comparisonType, binding)
    }
    case VARIABLE_STRING: {
      return stringFactory(conditionType, operator, value, comparisonType, binding)
    }
    case VARIABLE_DATE: {
      return dateFactory(conditionType, operator, value, lowerBoundValue, upperBoundValue, comparisonType, binding)
    }
  }
}

function getValueForListFactory(value, values, conditionType) {
  if (value === null && values.length === 1) {
    return values[0]
  }
  return value === null ? getDefaultValueByValueType(conditionType) : value
}

function listFactory(conditionType, operator, value, values, isMultiSelect, comparisonType = 'Value', binding = null) {
  if (conditionType === VALUE_LIST || conditionType === VALUE_MULTI_SELECT) {
    return multipleListFactory(conditionType, operator, values, value, isMultiSelect, comparisonType, binding)
  }
  return {
    value: getValueForListFactory(value, values, conditionType),
    operator,
    conditionType,
    comparisonType: comparisonType,
    binding: binding
  }
}

function multipleListFactory(conditionType, operator, values, value, isMultiSelect, comparisonType = 'Value', binding = null) {
  return {
    values: value === null || value === '' ? values : [value],
    operator,
    conditionType,
    isMultiSelect,
    comparisonType: comparisonType,
    binding: binding
  }
}

function booleanFactory(conditionType, operator, value, comparisonType = 'Value', binding = null) {
  return {
    value: value === null ? getDefaultValueByValueType(conditionType) : value,
    operator,
    conditionType,
    comparisonType: comparisonType,
    binding: binding
  }
}

function stringFactory(conditionType, operator, value, comparisonType = 'Value', binding = null) {
  return {
    value: value === null ? getDefaultValueByValueType(conditionType) : value,
    operator,
    conditionType,
    ignoreCase: false,
    comparisonType: comparisonType,
    binding: binding
  }
}

function numericFactory(conditionType, operator, value, lwrVal, uprVal, comparisonType = 'Value', binding = null) {
  if (conditionType === VALUE_NUMERIC_RANGE) {
    return numericRangeFactory(conditionType, operator, lwrVal, uprVal)
  }
  return {
    value: value === null ? getDefaultValueByValueType(conditionType) : value,
    operator,
    conditionType,
    comparisonType: comparisonType,
    binding: binding
  }
}

function numericRangeFactory(conditionType, operator, lwrVal, uprVal) {
  return {
    lowerBoundValue: lwrVal === null ? 0 : lwrVal,
    upperBoundValue: uprVal === null ? 0 : uprVal,
    lowerBoundOperator: DEFAULT_NUMERIC_LOWER_BOUND_OPERATOR,
    upperBoundOperator: DEFAULT_NUMERIC_UPPER_BOUND_OPERATOR,
    operator,
    conditionType,
    comparisonType: 'Value'
  }
}

function dateFactory(conditionType, operator, value, lwrVal, uprVal, comparisonType = 'Value', binding = null) {
  if (conditionType === VALUE_DATE_RANGE) {
    return dateRangeFactory(conditionType, operator, lwrVal, uprVal)
  }
  return {
    value: value === null ? getDefaultValueByValueType(conditionType) : value,
    operator,
    conditionType,
    comparisonType: comparisonType,
    binding: binding
  }
}

function dateRangeFactory(conditionType, operator, lwrVal, uprVal) {
  const tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1))

  return {
    lowerBoundValue: lwrVal === null ? new Date(Date.now()).toISOString() : lwrVal,
    upperBoundValue: uprVal === null ? tomorrowDate.toISOString() : uprVal,
    lowerBoundOperator: DEFAULT_DATE_LOWER_BOUND_OPERATOR,
    upperBoundOperator: DEFAULT_DATE_UPPER_BOUND_OPERATOR,
    operator,
    conditionType,
    comparisonType: 'Value'
  }
}
