// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const MiscRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./profile'),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        {
          name: 'profile',
          displayLocizeKey: 'my-profile-breadcrumb'
        }
      ]
    }
  }
]
