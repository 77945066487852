// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { showServerErrorModal } from '@/common/modals'
import Vue from 'vue'
import { logout } from '@/common/auth'

const handleError = async (error) => {
  if (!error) return
  if (error.response && (error.response.status === 403 || error.response.status === 401)) {
    await logout()
  } else if (error.response && error.response.status === 404) {
    showServerErrorModal(new Vue(), error)
    console.error(error)
  } else {
    showServerErrorModal(new Vue(), error)
    console.error(error)
  }
}

export default handleError