// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

export default {
  name: 'sub-title',
  props: {
    subTitleText: {
      type: String,
      default: ''
    },
    subTitleIcon: {
      type: String,
      default: ''
    },
    subTitleActions: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: [String, Number],
      default: '3'
    },
    margin: {
      type: String,
      default: '0'
    },
    onIconClickHandler: {
      type: Function,
      default: () => {}
    },
    textSize: {
      type: [String, Number],
      default: '4'
    },
    textColor: {
      type: String,
      default: '#737373'
    },
    isTooltipEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconStyle: {
        fontSize: this.iconSize + 'rem',
        marginLeft: this.margin + 'rem',
        cursor: this.onIconClickHandler ? 'pointer' : 'default'
      }
    }
  },
  computed: {
    subtitleMargin() {
      return !this.subTitleIcon && this.margin
        ? {
          marginLeft: this.margin + 'rem'
        }
        : {}
    },
    textStyle() {
      return {
        fontSize: this.textSize + 'rem',
        color: this.textColor
      }
    }
  }
}