// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
export const NONE = 'None'
export const ROUNDING = 'Rounding'
export const NO_DECIMALS_WITH_ROUNDING = 'No Decimals with Rounding'
export const NODECIMALSWITHROUNDING = 'NoDecimalsWithRounding'
export const PRECISION_TYPES = [
  { id: 1, name: NONE, beName: NONE },
  { id: 2, name: ROUNDING, beName: ROUNDING },
  { id: 3, name: NO_DECIMALS_WITH_ROUNDING, beName: NODECIMALSWITHROUNDING }
]