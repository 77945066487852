// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { I18N_SET_CURRENT_LANGUAGE } from '@/store/types'

const state = {
  currentLanguage: 'en-US'
}

const getters = {
  getCurrentLanguage(state) {
    return state.currentLanguage
  }
}

const mutations = {
  [I18N_SET_CURRENT_LANGUAGE](state, language) {
    state.currentLanguage = language
  }
}

export default {
  state,
  getters,
  mutations
}