// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
/* istanbul ignore file */
export const CatalogRoutes = [{
  path: '/catalog/',
  name: 'catalog',
  component: () =>
    import(/* webpackChunkName: "catalog" */ './catalog'),
  meta: {
    breadcrumbs: [{
      name: 'catalog',
      displayLocizeKey: 'breadcrumbs_catalog'
    }],
    requiresAuth: true
  }
}
]