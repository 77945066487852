// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const CustomFieldsRoutes = [
  {
    path: '/custom-fields/',
    name: 'customFields',
    component: () =>
      import(/* webpackChunkName: "customFields" */ './custom-fields'),
    meta: {
      breadcrumbs: [{
        name: 'customFields',
        displayLocizeKey: 'breadcrumbs_custom-fields',
        iconClass: 'fa-table'
      }],
      requiresAuth: true
    }
  },
  {
    path: '/custom-fields/custom-field-info/',
    name: 'addCustomField',
    component: () =>
        import(/* webpackChunkName: "customFieldInfo" */ './custom-field-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'customFields',
        displayLocizeKey: 'breadcrumbs_custom-fields'
      },
      {
        name: 'addCustomField',
        displayLocizeKey: 'breadcrumbs_custom-field_add'
      }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/custom-fields/custom-field-info/:customFieldId?',
    name: 'editCustomField',
    component: () =>
        import(/* webpackChunkName: "customFieldInfo" */ './custom-field-info'),
    props: true,
    meta: {
      breadcrumbs: [{
        name: 'customFields',
        displayLocizeKey: 'breadcrumbs_custom-fields'
      },
      {
        name: 'editCustomField',
        displayLocizeKey: 'breadcrumbs_custom-field_edit'
      }
      ],
      requiresAuth: true
    }
  }
]
