// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import state from './masterData/state'
import getters from './masterData/getters'
import actions from './masterData/actions'
import mutations from './masterData/mutations'

export default {
  state,
  getters,
  actions,
  mutations
}