// (c) Cincom Systems, Inc. <2018> - <2021>
// ALL RIGHTS RESERVED                      
const redirectUri = document.location.origin + '/implicit/callback'

const authConfig = {
  oidc: {
    clientId: window.KUBE_SETTINGS.clientId,
    issuer: window.KUBE_SETTINGS.issuer,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    redirectUri
  }
}

export default {
  issuer: authConfig.oidc.issuer,
  clientId: authConfig.oidc.clientId,
  redirectUri: authConfig.oidc.redirectUri,
  scopes: authConfig.oidc.scopes,
  pkce: true
}