// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { mapGetters, mapMutations } from 'vuex'
import { SIGNALR_MESSAGE_STATE_GOOD, SIGNALR_MESSAGE_STATE_ERROR } from '@/store/signalRMessageStates'
import { PROMOTE_ENVIRONMENT, ADD_DEMOS } from '@/constants/taskTypes'
import { CPQ_MANAGER_TOAST } from '@/store/types'

export default {
  computed: {
    ...mapGetters({
      signalRMessageState: 'getSignalRMessageState',
      task: 'getTask'
    })
  },
  methods: {
    ...mapMutations({
      setToast: CPQ_MANAGER_TOAST
    })
  },
  watch: {
    signalRMessageState(newValue) {
      if (!newValue) return
      switch (newValue.taskType) {
        case PROMOTE_ENVIRONMENT:
          switch (newValue.status) {
            case SIGNALR_MESSAGE_STATE_GOOD:
              const message = this.task && this.task.properties && this.task.properties.environmentToPromote
                ? this.$t('deployment_deployment-success_message') + ' ' +
                  this.$t('breadcrumbs_models') + ' (' + this.task.properties.environmentToPromote.modelIds.length + '), ' +
                  this.$t('breadcrumbs_pricing') + ' (' + ((this.task.properties.environmentToPromote.headerModelId ? 1 : 0) + (this.task.properties.environmentToPromote.lineModelId ? 1 : 0) + (this.task.properties.environmentToPromote.publishCostLists ? 1 : 0) + (this.task.properties.environmentToPromote.publishPriceLists ? 1 : 0)) + '), ' +
                  this.$t('breadcrumbs_outputs') + ' (' + (this.task.properties.environmentToPromote.outputModelId ? '1' : '0') + ')' +
                  (this._isFeatureEnabled('translations')
                    ? ', ' + this.$t('breadcrumbs_languages') + ' (' + ((this.task.properties.environmentToPromote.languageModelId || this.task.properties.environmentToPromote.localizationVersion) ? '1' : '0') + ')'
                    : '')
                : this.$t('deployment_deployment-success_message')
              this.setToast({ type: 'success', messages: message })
              break
            case SIGNALR_MESSAGE_STATE_ERROR:
              this.setToast({ type: 'error', messages: this.$t('deployment-error_message') })
              break
            default:
              return
          }
          break
        case ADD_DEMOS:
          switch (newValue.status) {
            case SIGNALR_MESSAGE_STATE_GOOD:
              this.setToast({ type: 'success', messages: this.$t('add-demos-success-message') })
              break
            case SIGNALR_MESSAGE_STATE_ERROR:
              this.setToast({ type: 'error', messages: this.$t('add-demos-error_message') })
              break
            default:
              return
          }
          break
        default:
          return
      }
      setTimeout(() => {
        this.setToast(null)
      }, 5000)
    }
  }
}
