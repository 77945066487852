// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
/* istanbul ignore file */
export const SimulatorRoutes = [
  {
    path: '/simulator',
    name: 'simulator',
    component: () =>
      import(/* webpackChunkName: "simulatorDashboard" */ './simulator-dashboard'),
    meta: {
      breadcrumbs: [
        {
          name: 'simulator',
          displayLocizeKey: 'breadcrumbs_simulator'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/simulator/configurator',
    name: 'simulatorConfigurator',
    component: () =>
      import(/* webpackChunkName: "simulatorConfigurator" */ './simulator-configurator'),
    meta: {
      breadcrumbs: [
        {
          name: 'simulator',
          displayLocizeKey: 'breadcrumbs_simulator'
        },
        {
          name: 'simulatorConfigurator',
          displayLocizeKey: 'breadcrumbs_simulator-configurator'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/simulator/documents',
    name: 'simulatorDocuments',
    component: () =>
      import(/* webpackChunkName: "simulatorDocuments" */ './simulator-documents'),
    meta: {
      breadcrumbs: [
        {
          name: 'simulator',
          displayLocizeKey: 'breadcrumbs_simulator'
        },
        {
          name: 'simulatorDocuments',
          displayLocizeKey: 'breadcrumbs_simulator-documents'
        }
      ],
      requiresAuth: true
    }
  }
]