// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      

export default function getHostNames(appName) {
  return {
    devURL: `${appName}.devlkube.eastus2-dev.cincomcloud.com`,
    devBlueGreenURL: `${appName}-test.devlkube.eastus2-dev.cincomcloud.com`,
    uatBlueGreenURL: `${appName}-test.qakube.eastus2-dev.cincomcloud.com`,
    uatURL: `${appName}.qakube.eastus2-dev.cincomcloud.com`,
    crmURL: `${appName}-crm.qakube.eastus2-dev.cincomcloud.com`,
    drURL: `${appName}.drkube.prod.cincomcloud.com`,
    internalProdURL: `${appName}.prodkube.prod.cincomcloud.com`,
    internalCentralUSProdURL: `${appName}.prodkube.centralus-prod.cincomcloud.com`,
    internalDemoProdURL: `${appName}.prodkube.prod.cincomcloud.com`,
    preProdURL: `${appName}-preprod.prodkube.prod.cincomcloud.com`,
    preProdBlueGreenURL: `${appName}-preprod-test.prodkube.prod.cincomcloud.com`,
    prodURL: `${appName}.cincomcpq.com`,
    demoProdURL: `${appName}.prodkube.cincomcpq.com`,
    localURL: 'localhost'
  }
}