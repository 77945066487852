// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
export const TENANT_SET_TENANT = 'TENANT_SET_TENANT'
export const TENANT_SET_NEW_AUTHOR_HOTFIX = 'TENANT_SET_NEW_AUTHOR_HOTFIX'
export const TENANT_SET_NEW_AUTHOR_VERSION = 'TENANT_SET_NEW_AUTHOR_VERSION'

export const MASTER_DATA_SET_CURRENCY_SELECTED_STATE = 'MASTER_DATA_SET_CURRENCY_SELECTED_STATE'
export const MASTER_DATA_SET_CURRENCIES = 'MASTER_DATA_SET_CURRENCIES'
export const TENANT_SET_CURRENCIES = 'TENANT_SET_CURRENCIES'

export const MASTER_DATA_SET_CUSTOM_FIELDS = 'MASTER_DATA_SET_CUSTOM_FIELDS'
export const MASTER_DATA_SET_CURRENT_CUSTOM_FIELD = 'MASTER_DATA_SET_CURRENT_CUSTOM_FIELD'

export const MASTER_DATA_SET_PRICED_ITEMS = 'MASTER_DATA_SET_PRICED_ITEMS'
export const MASTER_DATA_SET_CURRENT_PRICED_ITEM = 'MASTER_DATA_SET_CURRENT_PRICED_ITEM'
export const MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS = 'MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS'

export const MASTER_DATA_SET_PRICE_LISTS = 'MASTER_DATA_SET_PRICE_LISTS'
export const MASTER_DATA_SET_CURRENT_PRICE_LIST = 'MASTER_DATA_SET_CURRENT_PRICE_LIST'
export const MASTER_DATA_SET_PRICE_LIST_ITEMS = 'MASTER_DATA_SET_PRICE_LIST_ITEMS'
export const MASTER_DATA_SET_PRICE_SHEET_ITEMS = 'MASTER_DATA_SET_PRICE_SHEET_ITEMS'
export const MASTER_DATA_SET_COST_SHEET_ITEMS = 'MASTER_DATA_SET_COST_SHEET_ITEMS'

export const MASTER_DATA_SET_GROUPS = 'MASTER_DATA_SET_GROUPS'
export const MASTER_DATA_SET_GROUP_SELECTED_TO_FALSE = 'MASTER_DATA_SET_GROUP_SELECTED_TO_FALSE'
export const MASTER_DATA_SET_GROUP_SELECTED_STATE = 'MASTER_DATA_SET_GROUP_SELECTED_STATE'
export const MASTER_DATA_SET_CURRENT_GROUP = 'MASTER_DATA_SET_CURRENT_GROUP'
export const MASTER_DATA_SET_COLUMN_SETTINGS = 'MASTER_DATA_SET_COLUMN_SETTINGS'

export const MODEL_DESIGNER_SET_FORMULAS = 'MODEL_DESIGNER_SET_FORMULAS'
export const MODEL_DESIGNER_SET_FORMULAS_SEQUENCE = 'MODEL_DESIGNER_SET_FORMULAS_SEQUENCE'
export const MODEL_DESIGNER_SET_CURRENT_FORMULA = 'MODEL_DESIGNER_SET_CURRENT_FORMULA'
export const MODEL_DESIGNER_SET_SYSTEM_PROPERTIES = 'MODEL_DESIGNER_SET_SYSTEM_PROPERTIES'
export const MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID = 'MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID'
export const MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID = 'MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID'
export const MODEL_DESIGNER_SET_MODELS = 'MODEL_DESIGNER_SET_MODELS'
export const MODEL_DESIGNER_SET_CURRENT_MODEL = 'MODEL_DESIGNER_SET_CURRENT_MODEL'
export const MODEL_DESIGNER_SET_CURRENT_MODEL_HEADERS = 'MODEL_DESIGNER_SET_CURRENT_MODEL_HEADERS'
export const MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS = 'MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS'
export const MODEL_DESIGNER_CLEAR_CURRENT_MODEL_DETAILS = 'MODEL_DESIGNER_CLEAR_CURRENT_MODEL_DETAILS'
export const MODEL_DESIGNER_SET_CURRENT_MODEL_INFO = 'MODEL_DESIGNER_SET_CURRENT_MODEL_INFO'
export const MODEL_DESIGNER_SET_BUNDLES = 'MODEL_DESIGNER_SET_BUNDLES'
export const MODEL_DESIGNER_SET_EDIT_BUNDLE = 'MODEL_DESIGNER_SET_EDIT_BUNDLE'
export const MODEL_DESIGNER_SET_CONSTRAINTS = 'MODEL_DESIGNER_SET_CONSTRAINTS'
export const MODEL_DESIGNER_SET_CURRENT_CONSTRAINT = 'MODEL_DESIGNER_SET_CURRENT_CONSTRAINT'
export const MODEL_DESIGNER_SET_GROUPS = 'MODEL_DESIGNER_SET_GROUPS'
export const MODEL_DESIGNER_SET_OPTIONS_GROUPS = 'MODEL_DESIGNER_SET_OPTIONS_GROUPS'
export const MODEL_DESIGNER_SET_METADATA_UIS = 'MODEL_DESIGNER_SET_METADATA_UIS'
export const MODEL_DESIGNER_SET_GUIDED_UIS = 'MODEL_DESIGNER_SET_GUIDED_UIS'
export const MODEL_DESIGNER_SET_CURRENT_GUIDED_UI = 'MODEL_DESIGNER_SET_CURRENT_GUIDED_UI'
export const MODEL_DESIGNER_SET_METADATA = 'MODEL_DESIGNER_SET_METADATA'
export const MODEL_DESIGNER_SET_METADATA_STEPS = 'MODEL_DESIGNER_SET_METADATA_STEPS'
export const MODEL_DESIGNER_SET_METADATA_ACTIVE_STEP_ID = 'MODEL_DESIGNER_SET_METADATA_ACTIVE_STEP_ID'
export const MODEL_DESIGNER_SET_METADATA_SECTIONS = 'MODEL_DESIGNER_SET_METADATA_SECTIONS'
export const MODEL_DESIGNER_SET_VARIABLES = 'MODEL_DESIGNER_SET_VARIABLES'
export const MODEL_DESIGNER_SET_CURRENT_VARIABLE = 'MODEL_DESIGNER_SET_CURRENT_VARIABLE'
export const MODEL_DESIGNER_SET_BOMS = 'MODEL_DESIGNER_SET_BOMS'
export const MODEL_DESIGNER_SET_CURRENT_BOM = 'MODEL_DESIGNER_SET_CURRENT_BOM'
export const MODEL_DESIGNER_SET_CURRENT_ENTITY = 'MODEL_DESIGNER_SET_CURRENT_ENTITY'
export const MODEL_DESIGNER_DECISION_RULES = 'MODEL_DESIGNER_DECISION_RULES'
export const MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE = 'MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE'
export const MODEL_DESIGNER_SET_ENTITIES = 'MODEL_DESIGNER_SET_ENTITIES'
export const MODEL_DESIGNER_SET_BOM = 'MODEL_DESIGNER_SET_BOM'
export const MODEL_DESIGNER_SET_BOM_ENTITIES = 'MODEL_DESIGNER_SET_BOM_ENTITIES'
export const MODEL_DESIGNER_SET_SELECTED_ENTITY = 'MODEL_DESIGNER_SET_SELECTED_ENTITY'
export const MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS = 'MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS'
export const MODEL_DESIGNER_SET_PARAMETERS = 'MODEL_DESIGNER_SET_PARAMETERS'
export const MODEL_DESIGNER_SET_CURRENT_PARAMETER = 'MODEL_DESIGNER_SET_CURRENT_PARAMETER'
export const MODEL_DESIGNER_SET_INTEGRATIONS = 'MODEL_DESIGNER_SET_INTEGRATIONS'
export const MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION = 'MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION'

export const CONFIGURATOR_SET_CARTS = 'CONFIGURATOR_SET_CARTS'

export const RULES_TABLE_SET_MATRIX_ARRAY = 'RULES_TABLE_SET_MATRIX_ARRAY'
export const RULES_TABLE_SET_MODEL_TYPE = 'RULES_TABLE_SET_MODEL_TYPE'
export const RULES_TABLE_SET_CONDITION_ID = 'RULES_TABLE_SET_CONDITION_ID'
export const RULES_TABLE_SET_CONDITION_COMPARISON_TYPE = 'RULES_TABLE_SET_CONDITION_COMPARISON_TYPE'
export const RULES_TABLE_SET_CONDITION_BINDING = 'RULES_TABLE_SET_CONDITION_BINDING'
export const RULES_TABLE_SET_AVAILABILITY = 'RULES_TABLE_SET_AVAILABILITY'
export const RULES_TABLE_SET_LIST_VALUE = 'RULES_TABLE_SET_LIST_VALUE'
export const RULES_TABLE_SET_LIST_ASSIGNMENT = 'RULES_TABLE_SET_LIST_ASSIGNMENT'
export const RULES_TABLE_ADD_CONDITION_COLUMN = 'RULES_TABLE_ADD_CONDITION_COLUMN'
export const RULES_TABLE_REMOVE_CONDITION_COLUMN = 'RULES_TABLE_REMOVE_CONDITION_COLUMN'
export const RULES_TABLE_REMOVE_OUTCOME_COLUMN = 'RULES_TABLE_REMOVE_OUTCOME_COLUMN'
export const RULES_TABLE_ADD_OUTCOME_COLUMN = 'RULES_TABLE_ADD_OUTCOME_COLUMN'
export const RULES_TABLE_ADD_ROWS = 'RULES_TABLE_ADD_ROWS'
export const RULES_TABLE_REMOVE_ROW = 'RULES_TABLE_REMOVE_ROW'
export const RULES_TABLE_SET_ROWS_LENGTH = 'RULES_TABLE_SET_ROWS_LENGTH'
export const RULES_TABLE_SET_CURRENT_SECTION_RULE = 'RULES_TABLE_SET_CURRENT_SECTION_RULE'
export const RULES_TABLE_SET_CURRENT_RULE = 'RULES_TABLE_SET_CURRENT_RULE'
export const RULES_TABLE_SET_RELATIONSHIP_CHILD_MODEL_ID = 'RULES_TABLE_SET_RELATIONSHIP_CHILD_MODEL_ID'
export const RULES_TABLE_SET_STRING_ASSIGNMENT = 'RULES_TABLE_SET_STRING_ASSIGNMENT'
export const RULES_TABLE_SET_NUMERIC_ASSIGNMENT = 'RULES_TABLE_SET_NUMERIC_ASSIGNMENT'
export const RULES_TABLE_SET_BOOLEAN_ASSIGNMENT = 'RULES_TABLE_SET_BOOLEAN_ASSIGNMENT'
export const RULES_TABLE_SET_DATE_ASSIGNMENT = 'RULES_TABLE_SET_DATE_ASSIGNMENT'
export const RULES_TABLE_SET_CONDITIONS = 'RULES_TABLE_SET_CONDITIONS'
export const RULES_TABLE_SET_CONDITIONS_ENTITIES = 'RULES_TABLE_SET_CONDITIONS_ENTITIES'
export const RULES_TABLE_SET_OUTCOMES_ENTITIES = 'RULES_TABLE_SET_OUTCOMES_ENTITIES'
export const RULES_TABLE_SET_BINDINGS_ENTITIES = 'RULES_TABLE_SET_BINDINGS_ENTITIES'
export const RULES_TABLE_SET_ENTITIES_DETAILS = 'RULES_TABLE_SET_ENTITIES_DETAILS'
export const RULES_TABLE_SET_NEW_OPERATOR_TO_COLUMN = 'RULES_TABLE_SET_NEW_OPERATOR_TO_COLUMN'
export const RULES_TABLE_SET_CELL_OPERATOR = 'RULES_TABLE_SET_CELL_OPERATOR'
export const RULES_TABLE_SET_IGNORE_CASE = 'RULES_TABLE_SET_IGNORE_CASE'
export const RULES_TABLE_SET_CELL_RANGE_START_OPERATOR = 'RULES_TABLE_SET_CELL_RANGE_START_OPERATOR'
export const RULES_TABLE_SET_CELL_RANGE_END_OPERATOR = 'RULES_TABLE_SET_CELL_RANGE_END_OPERATOR'
export const RULES_TABLE_SET_CELL_RANGE_END_VALUE = 'RULES_TABLE_SET_CELL_RANGE_END_VALUE'
export const RULES_TABLE_SET_CELL_RANGE_START_VALUE = 'RULES_TABLE_SET_CELL_RANGE_START_VALUE'
export const RULES_TABLE_SET_RULE_CONTEXT = 'RULES_TABLE_SET_RULE_CONTEXT'
export const RULES_TABLE_SET_RULE_USEDIN = 'RULES_TABLE_SET_RULE_USEDIN'
export const RULES_TABLE_SET_ROW_VALIDITY = 'RULES_TABLE_SET_ROW_VALIDITY'
export const RULES_TABLE_SET_NAME_AND_DESCRIPTION = 'RULES_TABLE_SET_NAME_AND_DESCRIPTION'
export const RULES_TABLE_RESET_DEFAULT_STATE = 'RULES_TABLE_RESET_DEFAULT_STATE'
export const RULES_TABLE_SET_QUANTITY = 'RULES_TABLE_SET_QUANTITY'
export const RULES_TABLE_SET_QUANTITY_TYPE = 'RULES_TABLE_SET_QUANTITY_TYPE'
export const RULES_TABLE_SET_OUTCOME_TYPE = 'RULES_TABLE_SET_OUTCOME_TYPE'
export const RULES_TABLE_SET_ROW_OBJECT_TYPE = 'RULES_TABLE_SET_ROW_OBJECT_TYPE'
export const RULES_TABLE_SET_QUANTITY_BINDING = 'RULES_TABLE_SET_QUANTITY_BINDING'
export const RULES_TABLE_SET_PRODUCT_OUTCOMES = 'RULES_TABLE_SET_PRODUCT_OUTCOMES'
export const RULES_TABLE_SET_AVAILABLE_BUNDLE_PRODUCTS = 'RULES_TABLE_SET_AVAILABLE_BUNDLE_PRODUCTS'
export const RULES_TABLE_SET_VOLUME_ADJUSTMENT_ASSIGNMENT = 'RULES_TABLE_SET_VOLUME_ADJUSTMENT_ASSIGNMENT'
export const RULES_TABLE_SET_ADD_PRODUCTS_ASSIGNMENT = 'RULES_TABLE_SET_ADD_PRODUCTS_ASSIGNMENT'
export const RULES_TABLE_SET_ADD_SOLUTIONS_ASSIGNMENT = 'RULES_TABLE_SET_ADD_SOLUTIONS_ASSIGNMENT'
export const RULES_TABLE_SET_REMOVE_PRODUCTS_ASSIGNMENT = 'RULES_TABLE_SET_REMOVE_PRODUCTS_ASSIGNMENT'
export const RULES_TABLE_SET_REMOVE_SOLUTIONS_ASSIGNMENT = 'RULES_TABLE_SET_REMOVE_SOLUTIONS_ASSIGNMENT'
export const RULES_TABLE_SET_CUSTOM_DATA_TYPE = 'RULES_TABLE_SET_CUSTOM_DATA_TYPE'
export const RULES_TABLE_ADD_VOLUME_ADJUSTMENT_ROWS = 'RULES_TABLE_ADD_VOLUME_ADJUSTMENT_ROWS'
export const RULES_TABLE_IS_LOADING_RULES = 'RULES_TABLE_IS_LOADING_RULES'

export const CONFIG_SET_MANAGER_API_CONFIG = 'CONFIG_SET_MANAGER_API_CONFIG'
export const SET_TEMP_SECTION_VISIBILITY_TABLE = 'SET_TEMP_SECTION_VISIBILITY_TABLE'
export const SET_TEMP_VISIBILITY_TABLE = 'SET_TEMP_VISIBILITY_TABLE'
export const SET_TEMP_ENABLEMENT_TABLE = 'SET_TEMP_ENABLEMENT_TABLE'
export const SET_TEMP_REQUIREMENT_TABLE = 'SET_TEMP_REQUIREMENT_TABLE'
export const SET_TEMP_SPECIAL_REQUEST_TABLE = 'SET_TEMP_SPECIAL_REQUEST_TABLE'
export const SET_TEMP_RECOMMENDATIONS_TABLE = 'SET_TEMP_RECOMMENDATIONS_TABLE'
export const SET_TEMP_FILTERS_TABLE = 'SET_TEMP_FILTERS_TABLE'
export const SET_TEMP_SECTION_REQUIREMENT_TABLE = 'SET_TEMP_SECTION_REQUIREMENT_TABLE'
export const SET_TEMP_SECTION_ENABLEMENT_TABLE = 'SET_TEMP_SECTION_ENABLEMENT_TABLE'
export const SET_TEMP_SECTION_SPECIAL_REQUEST_TABLE = 'SET_TEMP_SECTION_SPECIAL_REQUEST_TABLE'
export const SET_NEW_SORT = 'SET_NEW_SORT'
export const SET_NEW_PAGE_INFO = 'SET_NEW_PAGE_INFO'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_OBJECT_KEY = 'SET_OBJECT_KEY'
export const RESET_OBJECT_VIEW = 'RESET_OBJECT_VIEW'

export const ROUTER_SET_FROM_ROUTE = 'ROUTER_SET_FROM_ROUTE'

export const AUTH_SET_IS_AUTHENTICATED = 'AUTH_SET_IS_AUTHENTICATED'
export const AUTH_SET_IS_AUTHOR = 'AUTH_SET_IS_AUTHOR'
export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_SET_OKTA_TOKENS = 'AUTH_SET_OKTA_TOKENS'

export const I18N_SET_CURRENT_LANGUAGE = 'I18N_SET_CURRENT_LANGUAGE'

export const TASK_SET_SIGNALR_CONNECTION_STATE = 'TASK_SET_SIGNALR_CONNECTION_STATE'
export const TASK_SET_SIGNALR_MESSAGE_STATE = 'TASK_SET_SIGNALR_MESSAGE_STATE'
export const TASK_SET_TASK = 'TASK_SET_TASK'

export const SET_HTTP_REQUEST_PENDING = 'SET_HTTP_REQUEST_PENDING'
export const SET_HTTP_REQUEST_COMPLETE = 'SET_HTTP_REQUEST_COMPLETE'

// Error mutation
export const CPQ_MANAGER_TOAST = 'CPQ_MANAGER_TOAST'

// Translation
export const TRANSLATION_SET_TOTAL_LANGUAGES = 'TRANSLATION_SET_TOTAL_LANGUAGES'
export const TRANSLATION_SET_AVAILABLE_TRANSLATIONS = 'TRANSLATION_SET_AVAILABLE_TRANSLATIONS'
export const TRANSLATION_SET_ACTUAL_TRANSLATIONS = 'TRANSLATION_SET_ACTUAL_TRANSLATIONS'
export const TRANSLATION_SET_TRANSLATED_PERCENTAGE = 'TRANSLATION_SET_TRANSLATED_PERCENTAGE'
export const TRANSLATION_SET_LANGUAGE_LIST = 'TRANSLATION_SET_LANGUAGE_LIST'
export const TRANSLATION_SET_TIMEZONE_LIST = 'TRANSLATION_SET_TIMEZONE_LIST'
export const TRANSLATION_SET_CURRENT_LANGUAGE = 'TRANSLATION_SET_CURRENT_LANGUAGE'
export const TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE = 'TRANSLATION_SET_CPQ_PREFERRED_LANGUAGE'

// CPQ Tenant
export const CPQ_TENANT_SET_USER_PREFERENCES = 'CPQ_TENANT_SET_USER_PREFERENCES'
