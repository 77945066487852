// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import axios from 'axios'
import { setupInterceptors } from '@/utils/httpInterceptors'
import { saveAxiosInstance } from '@/common/utils/tokenPropogater'

const BASE_URL = window.KUBE_SETTINGS.TenantServiceURL

export const getTenantApi = () => {
  let httpClient = axios.create({
    baseURL: `${BASE_URL}/`,
    withCredentials: true,
    headers: {
      post: {
        'Content-Type': 'application/json'
      },
      put: {
        'Content-Type': 'application/json'
      }
    },
    crossDomain: true
  })
  setupInterceptors(httpClient)
  saveAxiosInstance(httpClient)

  return httpClient
}