// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import { clickOutside } from '@/common/utils/directives'
import Badge from '@/common/layout/badge'
import DropdownHeaderTextbox from '@/common/form-controls/dropdown-header-textbox'
import { DOWN_ARROW, UP_ARROW, ENTER, TAB } from '../../../constants/keysCodes'

export default {
  name: 'simple-dropdown',
  components: {
    Badge,
    DropdownHeaderTextbox
  },
  directives: {
    clickOutside
  },
  props: {
    id: { type: String },
    errorText: { type: String, default: null },
    enabled: { type: Boolean, default: true },
    defaultNoneText: { type: String, default: null },
    label: { type: String, default: null },
    includeNoneListItem: { type: Boolean, default: false },
    backgroundColor: { type: String, default: 'white' },
    isAddable: { type: Boolean, default: false },
    isAllSelectable: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isMultiSelect: { type: Boolean, default: false },
    isSearchable: { type: Boolean, default: false },
    isCombobox: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    badgeItemsEnabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    searchPlaceholder: { type: String, default: null },
    selectedId: { type: [String, Number], default: null },
    selectedIds: { type: Array, default: () => [] },
    smartPosition: { type: Boolean, default: false },
    subTextClass: { type: String, default: 'simple-dropdown__validation' },
    reversed: { type: Boolean, default: false },
    singleCheckmarkEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpen: false,
      comboboxText: null,
      filterText: null,
      selectedItems: [],
      useSelectedId: true,
      selectionMade: false,
      selectionMadeId: null
    }
  },
  created() {
    this.selectedItems = this.selectedIds && this.selectedIds.length > 0 ? this.items.filter(item => this.selectedIds.includes(item.id)) : []

    window.addEventListener('resize', this.resizeDropdown)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeDropdown)
  },
  updated() {
    /* istanbul ignore next */
    (() => {
      if (!this.smartPosition || !this.isOpen) return
      // position popup below or above or left of parent
      const popup = this.$el.querySelector('.options-container')
      const dropdownElement = this.$el.querySelector('.simple-dropdown__dropdown')
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const popupHeight = popup.clientHeight
      const windowHeight = window.innerHeight
      const spaceAboveParent = dropdownRect.top
      const spaceBelowParent = windowHeight - dropdownRect.bottom
      const tdElement = this.$el.closest('td')
      if (tdElement) {
        popup.style.minWidth = (tdElement.clientWidth + 12) + 'px'
        popup.style.maxWidth = (tdElement.clientWidth + 12) + 'px'
      } else {
        popup.style.minWidth = (this.$el.offsetWidth + 12) + 'px'
        popup.style.maxWidth = (this.$el.offsetWidth + 12) + 'px'
      }
      popup.style.position = 'fixed'
      if (popupHeight <= spaceBelowParent) {
        // position popup below parent
        popup.style.transform = 'translateY(0%)'
        popup.style.top = dropdownRect.bottom + 'px'
        popup.style.left = (tdElement ? tdElement.getBoundingClientRect().left : dropdownRect.left) + 'px'
      } else if (popupHeight <= spaceAboveParent) {
        // position popup above parent
        popup.style.transform = 'translateY(0%)'
        popup.style.top = (dropdownRect.top - popupHeight - 10) + 'px'
        popup.style.left = (tdElement ? tdElement.getBoundingClientRect().left : dropdownRect.left) + 'px'
      } else {
        // position popup left of parent
        popup.style.transform = 'translateY(-50%)'
        popup.style.top = '50%'
        popup.style.left = 'unset'
        popup.style.right = '47rem'
        popup.style.minWidth = '40rem'
      }
    })() // using ES6 arrow function for istanbul ignore
  },
  computed: {
    displayText() {
      if (this.isMultiSelect) {
        return this.hasValueSelected ? this.selectedItems.map(item => item.name || item.value).join(', ') : this.placeholder
      } else {
        const selectedItem = (this.useSelectedId && this.selectedId) ? this.items.find(({ id }) => id === this.selectedId) : null
        if (this.isCombobox && !selectedItem && this.comboboxText) {
          return this.comboboxText
        } else {
          return selectedItem ? selectedItem.name || selectedItem.value : this.includeNoneListItem ? this.noneText : null
        }
      }
    },
    noneText() {
      return !this.defaultNoneText ? this.$t2('list-item-none', 'None', this.$t) : this.defaultNoneText
    },
    filteredItems() {
      return this.filterText
        ? this.items.filter(item => (item.name || item.value).toLowerCase().includes(this.filterText.toLowerCase()))
        : this.items
    },
    hasValueSelected() {
      return this.selectedItems && this.selectedItems.length > 0
    },
    isLabelSmall() {
      return this.isOpen || this.selectedId || (this.selectedIds && this.selectedIds.length > 0) || this.includeNoneListItem || (this.isCombobox && this.comboboxText)
    },
    selectedItem() {
      if (!this.isMultiSelect) {
        return (this.useSelectedId && this.selectedId) ? this.items.find(({ id }) => id === this.selectedId) : null
      }
      return null
    },
    subText() {
      if (this.errorText) {
        return this.errorText
      } else if (this.error) {
        return this.hasError.text
      }
      return ''
    },
    isReversed() {
      if (this.reversed) {
        return 'options-container options-container--reversed'
      } else {
        return 'options-container'
      }
    }
  },
  methods: {
    addItem() {
      this.$emit('add-item', this.filterText)
      this.hideList()
    },
    changeFilterText(text) {
      this.filterText = text.length >= 3 ? text : null
      if (this.isCombobox) {
        this.comboboxText = text
        this.useSelectedId = false
        this.$emit('changed', text)
      }
    },
    isItemSelected(item) {
      return this.selectedItems.map(item => item.id).includes(item.id)
    },
    keyPressed(e) {
      /* istanbul ignore next */
      if (e.keyCode !== TAB) e.preventDefault()
      /* istanbul ignore next */
      if (e.keyCode === UP_ARROW) {
        const index = this.items.findIndex(i => i.id === this.selectedId) - 1
        if (index < 0) return
        this.$emit('selected', this.items[index])
      } else if (e.keyCode === DOWN_ARROW) {
        const index = this.items.findIndex(i => i.id === this.selectedId) + 1
        if (index >= this.items.length) return
        this.$emit('selected', this.items[index])
      } else if (e.keyCode === ENTER || e.keyCode === 32) {
        if (this.isOpen) {
          this.hideList()
        } else if (!this.isOpen) {
          this.openList()
        }
      } else if (e.keyCode === TAB) {
        this.hideList()
      }
    },
    openList() {
      if (this.enabled) {
        this.isOpen = true
        this.$emit('simple-dropdown_open-list')
      }
    },
    hideList() {
      this.isOpen = false
      this.filterText = null
    },
    selectItem(item) {
      this.selectionMade = true
      this.selectionMadeId = item.id
      if (this.isMultiSelect) {
        if (!this.isItemSelected(item)) {
          this.selectedItems.push(item)
        } else {
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id)
        }
        this.$emit('selected', this.selectedItems)
      } else {
        this.useSelectedId = true
        this.$emit('selected', item)
        this.hideList()
      }
    },
    select(event) {
      if (!this.isOpen || !this.isMultiSelect) {
        this.toggleList(event)
      }
    },
    selectAllItems() {
      this.selectedItems = this.items
      this.$emit('selected', this.selectedItems)
    },
    deselectAllItems() {
      this.selectedItems = []
      this.$emit('selected', this.selectedItems)
    },
    toggleList(event) {
      // Not if readonly and not if focus due to click.
      if (!this.readOnly && (event.type !== 'focus' || (event.explicitOriginalTarget && event.explicitOriginalTarget.tagName === 'INPUT'))) {
        if (this.isOpen) this.hideList()
        else this.openList()
      }
    },
    async resizeDropdown() {
      if (!this.isOpen || this.smartPosition) return

      // Wait for render.
      await this.$nextTick()

      const defaultListElement = this.$el.querySelector('.dropdown__default-list')
      const dropdownElement = this.$el.querySelector('.simple-dropdown__dropdown')
      const spaceBelowParent = window.innerHeight - dropdownElement.getBoundingClientRect().bottom
      const dropdownComputedStyle = window.getComputedStyle(dropdownElement)
      const lineHeight = Number.parseFloat(dropdownComputedStyle.lineHeight)
      const minimumMaxHeight = lineHeight * 2
      const maxHeightFromScss = 240 // 30rem
      const maximumMaxHeight = maxHeightFromScss - minimumMaxHeight
      const proposedMaxHeight = spaceBelowParent - lineHeight
      const newMaxHeight = Math.max(proposedMaxHeight, minimumMaxHeight)

      if (defaultListElement) {
        defaultListElement.style.maxHeight = newMaxHeight > maximumMaxHeight
          ? `${maximumMaxHeight}px`
          : `${newMaxHeight}px`
      }
    }
  },
  watch: {
    isOpen() {
      this.resizeDropdown()
    },
    items() {
      this.selectedItems = this.items.filter(item => this.selectedIds.includes(item.id))
    },
    selectedIds() {
      this.selectedItems = this.items.filter(item => this.selectedIds.includes(item.id))
    }
  }
}
