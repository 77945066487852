// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export default {
  name: 'badge',
  props: {
    backgroundColor: {
      type: String
    },
    displayText: {
      type: String,
      required: true
    },
    iconClass: {
      type: String
    },
    stackIconClass: {
      type: String
    },
    padding: {
      type: String
    },
    fontSize: {
      type: String
    },
    fontWeight: {
      type: String
    }
  }
}