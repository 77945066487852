// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
/* istanbul ignore file */
import { CatalogRoutes } from '@/modules/catalog/routes'
import { CustomFieldsRoutes } from '@/modules/custom-fields/routes'
import { DeploymentRoutes } from '@/modules/deployment/routes'
import { DocumentOutputRoutes } from '@/modules/document-output/routes'
import { GroupsRoutes } from '@/modules/groups/routes'
import { IntegrationRoutes } from '@/modules/integrations/routes'
import { MiscRoutes } from '@/modules/misc/routes'
import { ModelsRoutes } from '@/modules/models/routes'
import { OutputsRoutes } from '@/modules/outputs/routes'
import { PricedItemsRoutes } from '@/modules/priced-items/routes'
import { PricingRoutes } from '@/modules/pricing/routes'
import { ParametersRoutes } from '@/modules/parameters/routes'
import { SelfServiceSettingsRoutes } from '@/modules/self-service-settings/routes'
import { SimulatorRoutes } from '@/modules/misc/simulator/routes'
import { TranslationsRoutes } from '@/modules/translations/routes'

export default [
  ...CatalogRoutes,
  ...CustomFieldsRoutes,
  ...DeploymentRoutes,
  ...DocumentOutputRoutes,
  ...GroupsRoutes,
  ...IntegrationRoutes,
  ...MiscRoutes,
  ...ModelsRoutes,
  ...OutputsRoutes,
  ...PricedItemsRoutes,
  ...PricingRoutes,
  ...ParametersRoutes,
  ...SelfServiceSettingsRoutes,
  ...SimulatorRoutes,
  ...TranslationsRoutes
]