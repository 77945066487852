// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export default {
  name: 'toast',
  props: {
    message: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    additionalInfo: {
      type: String,
      default: null
    },
    hasActions: {
      type: Boolean,
      default: false
    },
    showInnerSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAdditionalInfoVisible: false
    }
  },
  mounted() {
    const closeAction = this.$el.querySelector('i.far.fa-times')
    const nothanksButton = this.$el.querySelector('button.toast__button')
    if (closeAction) {
      closeAction.focus()
    } else if (nothanksButton) {
      nothanksButton.focus()
    }
  },
  computed: {
    fullMessage() {
      return this.additionalInfo && this.isAdditionalInfoVisible
        ? this.message + '<p class="content__additional-info">' + this.additionalInfo + '</p>'
        : this.message
    },
    iconClass() {
      return {
        'success': 'fa fa-check-circle icon__success',
        'info': 'fa fa-info-circle icon__info',
        'warning': 'far fa-exclamation-triangle icon__warning',
        'error': 'fa fa-exclamation-circle icon__error',
        'notification': 'fa fa-gift icon__notification'
      }[this.type] || ''
    }
  },
  methods: {
    toggleAdditionalInfoVisibility() {
      this.isAdditionalInfoVisible = !this.isAdditionalInfoVisible
    }
  },
  watch: {
    type() {
      this.isAdditionalInfoVisible = false
    }
  }
}