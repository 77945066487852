// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import store from '@/store'
import i18next from 'i18next'

export function setupInterceptors(httpClient) {
  const interceptor = {
    pending: () => {
      setTimeout(() => {
        store.dispatch('setHttpRequestPending')
      }, 1500)
    },
    done: () => {
      store.dispatch('setHttpRequestComplete')
    }
  }
  httpClient.interceptors.request.use(config => {
    interceptor.pending()
    return config
  }, error => {
    interceptor.done()
    return Promise.reject(error)
  })
  httpClient.interceptors.response.use(response => {
    interceptor.done()
    return Promise.resolve(response)
  }, async error => {
    interceptor.done()
    if (/status code 401/i.test(error.message)) {
      const unauthorizedDescription = error.response.config.method.toUpperCase() + ' ' + error.response.config.url + '\n' +
        error.message + '\n' +
        i18next.t('unauthorized-dialog_must-login', 'You must login again')
      console.error(unauthorizedDescription)
      showLogoutAlert(unauthorizedDescription)
    }
    return Promise.reject(error)
  })

  const showLogoutAlert = (unauthorizedDescription) => {
    if (document.getElementById('logoutDiv')) {
      return
    }

    // The main dialog box
    const logoutDiv = document.createElement('div')
    logoutDiv.id = 'logoutDiv'
    logoutDiv.style = `height: 291px; width: 532px; 
      position: absolute; top: 50%; left: 50%; transform:translate(-50%,-50%); 
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
      z-index: 2000;`
    const backgroundNoclickDiv = document.createElement('div')
    backgroundNoclickDiv.id = 'backgroundNoclickDiv'
    backgroundNoclickDiv.style = `height: 100vh; width: 100%;
      position: fixed; left: 0; top: 0;
      background: rgba(0, 0, 0, 0.5) !important;
      opacity: 1;
      box-sizing: border-box;
      z-index: 1999;`

    // Create a header row with an icon and text
    const logoutHeaderIcon = document.createElement('i')
    logoutHeaderIcon.classList.add('fas', 'fa-exclamation-triangle')
    logoutHeaderIcon.style = `height: 36px; width: 41px;
      margin: 24px 0 0 25px;
      color: #ec971f;
      font-size: 36px;`

    const logoutHeaderText = document.createElement('span')
    logoutHeaderText.id = 'logoutHeaderText'
    logoutHeaderText.innerText = i18next.t('unauthorized-dialog_header', 'Unauthorized')
    logoutHeaderText.style = `height: 28px; width: 433px;
      margin: 28px 0 0 9px;
      color: #737373;
      font-size: 24px;`

    const logoutHeader = document.createElement('div')
    logoutHeader.style = 'height: 76px; width: 532px;'
    logoutHeader.appendChild(logoutHeaderIcon)
    logoutHeader.appendChild(logoutHeaderText)

    // Create a description row with text
    const logoutDescriptionText = document.createElement('div')
    logoutDescriptionText.id = 'logoutDescriptionText'
    logoutDescriptionText.innerText = unauthorizedDescription
    logoutDescriptionText.style = `height: 24px; width: 385px;
      margin: 24px 0 0 73.5px;
      color: #595959;
      font-size: 16px;`

    const logoutDescription = document.createElement('div')
    logoutDescription.style = 'height: 126px; width: 532px;'
    logoutDescription.appendChild(logoutDescriptionText)

    // Create a horizontal divider line
    const logoutLine = document.createElement('div')
    logoutLine.style = `height: 1px;
      margin: -20px 24px 24px 24px;
      background-color: #d9d9d9;`

    // Create a footer row with a button
    const logoutButtonOk = document.createElement('button')
    logoutButtonOk.id = 'logoutButtonOk'
    logoutButtonOk.innerText = i18next.t('unauthorized-dialog_button-ok', 'OK')
    logoutButtonOk.style = `height: 40px; width: 118px;
      background-color: #14709a;
      color: white;
      font-size: 14px;
      border-radius: 8px;`
    logoutButtonOk.onclick = async function() {
      const logoutDiv = document.getElementById('logoutDiv')
      document.body.removeChild(logoutDiv)
      const backgroundNoclickDiv = document.getElementById('backgroundNoclickDiv')
      document.body.removeChild(backgroundNoclickDiv)
    }
    
    const logoutFooter = document.createElement('div')
    logoutFooter.style = `height: 89px; width: 532px;
      text-align: center;`
    logoutFooter.appendChild(logoutButtonOk)

    // Add all rows to the main dialog box
    logoutDiv.appendChild(logoutHeader)
    logoutDiv.appendChild(logoutDescription)
    logoutDiv.appendChild(logoutLine)
    logoutDiv.appendChild(logoutFooter)
    document.body.appendChild(logoutDiv)

    // Disable clicks on the page
    document.body.appendChild(backgroundNoclickDiv)
    document.querySelector('#logoutButtonOk').focus()
    setTimeout(() => {
      document.querySelector('#logoutButtonOk').focus()
    }, 1000)
  }
}
