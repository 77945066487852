// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import authOptions from '@/auth.config'
import OktaSignIn from '@okta/okta-signin-widget'
import { setTokens, finishLogin, getReceivedTokenErrorAndReset, loginLog } from '@/common/auth'
import router from '@/router'

export default {
  name: 'login',
  async mounted() {
    const redirect = new URLSearchParams(window.location.search).get('redirect')
    this.$nextTick(() => {
      this.widget = new OktaSignIn({
        logo: 'https://op1static.oktacdn.com/fs/bco/1/fs0har6v8u1ckfQbq0h7',
        baseUrl: window.location.protocol + '//',
        clientId: authOptions.clientId,
        redirectUri: authOptions.redirectUri,
        el: '#okta-signin-container',
        authParams: {
          ...authOptions,
          display: 'page'
        },
        language: 'en', 
        i18n: {
          'en': {
            'primaryauth.title': 'Cincom Systems Inc.'
          }
        },
        features: {
          multiOptionalFactorEnroll: true,
          idpDiscovery: true
        },
        idpDiscovery: {
          requestContext: window.location.href.replace('/login', !redirect ? '' : redirect)
        }
      })
      
      this.widget.showSignInToGetTokens({ el: '#okta-signin-container', scopes: authOptions.scopes }).then(tokens => {
        setTokens(tokens)
        loginLog('login.js mounted() calling finishLogin()')
        finishLogin(null)
      }).catch(err => {
        router.push({ name: 'logout' })
        console.error(err)
        throw err
      })
      this.widget.on('ready', this.showUnassignedUserError)
    })
    document.body.classList.add('login')
  },
  destroyed () {
    if (this.widget) this.widget.remove()
    document.body.classList.remove('login')
  },
  methods: {
    showUnassignedUserError() {
      if (!getReceivedTokenErrorAndReset()) return 
      const errorContainer = document.querySelector('.o-form-error-container')
      if (errorContainer && errorContainer.children.length === 0) {
        errorContainer.classList.add('o-form-has-errors')
        const errorDiv1 = document.createElement('div')
        const errorDiv2 = document.createElement('div')
        errorDiv2.classList.add('okta-form-infobox-error', 'infobox', 'infobox-error')
        errorDiv2.setAttribute('role', 'alert')
        
        const errorIcon = document.createElement('span')
        errorIcon.classList.add('icon', 'error-16')

        const errorText = document.createElement('p')
        errorText.innerText = this.$t('validate-access_not-authorized_message', 'User is not assigned to the client application.')
        
        errorDiv2.appendChild(errorIcon)
        errorDiv2.appendChild(errorText)
        errorDiv1.appendChild(errorDiv2)
        errorContainer.appendChild(errorDiv1)
      }
    }
  }
}
