// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import RequiredAsterisk from '../required-asterisk'

Vue.use(VTooltip)

export default {
  name: 'text-input',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    name: {
      type: String
    },
    list: {
      type: String
    },
    searchResults: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String, Object]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    errorText: {
      type: String,
      default: null
    },
    subTextClass: {
      type: String,
      default: 'text-input__validation'
    },
    isIconEnabled: {
      type: Boolean,
      default: false
    },
    isBorderless: {
      type: Boolean,
      default: false
    },
    characterLimit: {
      type: [Number, String],
      default: 0
    },
    iconClass: {
      type: String,
      default: ''
    },
    iconTooltip: {
      type: String,
      default: null
    },
    validation: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    RequiredAsterisk
  },
  data() {
    return {
      inputFocused: false,
      error: null,
      icon: this.iconClass,
      selectedOptionId: null,
      hasFocus: false
    }
  },
  computed: {
    id() {
      return this._uid
    },
    valueField() {
      return this.placeholder
    },
    hasValue() {
      return typeof this.value === 'string' ? this.value && this.value.length > 0 : this.value !== null
    },
    hasError() {
      return {
        errorType: this.isValidationVisible,
        text: this.error
      }
    },
    dataListId() {
      return '#' + this.list
    },
    inputIcon() {
      return this.subText ? 'fas fa-exclamation-circle danger' : this.iconClass
    },
    subText() {
      if (this.errorText) {
        return this.errorText
      } else if (this.error) {
        return this.hasError.text
      } else if (this.value && this.characterLimit && this.value.length > this.characterLimit && this.characterLimit > 0) {
        return this.$t2('character_limit_reached_input', 'Character Limit Reached!', this.$t) + ' ' + this.characterLimit
      } else if (this.value && isNaN(this.value) && this.type === 'number') {
        return 'Invalid Number. Please enter value number'
      } else if (this.validation.expression) {
        return this.validation.message
      }
      return ''
    }
  },
  methods: {
    focusInput() {
      this.$el.getElementsByClassName('text-input__input')[0].focus()
    },
    iconClicked(event) {
      this.$emit('iconClickedEmit', event)
    },
    enterPressed() {
      // const id = this.searchResults ? this.searchResults.find(({ name }) => name === event.target.value).id : ''
      this.$emit('enterPressedEmit')
    },
    onFocus(e) {
      this.$emit('focus', e)
      this.hasFocus = true
    },
    onFocusOut(e) {
      this.hasFocus = false
    },
    inputEventHandler(event) {
      const result = this.searchResults.find(({ name }) => name === event.target.value)
      if (result) {
        this.$emit('input', result.name)
      } else {
        this.$emit('input', event.target.value ? event.target.value : null)
      }
      this.clearError()
    },
    clearError() {
      this.$emit('clearError')
      this.error = null
    }
  }
}
