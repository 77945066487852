// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export default {
  pricedItems: [],
  currentPricedItem: null,
  unassignedPricedItems: [],
  unassignedProducts: [],
  priceLists: [],
  priceListItems: [],
  priceSheetItems: [],
  costSheetItems: [],
  currentPriceList: null,
  groups: [],
  currentGroup: null,
  currencies: [],
  customFields: [],
  currentCustomField: null,
  columnSettings: [],
  error: null
}