// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const PricingRoutes = [
  {
    path: '/pricing/lines/reset',
    name: 'resetLines',
    component:
      () => import(/* webpackChunkName: "resetLines" */ './reset-lines'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricing/header/reset',
    name: 'resetHeader',
    component:
      () => import(/* webpackChunkName: "resetHeader" */ './reset-header'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricing/lines/rebuild',
    name: 'rebuildLines',
    component:
      () => import(/* webpackChunkName: "rebuildLines" */ './rebuild-lines'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricing/header/rebuild',
    name: 'rebuildHeader',
    component:
      () => import(/* webpackChunkName: "rebuildHeader" */ './rebuild-header'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-currencies/',
    name: 'priceCurrencies',
    component: () =>
      import(/* webpackChunkName: "priceCurrencies" */ './price-currencies/price-currencies'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceCurrencies',
          displayLocizeKey: 'breadcrumbs_price-currencies',
          iconClass: 'fa-money-bill-transfer'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-currencies/',
    name: 'currencies',
    component: () =>
      import(/* webpackChunkName: "priceCurrencies" */ './price-currencies/price-currencies'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceCurrencies',
          displayLocizeKey: 'breadcrumbs_price-currencies',
          iconClass: 'far fa-money-bill-wave'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-currencies/price-currency/',
    name: 'addCurrency',
    component: () =>
      import(/* webpackChunkName: "priceCurrency" */ './price-currencies/price-currency'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceCurrencies',
          displayLocizeKey: 'breadcrumbs_price-currencies',
          iconClass: 'far fa-money-bill-wave'
        },
        {
          name: 'addCurrency',
          displayLocizeKey: 'breadcrumbs_price-add-currency',
          iconClass: 'far fa-money-bill-transfer'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-currencies/price-currency/:currencyId?',
    name: 'editCurrency',
    component: () =>
      import(/* webpackChunkName: "priceCurrency" */ './price-currencies/price-currency'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceCurrencies',
          displayLocizeKey: 'breadcrumbs_price-currencies',
          iconClass: 'far fa-money-bill-wave'
        },
        {
          name: 'editCurrency',
          displayLocizeKey: 'breadcrumbs_price-edit-currency',
          iconClass: 'far fa-money-bill-wave'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists/',
    name: 'priceLists',
    component: () =>
      import(/* webpackChunkName: "priceLists" */ './price-lists/price-lists'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceLists',
          displayLocizeKey: 'breadcrumbs_price-lists'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists-new/',
    name: 'priceListsNew',
    component: () =>
      import(/* webpackChunkName: "priceListsNew" */ './price-lists/price-lists-new'),
    meta: {
      breadcrumbs: [
        {
          name: 'priceListsNew',
          displayLocizeKey: 'breadcrumbs_price-lists',
          iconClass: 'fa-box-usd'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists/price-list-info/',
    name: 'priceListInfo',
    component: () =>
      import(/* webpackChunkName: "priceListInfo" */ './price-lists/price-list-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'priceLists',
          displayLocizeKey: 'breadcrumbs_price-lists'
        },
        {
          name: 'priceListInfo',
          displayLocizeKey: 'breadcrumbs_price-list-info'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists-new/price-list-info-new/',
    name: 'priceListInfoNew',
    component: () =>
      import(/* webpackChunkName: "priceListInfoNew" */ './price-lists/price-list-info-new'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'priceListsNew',
          displayLocizeKey: 'breadcrumbs_price-lists'
        },
        {
          name: 'priceListInfoNew',
          displayLocizeKey: 'breadcrumbs_price-list-info'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists/price-list-detail/:priceListId?',
    name: 'priceListDetail',
    component: () =>
      import(/* webpackChunkName: "priceListDetail" */ './price-lists/price-list-detail'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'priceLists',
          displayLocizeKey: 'breadcrumbs_price-lists'
        },
        {
          name: 'priceListDetail'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists-new/price-list-detail-new/:priceListId?',
    name: 'priceListDetailNew',
    component: () =>
      import(/* webpackChunkName: "priceListDetailNew" */ './price-lists/price-list-detail-new'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'priceListsNew',
          displayLocizeKey: 'breadcrumbs_price-lists'
        },
        {
          name: 'priceListDetailNew'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-lists-new/cost-list-detail/:priceListId?',
    name: 'costListDetail',
    component: () =>
      import(/* webpackChunkName: "costListDetail" */ './price-lists/cost-list-detail'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'priceListsNew',
          displayLocizeKey: 'breadcrumbs_price-lists'
        },
        {
          name: 'costListDetail',
          displayLocizeKey: 'breadcrumbs_price-lists',
          iconClass: 'fa-box-usd'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId',
    name: 'pricingLines',
    component: () =>
      import(/* webpackChunkName: "priceRulesDashboard" */ './price-rules/price-rules-dashboard'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules',
          iconClass: 'fa-file-alt'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/uis',
    name: 'pricingLinesUis',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesUis',
          displayLocizeKey: 'breadcrumbs_pricing-lines-uis',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelLines: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/uis/direct-sales-ui',
    name: 'pricingLines-directSalesUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesUis',
          displayLocizeKey: 'breadcrumbs_pricing-lines-uis'
        },
        {
          name: 'pricingLines-directSalesUi',
          displayLocizeKey: 'tenant_portal-for-direct-sales_label',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelLines: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/uis/self-service-ui',
    name: 'pricingLines-selfServiceUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesUis',
          displayLocizeKey: 'breadcrumbs_pricing-lines-uis'
        },
        {
          name: 'pricingLines-selfServiceUi',
          displayLocizeKey: 'breadcrumbs_self-service-ui',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelLines: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/uis/dealer-ui',
    name: 'pricingLines-dealerUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesUis',
          displayLocizeKey: 'breadcrumbs_pricing-lines-uis'
        },
        {
          name: 'pricingLines-dealerUi',
          displayLocizeKey: 'tenant_portal-for-resellers_label',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelLines: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/ui',
    name: 'pricingLinesUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesUi',
          displayLocizeKey: 'breadcrumbs_pricing-ui',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelLines: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/formulas',
    name: 'pricingLinesFormulas',
    component: () =>
      import(/* webpackChunkName: "formulas" */ '../models/formulas/formulas'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesFormulas',
          displayLocizeKey: 'breadcrumbs_formulas',
          iconClass: 'fa-function'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/formulas/formula-info',
    name: 'addPricingLinesFormula',
    component: () =>
      import(/* webpackChunkName: "formula-info" */ '../models/formulas/formula-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesFormulas',
          displayLocizeKey: 'breadcrumbs_formulas'
        },
        {
          name: 'addPricingLinesFormula',
          displayLocizeKey: 'breadcrumbs_formula-add'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/formulas/formula-info/:formulaId?',
    name: 'editPricingLinesFormula',
    component: () =>
      import(/* webpackChunkName: "formula-info" */ '../models/formulas/formula-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesFormulas',
          displayLocizeKey: 'breadcrumbs_formulas'
        },
        {
          name: 'editPricingLinesFormula',
          displayLocizeKey: 'breadcrumbs_formula-edit'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/variables/',
    name: 'pricingLinesVariables',
    component: () =>
      import(/* webpackChunkName: "variables" */ '../models/variables'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesVariables',
          displayLocizeKey: 'breadcrumbs_variables',
          iconClass: 'fa-superscript'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/variables/variable-info/',
    name: 'addPricingLinesVariable',
    component: () =>
      import(/* webpackChunkName: "variableInfo" */ '../models/variable-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesVariables',
          displayLocizeKey: 'breadcrumbs_variables'
        },
        {
          name: 'addPricingLinesVariable',
          displayLocizeKey: 'breadcrumbs_variable_add'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/variables/variable-info/:variableId?',
    name: 'editPricingLinesVariable',
    component: () =>
      import(/* webpackChunkName: "variableInfo" */ '../models/variable-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesVariables',
          displayLocizeKey: 'breadcrumbs_variables'
        },
        {
          name: 'editPricingLinesVariable',
          displayLocizeKey: 'breadcrumbs_variable_edit'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/decision-rules',
    name: 'pricingLinesDecisionRules',
    component: () =>
      import(/* webpackChunkName: "decision-rules" */ '../models/decision-rules/decision-rules'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules',
          iconClass: 'fa-chart-network'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/decision-rules/decision-rule-info/',
    name: 'addPricingLinesDecisionRule',
    component: () =>
      import(/* webpackChunkName: "decision-rule-info" */ '../models/decision-rules/decision-rule-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules'
        },
        {
          name: 'addPricingLinesDecisionRule',
          displayLocizeKey: 'breadcrumbs_decision-rule-add'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/lines/:modelId/decision-rules/decision-rule-editor/:decisionRuleId?',
    name: 'editPricingLinesDecisionRule',
    component: () =>
      import(/* webpackChunkName: "decision-rule-editor" */ '../models/decision-rules/decision-rule-editor'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'pricingLinesDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules'
        },
        {
          name: 'editPricingLinesDecisionRule',
          displayLocizeKey: 'breadcrumbs_decision-rule-edit',
          iconClass: 'fa-chart-network'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId',
    name: 'pricingHeaders',
    component: () =>
      import(/* webpackChunkName: "priceRulesDashboard" */ './price-rules/price-rules-dashboard'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules',
          iconClass: 'fa-file-invoice'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/uis',
    name: 'pricingHeaderUis',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderUis',
          displayLocizeKey: 'breadcrumbs_pricing-header-uis',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelHeaders: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/uis/direct-sales-ui',
    name: 'pricingHeaders-directSalesUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderUis',
          displayLocizeKey: 'breadcrumbs_pricing-header-uis'
        },
        {
          name: 'pricingHeaders-directSalesUi',
          displayLocizeKey: 'tenant_portal-for-direct-sales_label',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelHeaders: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/uis/self-service-ui',
    name: 'pricingHeaders-selfServiceUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderUis',
          displayLocizeKey: 'breadcrumbs_pricing-header-uis'
        },
        {
          name: 'pricingHeaders-selfServiceUi',
          displayLocizeKey: 'breadcrumbs_self-service-ui',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelHeaders: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/uis/dealer-ui',
    name: 'pricingHeaders-dealerUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderUis',
          displayLocizeKey: 'breadcrumbs_pricing-header-uis'
        },
        {
          name: 'pricingHeaders-dealerUi',
          displayLocizeKey: 'tenant_portal-for-resellers_label',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true,
      isPricingModelHeaders: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/ui',
    name: 'pricingHeaderUi',
    component: () =>
      import(/* webpackChunkName: "modelUI" */ '../models/model-uis/model-ui'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderUi',
          displayLocizeKey: 'breadcrumbs_pricing-ui',
          iconClass: 'fa-ruler-combined'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/formulas',
    name: 'pricingHeaderFormulas',
    component: () =>
      import(/* webpackChunkName: "formulas" */ '../models/formulas/formulas'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderFormulas',
          displayLocizeKey: 'breadcrumbs_formulas',
          iconClass: 'fa-function'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/formulas/formula-info',
    name: 'addPricingHeaderFormula',
    component: () =>
      import(/* webpackChunkName: "formula-info" */ '../models/formulas/formula-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderFormulas',
          displayLocizeKey: 'breadcrumbs_formulas'
        },
        {
          name: 'addPricingHeaderFormula',
          displayLocizeKey: 'breadcrumbs_formula-add'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/formulas/formula-info/:formulaId?',
    name: 'editPricingHeaderFormula',
    component: () =>
      import(/* webpackChunkName: "formula-info" */ '../models/formulas/formula-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderFormulas',
          displayLocizeKey: 'breadcrumbs_formulas'
        },
        {
          name: 'editPricingHeaderFormula',
          displayLocizeKey: 'breadcrumbs_formula-edit'
        }
      ],
      requiresAuth: true,
      isPricingFormula: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/variables/',
    name: 'pricingHeaderVariables',
    component: () =>
      import(/* webpackChunkName: "variables" */ '../models/variables'),
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderVariables',
          displayLocizeKey: 'breadcrumbs_variables',
          iconClass: 'fa-superscript'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/variables/variable-info/',
    name: 'addPricingHeaderVariable',
    component: () =>
      import(/* webpackChunkName: "variableInfo" */ '../models/variable-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderVariables',
          displayLocizeKey: 'breadcrumbs_variables'
        },
        {
          name: 'addPricingHeaderVariable',
          displayLocizeKey: 'breadcrumbs_variable_add'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/variables/variable-info/:variableId?',
    name: 'editPricingHeaderVariable',
    component: () =>
      import(/* webpackChunkName: "variableInfo" */ '../models/variable-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderVariables',
          displayLocizeKey: 'breadcrumbs_variables'
        },
        {
          name: 'editPricingHeaderVariable',
          displayLocizeKey: 'breadcrumbs_variable_edit'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/decision-rules',
    name: 'pricingHeaderDecisionRules',
    component: () =>
      import(/* webpackChunkName: "decision-rules" */ '../models/decision-rules/decision-rules'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules',
          iconClass: 'fa-chart-network'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/decision-rules/decision-rule-info/',
    name: 'addPricingHeaderDecisionRule',
    component: () =>
      import(/* webpackChunkName: "decision-rule-info" */ '../models/decision-rules/decision-rule-info'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules'
        },
        {
          name: 'addPricingHeaderDecisionRule',
          displayLocizeKey: 'breadcrumbs_decision-rule-add'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  },
  {
    path: '/pricing/price-rules/header/:modelId/decision-rules/decision-rule-editor/:decisionRuleId?',
    name: 'editPricingHeaderDecisionRule',
    component: () =>
      import(/* webpackChunkName: "decision-rule-editor" */ '../models/decision-rules/decision-rule-editor'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'pricingHeaderDecisionRules',
          displayLocizeKey: 'breadcrumbs_decision-rules'
        },
        {
          name: 'editPricingHeaderDecisionRule',
          displayLocizeKey: 'breadcrumbs_decision-rule-edit',
          iconClass: 'fa-chart-network'
        }
      ],
      requiresAuth: true,
      isPricingModel: true
    }
  }
]
