// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { getSelfServiceDesignerApi } from '@/api/selfServiceDesignerApi'
import handleError from '@/api/handleError'

const actions = {
  fetchTheme({ getters }) {
    return new Promise((resolve, reject) => {
      getSelfServiceDesignerApi().get(`/tenants/${getters.getUserTenantId}/themes`)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  resetTheme({ getters }) {
    return new Promise((resolve, reject) => {
      getSelfServiceDesignerApi().post(`/tenants/${getters.getUserTenantId}/themes/reset-to-default`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  saveTheme({ getters }, { theme }) {
    return new Promise((resolve, reject) => {
      getSelfServiceDesignerApi().post(`/tenants/${getters.getUserTenantId}/themes`, theme)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  actions
}
