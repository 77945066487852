// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import SubTitle from '@/common/layout/sub-title'
import { blurInmplayer, unBlurInmplayer } from '@/common/utils/modalBlur'

export default {
  name: 'about-modal',
  components: {
    SubTitle
  },
  methods: {
    createHelpUrl() {
      return window.KUBE_SETTINGS.OnlineHelp + '/Legal/en/index-en.html'
    }
  },
  mounted() {
    blurInmplayer()
    if (this.$el.querySelector('.button.button-default-outline modal.server-error-modal__buttons__cancel')) this.$el.querySelector('.button.button-default-outline modal.server-error-modal__buttons__cancel').focus()
  },
  destroyed() {
    unBlurInmplayer()
  }
}
