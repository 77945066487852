// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import { mapGetters, mapActions } from 'vuex'
import Accordion from '@/common/layout/accordion'
import featureFlagMixin from '@/mixins/featureFlagMixin'

Vue.use(VTooltip)

export default {
  name: 'navigation-bar',
  components: {
    Accordion
  },
  mixins: [featureFlagMixin],
  props: {
    routeName: {
      type: String
    }
  },
  data() {
    return {
      extendedNav: false,
      lineModelId: null,
      headerModelId: null
    }
  },
  async mounted() {
    if (this.hasUserBeenLoaded) {
      this.lineModelId = await this.fetchPricingLinesModelId()
      this.headerModelId = await this.fetchPricingHeaderModelId()
    }
  },
  async updated() {
    // Solution to the race condition, not pretty but user isn't loaded on mount
    if (this.lineModelId === null || this.headerModelId === null) {
      if (this.hasUserBeenLoaded) {
        this.lineModelId = await this.fetchPricingLinesModelId()
        this.headerModelId = await this.fetchPricingHeaderModelId()
      }
    }
  },
  computed: {
    ...mapGetters({
      hasUserBeenLoaded: 'hasUserBeenLoaded',
      outputsModelId: 'getOutputsModelId',
      tenant: 'getTenant'
    })
  },
  methods: {
    ...mapActions([
      'fetchPricingLinesModelId',
      'fetchPricingHeaderModelId'
    ]),
    extendNav() {
      this.extendedNav = true
    },
    collapseNav() {
      this.extendedNav = false
    }
  }
}