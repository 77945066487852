// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { blurInmplayer, unBlurInmplayer } from '../../utils/modalBlur'

export default {
  name: 'WarningModal',
  props: {
    confirmCb: {
      type: Function,
      required: true
    },
    cancelCb: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: 'Are you sure?'
    },
    description: {
      type: String,
      default: ''
    },
    confirmBtnText: {
      type: String,
      default: 'Ok'
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel'
    }
  },
  mounted() {
    blurInmplayer()
    if (this.$el.querySelector('.button.button-default-outline.modal')) this.$el.querySelector('.button.button-default-outline.modal').focus()
  },
  destroyed() {
    unBlurInmplayer()
  },
  methods: {
    confirmClicked() {
      this.confirmCb()
      this.$emit('close')
    },
    cancelCallBack() {
      this.cancelCb()
      this.$emit('close')
    }
  }
}