// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import { getLocizeErrorsFromErrorResponse, getLocizeErrorsFromErrorObject } from '../../utils/getLocizeErrorsFromErrorResponse'
import { blurInmplayer, unBlurInmplayer } from '../../utils/modalBlur'

export default {
  name: 'ServerErrorModal',
  props: [
    'error'
  ],
  computed: {
    text() {
      if (this.error && this.error.response && this.error.response.data && this.error.response.data.errors) {
        const errors = getLocizeErrorsFromErrorResponse(this.error.response, this.$t)
        return errors.map(error => error.translatedText).join('<br>')
      } else if (this.error && this.error.response && this.error.response.data && this.error.response.status !== 404) {
        return this.error.response.data
      } else if (this.error && this.error.response && this.error.response.status === 404) {
        const errors = getLocizeErrorsFromErrorResponse(this.error.response, this.$t)
        return errors && errors.length > 0 ? errors[0].translatedText : this.error.toString()
      } else if (this.error && this.error.requestName && this.error.errors) {
        const errors = getLocizeErrorsFromErrorObject(this.error, this.$t)
        return errors && errors.length > 0 ? errors[0] : this.error.toString()
      } else {
        return this.error.toString()
      }
    }
  },
  mounted() {
    blurInmplayer()

    if (this.$el.querySelector('.button.button-default-outline.modal.server-error-modal__buttons__cancel')) {
      this.$el.querySelector('.button.button-default-outline.modal.server-error-modal__buttons__cancel').focus()
    }
  },
  destroyed() {
    unBlurInmplayer()
  }
}