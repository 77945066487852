// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import getHostNames from './getHostNames'

export const devEnvID = 'V+YbSWPdR8YOWl5PCnVWfJc/RQpq7wIjuLfhPsbrWcU=' // 'CjyXuU5jSsORu5DsSJ9i/LdSSaWIWJk4KaT9deJQvEU=' // 'NV3S47VCBMyEx2GBUgero4'
export const uatEnvID = 'RuXBWN2HMjfIyXoxiZ79FZk7JYw1ymMN5vTc3f0scok=' // 'kZCQPrFPy9NzDhUdFuJokP'
export const prodEnvID = 'zLOL/DgCpqXu+hshCzQuYwDiqtEYNDKgd4auCIFd8bM=' // 'nUrwHvtuUtKL8Pap5GTtLA'
export const preProdEnvID = '3r2IvOdeiT3w5b6XM2njeLAISOOEIIQXKtuBJH8eoyU=' // 'RUKVHZojSXWzTiK8VBaFbr'
export const demoEnvID = 'LMlTtBZldNL5HrccfNV1nFonTj1GHviJR5oAphESnKQ=' // 'afry47aPb4UrEPVzymHVQE'

export const getFeatureFlagEnvID = (hostname, appName) => {
  if (hostname === 'mycincom.cincom.com' && appName === 'cca') return prodEnvID
  const hostnameToEnvID = getHostnameToEnvID(appName)
  return hostnameToEnvID[hostname] || devEnvID
}

function getHostnameToEnvID(appName) {
  const hostNames = getHostNames(appName)
  return {
    [hostNames.localURL]: devEnvID,
    [hostNames.devURL]: devEnvID,
    [hostNames.devBlueGreenURL]: devEnvID,
    [hostNames.uatBlueGreenURL]: uatEnvID,
    [hostNames.uatURL]: uatEnvID,
    [hostNames.crmURL]: devEnvID,
    [hostNames.demoProdURL]: demoEnvID,
    [hostNames.internalDemoProdURL]: demoEnvID,
    [hostNames.internalProdURL]: prodEnvID,
    [hostNames.internalCentralUSProdURL]: prodEnvID,
    [hostNames.preProdURL]: preProdEnvID,
    [hostNames.preProdBlueGreenURL]: preProdEnvID,
    [hostNames.prodURL]: prodEnvID,
    [hostNames.drURL]: prodEnvID
  }
}
