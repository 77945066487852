// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import 'core-js/stable'
import VModal from 'vue-js-modal'
import VueI18Next from '@panter/vue-i18next'
import initI18Next from '@/common/plugins/initI18Next'
import { t2 } from '@/common/utils/getLocizeFromKey'
import OktaPlugin from '@/common/plugins/oktaPlugin'
import router from './router'
import store from './store'

import App from '@/'
import 'normalize.css'
import '@/common/__assets/fontawesome/fontawesome.scss'
import '@/common/__assets/fontawesome/brands.scss'
import '@/common/__assets/fontawesome/regular.scss'
import '@/common/__assets/fontawesome/solid.scss'

Vue.config.productionTip = false
Vue.use(VueI18Next)
Vue.use(VModal, { dynamic: true })
Vue.use(VTooltip)
Vue.use(OktaPlugin)

// Register a global custom directive called `v-focus`
Vue.directive('readOnly', {
  // When the bound element is inserted into the DOM...
  update: function (el, binding) {
    if (binding.value === true) {
      el.style.pointerEvents = 'none'
      el.style.opacity = 0.5
    } else {
      el.style.pointerEvents = 'unset'
      el.style.opacity = 'unset'
    }
  }
})

let languageCode = 'en-US'
if (window.localStorage.getItem('languageCode')) {
  languageCode = window.localStorage.languageCode
  document.documentElement.setAttribute('lang', languageCode)
}

Vue.prototype.$t2 = t2
const app = new Vue({
  router,
  store,
  i18n: initI18Next(languageCode),
  render: h => h(App)
})
app.$mount('#app')
Vue.prototype.$t = app.$t
