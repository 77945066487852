// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const GroupsRoutes = [{
  path: '/groups/',
  name: 'groups',
  component: () =>
    import('./groups'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'groups',
      displayLocizeKey: 'breadcrumbs_groups',
      iconClass: 'fa-object-group'
    }],
    requiresAuth: true
  }
},
{
  path: '/groups/groups-info/',
  name: 'addGroup',
  component: () =>
    import('./groups-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'groups',
      displayLocizeKey: 'breadcrumbs_groups'
    },
    {
      name: 'addGroup',
      displayLocizeKey: 'breadcrumbs_group_add',
      iconClass: 'fa-object-group'
    }],
    requiresAuth: true
  }
},
{
  path: '/groups/groups-info/:groupId?',
  name: 'editGroup',
  component: () =>
    import('./groups-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'groups',
      displayLocizeKey: 'breadcrumbs_groups'
    },
    {
      name: 'editGroup',
      displayLocizeKey: 'breadcrumbs_edit_group',
      iconClass: 'fa-object-group'
    }
    ],
    requiresAuth: true
  }
}]
