// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import axios from 'axios'
import { setupInterceptors } from '@/utils/httpInterceptors'
import { saveAxiosInstance } from '@/common/utils/tokenPropogater'

const BASE_URL = window.KUBE_SETTINGS.CPQModelDesignerServiceURL

export const getModelDesignerClient = (apiVersion = 1.0, config = {}) => {
  let httpClient = axios.create({
    baseURL: `${BASE_URL}/`,
    crossDomain: true,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'api-version': apiVersion.toFixed(1).toString()
    },
    ...config
  })
  setupInterceptors(httpClient)
  saveAxiosInstance(httpClient)
  return httpClient
}