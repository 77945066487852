// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import { getModelDesignerClient } from '@/api/modelDesignerApi'
import handleError from '@/api/handleError'
import {
  RULES_TABLE_ADD_ROWS,
  RULES_TABLE_SET_CURRENT_RULE,
  RULES_TABLE_SET_CONDITIONS_ENTITIES,
  RULES_TABLE_SET_OUTCOMES_ENTITIES,
  RULES_TABLE_SET_BINDINGS_ENTITIES,
  RULES_TABLE_SET_ENTITIES_DETAILS,
  RULES_TABLE_SET_NAME_AND_DESCRIPTION,
  RULES_TABLE_IS_LOADING_RULES,
  SET_TEMP_SECTION_VISIBILITY_TABLE,
  SET_TEMP_SECTION_ENABLEMENT_TABLE,
  SET_TEMP_SECTION_REQUIREMENT_TABLE,
  SET_TEMP_RECOMMENDATIONS_TABLE,
  SET_TEMP_FILTERS_TABLE,
  SET_TEMP_VISIBILITY_TABLE,
  SET_TEMP_ENABLEMENT_TABLE,
  SET_TEMP_REQUIREMENT_TABLE,
  RULES_TABLE_SET_PRODUCT_OUTCOMES,
  RULES_TABLE_SET_RULE_USEDIN
} from '@/store/types'
import {
  BUNDLE_AVAILABILITY,
  CONSTRAINT,
  SALES_BOM,
  MFG_BOM,
  SECTION_VISIBILITY,
  SECTION_ENABLEMENT,
  SECTION_REQUIRED,
  VISIBILITY,
  ENABLEMENT,
  RENDERING,
  MODEL,
  REQUIRED,
  RECOMMENDATIONS,
  FILTERS
} from '@/constants/ruleTableTypes'
import { getConditionsUsageByContext, getBuildVariationsRouteByContext, getUpdateRuleHeaderRouteByContext } from './utils'
import * as utils from '@/utils/ruleUtils'

const MODELS_API = 'models'

export default {
  fetchRulesTable({ dispatch, commit, getters, state }, payloadPlusBOM) {
    const payload = payloadPlusBOM.payload
    return new Promise(async (resolve, reject) => {
      const conditionsUsage = getConditionsUsageByContext(getters.getRuleContext)
      const ruleContext =
        getters.getDecisionRules.find(rule => rule.id === payload.decisionRuleId)?.ruleContext ||
        getters.getRuleUsedIn
      commit(RULES_TABLE_SET_RULE_USEDIN, ruleContext)
      const conditionsEntities = await dispatch('fetchModelCollections', {
        modelId: payload.modelId,
        usage: ruleContext === 'Option' ? 'OptionDecisionRuleConditions' : conditionsUsage
      })
      const outcomesEntities = await dispatch('fetchModelCollections', {
        modelId: payload.modelId,
        usage: ruleContext === 'Option' ? 'OptionDecisionRuleOutcomes' : 'DecisionRuleOutcomes'
      })
      const bindingsEntities = await dispatch('fetchModelCollections', {
        modelId: payload.modelId,
        usage: ruleContext === 'Option' ? 'OptionDecisionRuleBindings' : 'DecisionRuleBindings'
      })
      if (payloadPlusBOM.isFeatureEnabledManagerTerminology) {
        bindingsEntities.parameters.forEach(parameter => {
          if (parameter.modelItemType === 'Parameter') {
            parameter.modelItemType = 'Global Variable'
          }
        })
      }
      
      // const productEntities = await dispatch('fetchModelCollections', {
      //   modelId: payload.modelId,
      //   usage: ruleContext === 'Option' ? 'OptionDecisionRuleBindings' : 'DecisionRuleBindings'
      // })

      commit(RULES_TABLE_SET_CONDITIONS_ENTITIES, conditionsEntities)
      commit(RULES_TABLE_SET_OUTCOMES_ENTITIES, outcomesEntities)
      commit(RULES_TABLE_SET_BINDINGS_ENTITIES, bindingsEntities)
      // commit(RULES_TABLE_SET_CHILD_PRODUCTS, productEntities)

      switch (getters.getRuleContext) {
        case RENDERING:
        case VISIBILITY: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempVisibilityTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempVisibilityTable)

          break
        }
        case SECTION_VISIBILITY: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionVisibilityTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempSectionVisibilityTable)

          break
        }
        case SECTION_ENABLEMENT: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionEnablementTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempSectionEnablementTable)

          break
        }
        case SECTION_REQUIRED: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionRequirementTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempSectionRequirementTable)

          break
        }
        case ENABLEMENT: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempEnablementTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempEnablementTable)

          break
        }
        case REQUIRED: {
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempRequirementTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempRequirementTable)

          break
        }
        case RECOMMENDATIONS: {
          const { products = [] } = await dispatch('fetchModelItems', {
            modelId: state.relationshipChildModelId,
            commitToStore: false,
            payload: {
              'includeProducts': true
            }
          })

          commit(RULES_TABLE_SET_PRODUCT_OUTCOMES, products)
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempRecommendationsTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempRecommendationsTable)

          break
        }
        case FILTERS: {
          const { products = [] } = await dispatch('fetchModelItems', {
            modelId: state.relationshipChildModelId,
            commitToStore: false,
            payload: {
              'includeProducts': true
            }
          })

          commit(RULES_TABLE_SET_PRODUCT_OUTCOMES, products)
          commit(RULES_TABLE_SET_CURRENT_RULE, state.tempFiltersTable)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve(state.tempFiltersTable)

          break
        }
        case BUNDLE_AVAILABILITY: {
          await dispatch('fetchBundleAvailabilityRule', payload)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve()
          break
        }
        case SALES_BOM:
          payloadPlusBOM.isFeatureEnabledBOM
            ? await dispatch('fetchBomNestedDecisionRule', payload)
            : await dispatch('fetchBomDecisionRule', payload)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve()
          break
        case MFG_BOM: { // BOM
          await dispatch('fetchBomDecisionRule', payload)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve()
          break
        }
        case MODEL: { // MODEL Decision RUles
          await dispatch('fetchDecisionRule', payload)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve()
          break
        }
        case CONSTRAINT: {
          await dispatch('fetchConstraintRuleTable', payload)
          commit(RULES_TABLE_IS_LOADING_RULES, false)
          resolve()
          break
        }
        default: {
          console.error('No context given')
        }
      }
    })
  },
  fetchModelItems({ state, getters, commit }, { modelId, commitToStore = false, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .post(`/tenants/${getters.getUserTenantId}/models/${modelId}/items`, payload)
        .then(response => {
          if (commitToStore) {
            commit(RULES_TABLE_SET_ENTITIES_DETAILS, response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateDecisionMatrix({ getters }, payloadPlusBOM) {
    const { constraintId, entityId, decisionRuleId, bomId, modelId, bundleId, groupId, rows, conditions, outcomes } = payloadPlusBOM.payload
    return new Promise(async (resolve, reject) => {
      const ruleContextToRoute = {
        [CONSTRAINT]: `tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraint-tables/${constraintId}`,
        [SALES_BOM]: payloadPlusBOM.isFeatureEnabledBOM
          ? `tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`
          : `tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-item-decision-rules/${entityId}`,
        [MFG_BOM]: `tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-item-decision-rules/${entityId}`,
        [MODEL]: `tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/decision-rules/${decisionRuleId}`,
        [BUNDLE_AVAILABILITY]: `/tenants/${getters.getUserTenantId}/models/${modelId}/bundles/${bundleId}/availabilities/${groupId}`
      }

      const route = ruleContextToRoute[getters.getRuleContext]

      const rowsWithoutEmpties = utils.getTableWithoutEmpties(rows)

      getModelDesignerClient(2.0)
        .put(route, {
          rows: rowsWithoutEmpties,
          outcomes,
          conditions
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addDecisionRule({ getters }, { modelId, decisionRule }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/decision-rules`, decisionRule)
        .then(response => {
          response.data.rows = utils.addRowIdToDecisionMatrix(response.data.rows)
          resolve(response.data)
        })
        .catch(error => {
          if (error.response.data.errors[0].code === 1516) {
            reject(error)
          } else {
            handleError(error)
            reject(error)
          }
        })
    })
  },
  fetchDecisionRule({ commit, getters }, { modelId, decisionRuleId, commitToStore = true }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/decision-rules/${decisionRuleId}`)
        .then(res => {
          if (commitToStore) {
            res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
            commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          }
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  addBomDecisionRule({ getters }, { modelId, decisionRule, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-item-decision-rules/${entityId}`, decisionRule)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addBomNestedDecisionRule({ getters }, { modelId, bomId, decisionRule, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`, decisionRule)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addBomNestedCustomDecisionRule({ getters }, { modelId, bomId, decisionRule, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`, decisionRule)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomDecisionRule({ commit, getters }, { modelId, entityId, commitToStore = true }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-item-decision-rules/${entityId}`)
        .then(res => {
          if (commitToStore) {
            res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
            commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          }
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  fetchBomNestedDecisionRule({ commit, getters }, { modelId, bomId, entityId, commitToStore = true }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`)
        .then(res => {
          if (commitToStore) {
            res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
            commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          }
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  fetchBomNestedCustomDecisionRule({ commit, getters }, { modelId, bomId, entityId, commitToStore = true }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`)
        .then(res => {
          if (commitToStore) {
            res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
            commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          }
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  deleteBomDecisionRule({ getters }, { modelId, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-item-decision-rules/${entityId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteBomNestedDecisionRule({ getters }, { modelId, bomId, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteBomNestedCustomDecisionRule({ getters }, { modelId, bomId, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}/decision-rules/${entityId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBundleAvailabilityRule({ getters, commit }, { modelId, bundleId, groupId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}/availabilities/${groupId}`)
        .then(res => {
          res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
          commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  deleteBundleAvailabilityRule({ getters }, { modelId, bundleId, groupId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .delete(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}/availabilities/${groupId}`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  buildVariations({ getters, commit, state }, payloadPlusBOM) {
    const { constraintId, entityId, decisionRuleId, bomId, modelId, bundleId, groupId } = payloadPlusBOM.payload
    return new Promise((resolve, reject) => {
      const endpoint = getBuildVariationsRouteByContext({
        context: getters.getRuleContext,
        tenantId: getters.getUserTenantId,
        relationshipChildModelId: state.relationshipChildModelId,
        constraintId,
        modelId,
        entityId,
        bundleId,
        groupId,
        decisionRuleId,
        bomId: bomId,
        isFeatureEnabledBOM: payloadPlusBOM.isFeatureEnabledBOM
      })

      if (!endpoint) reject(new Error('Could not build route needed to build variations.'))

      const payload = {
        rows: utils.getTableWithoutEmpties(getters.getRulesTable.rows),
        conditions: getters.getRulesTable.conditions,
        outcomes: getters.getRulesTable.outcomes
      }

      getModelDesignerClient(2.0)
        .post(endpoint, payload)
        .then(response => {
          response.data.rows = utils.addRowIdToDecisionMatrix(response.data.rows)

          switch (getters.getRuleContext) {
            case SECTION_VISIBILITY:
              commit(SET_TEMP_SECTION_VISIBILITY_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionVisibilityTable)
              break
            case SECTION_ENABLEMENT:
              commit(SET_TEMP_SECTION_ENABLEMENT_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionEnablementTable)
              break
            case SECTION_REQUIRED:
              commit(SET_TEMP_SECTION_REQUIREMENT_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempSectionRequirementTable)
              break
            case VISIBILITY:
              commit(SET_TEMP_VISIBILITY_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempVisibilityTable)
              break
            case ENABLEMENT:
              commit(SET_TEMP_ENABLEMENT_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempEnablementTable)
              break
            case REQUIRED:
              commit(SET_TEMP_REQUIREMENT_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempRequirementTable)
              break
            case RECOMMENDATIONS:
              commit(SET_TEMP_RECOMMENDATIONS_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempRecommendationsTable)
              break
            case FILTERS:
              commit(SET_TEMP_FILTERS_TABLE, response.data)
              commit(RULES_TABLE_SET_CURRENT_RULE, state.tempFiltersTable)
              break
            case RENDERING:
              break
            default:
              commit(RULES_TABLE_SET_CURRENT_RULE, response.data)
          }

          resolve({ data: response.data, numberOfAddedRows: response.data.rows.length - payload.rows.length })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchConstraintRuleTable({ commit }, { tenantId, modelId, constraintId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0)
        .get(`tenants/${tenantId}/${MODELS_API}/${modelId}/constraint-tables/${constraintId}`)
        .then(res => {
          res.data.rows = utils.addRowIdToDecisionMatrix(res.data.rows)
          commit(RULES_TABLE_SET_CURRENT_RULE, res.data)
          if (res.data.rows.length === 0) {
            commit(RULES_TABLE_ADD_ROWS, 5)
          }
          resolve(res.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  updateRuleHeader({ commit, getters }, { tenantId, modelId, constraintId, decisionRuleId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      const route = getUpdateRuleHeaderRouteByContext({
        context: getters.getRuleContext,
        tenantId: getters.getUserTenantId,
        modelId,
        constraintId,
        decisionRuleId
      })

      getModelDesignerClient(2.0)
        .put(route, payload)
        .then(response => {
          commit(RULES_TABLE_SET_NAME_AND_DESCRIPTION, response.data)
          resolve(response.data)
        })
        .catch(error => {
          if (error?.response?.data?.errors && !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  }
}