// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const TranslationsRoutes = [
  {
    path: '/translations',
    name: 'translations',
    component:
      () => import('./translations'),
    meta: {
      breadcrumbs: [
        {
          name: 'translations',
          displayLocizeKey: 'settings_translations-ui_list-item',
          iconClass: 'fa-language'
        }
      ],
      requiresAuth: true
    }
  }
]
