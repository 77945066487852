// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import { mapActions } from 'vuex'
import TextInput from '@/common/form-controls/text-input'
import TextArea from '@/common/form-controls/text-area'
import Toast from '@/common/popups-and-indicators/toast'
import SimpleDropdown from '@/common/form-controls/dropdowns/simple-dropdown'
import VueOverlay from '@/common/layout/vue-overlay'
import { USA_ENGLISH } from '@/common/constants/locales'

export default {
  name: 'support-form',
  components: { SimpleDropdown, TextInput, TextArea, Toast, VueOverlay },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      description: '',
      phone: '',
      severityId: '3 - Medium',
      isToastVisible: false,
      language: USA_ENGLISH,
      // these used to be dynamic so I am leaving them in vm in case we need to change them back
      formActionLocation: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
      orgId: '00D41000000eXws',
      oktaInfo: {},
      company: ''
    }
  },
  async created() {
    this.oktaInfo = JSON.parse(localStorage.getItem('okta-token-storage')) 
    const tenant = await this.fetchTenant()
    this.company = tenant.name
  },
  mounted() {
    const { supportCaseSubmitted } = this.$route.query

    this.isToastVisible = supportCaseSubmitted === true
    if (this.isToastVisible) {
      setTimeout(() => {
        this.isToastVisible = false
      }, 3000)
    }
  },
  computed: {
    disabled() {
      return !(this.subject && this.description)
    },
    returnUrl() {
      return `${document.location.origin}?supportCaseSubmitted=true`
    },
    severityTypes() {
      return [
        { id: '1 - Critical', name: this.$t2('support_critical', 'Critical', this.$t) },
        { id: '2 - High', name: this.$t2('support_high', 'High', this.$t) },
        { id: '3 - Medium', name: this.$t2('support_medium', 'Medium', this.$t) },
        { id: '4 - Low', name: this.$t2('support_low', 'Low', this.$t) }
      ]
    }
  },
  methods: {
    ...mapActions([
      'createSupportTicket',
      'fetchTenant'
    ]),
    changeSeverity(severity) {
      this.severityId = severity.id
    },
    async submit(e) {
      this.$emit('navigate-to-guided-help', 'form-submit')

      // await fetch(this.formActionLocation, {
      //   body: new URLSearchParams([...new FormData(e.target).entries()]),
      //   method: 'POST'
      // })
      const formEntries = [...new FormData(e.target).entries()]
      let payload = {
        Company: formEntries.find(entry => entry[0] === 'company')[1],
        'Contact Name': formEntries.find(entry => entry[0] === 'name')[1],
        Email: formEntries.find(entry => entry[0] === 'email')[1],
        Severity: formEntries.find(entry => entry[0] === 'Severity__c')[1],
        'Selected Product': 'Cincom CPQ Manager',
        Subject: formEntries.find(entry => entry[0] === 'subject')[1],
        Description: formEntries.find(entry => entry[0] === 'description')[1],
        FName: this.oktaInfo.idToken.claims.firstName,
        LName: this.oktaInfo.idToken.claims.lastName,
        Phone: formEntries.find(entry => entry[0] === 'phone')[1]
      }
      this.createSupportTicket({ payload })
    },
    async handleMessage(e) {
      this.name = e.data.name
      this.email = e.data.email
    }
  }
}
