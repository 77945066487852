// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED                      
import {
  VALUE_NUMERIC,
  VALUE_STRING, VALUE_DATE,
  VALUE_NUMERIC_RANGE,
  VALUE_DATE_RANGE,
  VALUE_LIST,
  VALUE_BOOLEAN,
  VALUE_MULTI_SELECT
} from '@/constants/valuesTypes'
import {
  VARIABLE_LIST,
  VARIABLE_NUMERIC,
  VARIABLE_STRING,
  VARIABLE_BOOLEAN,
  VARIABLE_DATE
} from '@/constants/variableTypes'
import getDefaultRuleItem from './getDefaultRuleItem'
import { OPERATOR_ENUM } from '@/constants/operators'
import generateDecisionMatrixId from '@/utils/generateDecisionMatrixId'
import outcomeTypes from '@/modules/misc/rules-table/outcomeTypes'
import quantityTypes from '@/modules/misc/rules-table/quantityTypes'
import { SystemProperty } from '@/constants/objectTypes'

export const entityMap = {
  Variable: 'variables',
  Parameter: 'parameters',
  LineIntegration: 'lineIntegrations',
  HeaderIntegration: 'headerIntegrations',
  SystemGroup: 'systemGroups',
  SystemProperty: 'systemProperties',
  SystemObject: 'systemObjects',
  BomItemGroup: 'bomItemGroups',
  ConfiguredProductGroup: 'configuredProductGroups',
  ProductCustomField: 'productCustomFields',
  BasicProductGroup: 'basicProductGroups',
  OptionGroup: 'optionGroups',
  Products: 'products',
  Relationship: 'relationships',
  Adjustment: 'adjustments',
  OptionSystemProperty: 'optionSystemProperties',
  OptionCustomField: 'optionCustomFields',
  BomSection: 'bomSections'
}

export const isRowComplete = row => isEntireRowEmpty(row) || isEntireRowCompleted(row)

export const isEmptyCell = value => value === '' || value === null || value === undefined || value === {}

export const findRowByRowId = (rowId, rows) => rows.find(r => r.rowId === rowId) || {}

export const getOutcome = (rowId, rows, outcomeIndex) => findRowByRowId(rowId, rows).outcomes[outcomeIndex]

export const getEntityTypeById = (entities, entityId) => entities.find(({ id }) => id === entityId).dataType

export const getTableWithoutEmpties = (rows = []) => rows.filter(isEntireRowCompleted)

export const ruleDTOMapper = getAllEntitiesForModel => {
  return id => {
    const { objectType, dataType, name, binding } = getAllEntitiesForModel.find(e => e.id === id)
    return binding.bindingType === 'SystemProperty'
      ? {
        bindingType: binding.bindingType,
        entityType: objectType,
        systemProperty: name,
        dataType,
        id
      }
      : binding.bindingType === 'ProductCustomField'
        ? {
          bindingType: binding.bindingType,
          entityType: objectType,
          name,
          dataType,
          id,
          customFieldId: binding.customFieldId
        }
        : {
          bindingType: binding.bindingType,
          entityType: objectType,
          name,
          dataType,
          id
        }
  }
}

export const getDefaultValueTypeForEntitie = ({ objectType, dataType, isMultiSelect = false }) =>
  isMultiSelect
    ? VALUE_MULTI_SELECT
    : (objectType === 'OptionGroup')
      ? VALUE_LIST
      : getDefaultValueTypeByVariableType(dataType)

export const getVariableType = ({ objectType, dataType }) =>
  (objectType === 'OptionGroup')
    ? VARIABLE_LIST
    : dataType

export const getDefaultValueByValueType = conditionType => {
  const currentISOStringDate = new Date(Date.now()).toISOString()
  const currentISOStringDateWithoutHours = currentISOStringDate
    .substring(0, currentISOStringDate.indexOf('T') + 1) + '00:00:00.000Z'

  return {
    [VALUE_NUMERIC]: 0,
    [VALUE_NUMERIC_RANGE]: 0,
    [VALUE_LIST]: [],
    [VALUE_STRING]: '',
    [VALUE_DATE]: currentISOStringDateWithoutHours,
    [VALUE_DATE_RANGE]: currentISOStringDateWithoutHours,
    [VALUE_BOOLEAN]: null
  }[conditionType]
}

export const getDefaultValueTypeByVariableType = dataType => {
  return {
    [VARIABLE_LIST]: VALUE_LIST,
    [VARIABLE_NUMERIC]: VALUE_NUMERIC,
    [VARIABLE_STRING]: VALUE_STRING,
    [VARIABLE_BOOLEAN]: VALUE_BOOLEAN,
    [VARIABLE_DATE]: VALUE_DATE
  }[dataType]
}

export const addRowIdToDecisionMatrix = (rows = []) => {
  return rows.map(decisionRule => ({
    ...decisionRule,
    rowId: generateDecisionMatrixId.getId()
  }))
}

export const getValueTypeByOperatorAndEntityType = (entityType, operatorValue, currentValueType, isMultiSelect) => {
  if (isMultiSelect) return VALUE_MULTI_SELECT

  if (entityType === VARIABLE_NUMERIC) {
    if (operatorValue === OPERATOR_ENUM.Between) {
      return VALUE_NUMERIC_RANGE
    } else {
      return VALUE_NUMERIC
    }
  } else if (entityType === VARIABLE_DATE) {
    if (operatorValue === OPERATOR_ENUM.Between) {
      return VALUE_DATE_RANGE
    } else {
      return VALUE_DATE
    }
  } else if (entityType === VARIABLE_LIST) {
    return VALUE_LIST
  }

  return currentValueType
}

export const getDefaultRuleItemsForEntities = selectedConditionEntities => {
  return selectedConditionEntities.map(condition => {
    const { operator, isMultiSelect } = condition
    const { id, dataType, entityType: objectType } = condition.binding

    const conditionType = getValueTypeByOperatorAndEntityType(dataType, operator, null, isMultiSelect)
    return getDefaultRuleItem({ objectType, dataType, id, operator, conditionType, isMultiSelect })
  })
}

export const isEntireRowEmpty = row => {
  const conditionsEmpty = row.conditions.every(({ conditionType, value, lowerBoundValue, upperBoundValue, values, operator, comparisonType, binding }) => {
    if (comparisonType === 'Binding') {
      return binding === null || binding === null
    }
    if (operator === OPERATOR_ENUM.NoValue || operator === OPERATOR_ENUM.AllValues) {
      return false
    }
    if (conditionType === VALUE_NUMERIC_RANGE || conditionType === VALUE_DATE_RANGE) {
      return isEmptyCell(lowerBoundValue) || isEmptyCell(upperBoundValue)
    } else if (conditionType === VALUE_LIST) {
      return values.length === 0
    }
    return value === '' || value === undefined || value === null
  })

  const outcomesEmpty = (row.outcomes || []).every(({ value, outcomeType }) => {
    if (outcomeType === outcomeTypes.NO_VALUE) {
      return false
    }
    return value === '' || value === undefined || value === null
  })

  return conditionsEmpty && outcomesEmpty
}

export const isEntireRowCompleted = row => {
  return areConditionsCompleted(row) && areOutcomesCompleted(row)
}

export function areConditionsCompleted(row) {
  return row.conditions.every(({ conditionType, value, lowerBoundValue, upperBoundValue, values, operator, binding, comparisonType }) => {
    if (operator === OPERATOR_ENUM.NoValue || operator === OPERATOR_ENUM.AllValues) {
      return true
    }
    if (comparisonType === 'Value' || comparisonType === undefined || comparisonType === null) {
      if (conditionType === VALUE_NUMERIC_RANGE || conditionType === VALUE_DATE_RANGE) { // use only for ranges
        return !isEmptyCell(lowerBoundValue) && !isEmptyCell(upperBoundValue)
      } else if (conditionType === VALUE_LIST || conditionType === VALUE_MULTI_SELECT) {
        return values.length !== 0
      }
      return !isEmptyCell(value)
    } else {
      return !isEmptyCell(binding)
    }
  })
}

export function areOutcomesCompleted(row) {
  return (row.outcomes || []).every(outcome => {
    return isQuantityComplete(outcome)
  })
}

export function isQuantityComplete({ dataType, quantity, quantityType, quantityBinding }) {
  if (dataType !== 'List') return true
  if (dataType === 'List' && !quantityType) return true
  if (quantityType === 'Value') return !!quantity && !isNaN(quantity)
  if (quantityType === 'Binding') return !!quantityBinding
}

export function getEntityById(entities, id, entityType) {
  const collection = entities[entityMap[entityType]]
  return collection ? collection.find(e => e.id === id) : {}
}

export function getObjectName(items, binding, fallback) {
  let object = null
  if (binding && items) {
    const bindingType = binding.bindingType
    switch (bindingType) {
      case 'Entity':
        object = items.find(item => item.id === binding.id)
        break
      case 'CustomField':
        const entity = items.find(item => item.id === binding.entityId)
        if (entity && entity.customFields) {
          object = entity.customFields.find(customField => customField.id === binding.customFieldId)
        }
        break
      case 'Relationship':
      case 'Adjustment':
        object = items.find(item => item.id === binding.id)
        break
      case 'ProductCustomField':
      case 'OptionCustomField':
        object = items.find(item => item.id === binding.customFieldId)
        break
      case 'SystemProperty':
      case 'OptionSystemProperty':
        return binding.systemProperty
    }
  }
  return object ? object.name : fallback
}

export function getOutcomeForRow(entity) {
  const mapDataTypeToKey = {
    'List': 'values',
    'Boolean': 'value',
    'Numeric': 'value',
    'String': 'value',
    'Date': 'value',
    'Custom': 'value'
  }
  const mapDataTypeToValue = {
    'List': [],
    'Boolean': false,
    'Numeric': 0,
    'String': '',
    'Date': new Date(new Date(Date.now())
      .setUTCHours(0, 0, 0, 0))
      .toISOString(),
    'Custom': {
      customDataType: entity.binding.customDataType,
      items: []
    }
  }

  return {
    [mapDataTypeToKey[entity.dataType]]: mapDataTypeToValue[entity.dataType],
    outcomeType: getOutcomeTypeForRow(entity, entity.dataType),
    dataType: entity.dataType,
    customDataType: entity.binding.customDataType ? entity.binding.customDataType : null,
    binding: null,
    quantity: 1,
    quantityType: quantityTypes.VALUE,
    quantityBinding: null
  }
}

export function getOutcomeTypeForRow(entity, dataType) {
  const id = entity.id || entity?.binding?.id || entity?.binding?.systemProperty
  if (entity.modelItemType === SystemProperty && id === 'Quantity')
    return outcomeTypes.VALUE
  return entity.dataType === 'Boolean' ? outcomeTypes.VALUE : outcomeTypes.NO_VALUE
}