// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
// UI labels
export const OPERATOR_LABELS = {
  EQUALS: 'decision-rule_operator_equals',
  DOES_NOT_EQUAL: 'decision-rule_operator_does_not_equal',
  GREATER_THAN: 'decision-rule_operator_greater_than',
  GREATER_THAN_OR_EQUAL: 'decision-rule_operator_greater_than_or_equal',
  LESS_THAN: 'decision-rule_operator_less_than',
  LESS_THAN_OR_EQUAL: 'decision-rule_operator_less_than_or_equal',
  CONTAINS: 'decision-rule_operator_contains',
  DOES_NOT_CONTAIN: 'decision-rule_operator_does_not_contain',
  BETWEEN: 'decision-rule_operator_between',
  STARTS_WITH: 'decision-rule_operator_begins_with',
  ENDS_WITH: 'decision-rule_operator_ends_with',
  DOES_NOT_START_WITH: 'decision-rule_operator_does_not_begin_with',
  DOES_NOT_END_WITH: 'decision-rule_operator_does_not_end_with',
  AFTER: 'decision-rule_operator_after',
  BEFORE: 'decision-rule_operator_before',
  NOVALUE: 'rules-table_no-values_label',
  ALLVALUES: 'rules-table_all-values_label',
  BEFORE_OR_EQUAL_TO: 'rules-table_before-or-equal-to',
  AFTER_OR_EQUAL_TO: 'rules-table_after-or-equal-to',
  SUBSET: 'decision-rule_operator_subset',
  NOT_SUBSET: 'decision-rule_operator_not-subset',
  INTERSECTION: 'decision-rule_operator_intersection',
  SYMMETRIC_DIFFERENCE: 'decision-rule_operator_symmetric-difference'
}

export const OPERATOR_ENUM = {
  EqualTo: 'EqualTo',
  NotEqualTo: 'NotEqualTo',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqualTo: 'GreaterThanOrEqualTo',
  LessThan: 'LessThan',
  LessThanOrEqualTo: 'LessThanOrEqualTo',
  Contains: 'Contains',
  DoesNotContain: 'DoesNotContain',
  Between: 'Between',
  StartsWith: 'StartsWith',
  EndsWith: 'EndsWith',
  BeforeOrEqualTo: 'BeforeOrEqualTo',
  AfterOrEqualTo: 'AfterOrEqualTo',
  DoesNotStartWith: 'DoesNotStartWith',
  DoesNotEndWith: 'DoesNotEndWith',
  After: 'After',
  Before: 'Before',
  NoValue: 'NoValue',
  AllValues: 'AllValues',
  Subset: 'Subset',
  NotSubset: 'NotSubset',
  Intersection: 'Intersection',
  SymmetricDifference: 'SymmetricDifference'
}

export const DEFAULT_NUMERIC_LOWER_BOUND_OPERATOR = OPERATOR_ENUM.GreaterThanOrEqualTo
export const DEFAULT_NUMERIC_UPPER_BOUND_OPERATOR = OPERATOR_ENUM.LessThanOrEqualTo

export const DEFAULT_DATE_LOWER_BOUND_OPERATOR = OPERATOR_ENUM.After
export const DEFAULT_DATE_UPPER_BOUND_OPERATOR = OPERATOR_ENUM.Before

export const OPERATOR_DICTIONARY = {
  [OPERATOR_ENUM.EqualTo]: OPERATOR_LABELS.EQUALS,
  [OPERATOR_ENUM.NotEqualTo]: OPERATOR_LABELS.DOES_NOT_EQUAL,
  [OPERATOR_ENUM.GreaterThan]: OPERATOR_LABELS.GREATER_THAN,
  [OPERATOR_ENUM.GreaterThanOrEqualTo]: OPERATOR_LABELS.GREATER_THAN_OR_EQUAL,
  [OPERATOR_ENUM.LessThan]: OPERATOR_LABELS.LESS_THAN,
  [OPERATOR_ENUM.LessThanOrEqualTo]: OPERATOR_LABELS.LESS_THAN_OR_EQUAL,
  [OPERATOR_ENUM.Contains]: OPERATOR_LABELS.CONTAINS,
  [OPERATOR_ENUM.DoesNotContain]: OPERATOR_LABELS.DOES_NOT_CONTAIN,
  [OPERATOR_ENUM.Between]: OPERATOR_LABELS.BETWEEN,
  [OPERATOR_ENUM.StartsWith]: OPERATOR_LABELS.STARTS_WITH,
  [OPERATOR_ENUM.EndsWith]: OPERATOR_LABELS.ENDS_WITH,
  [OPERATOR_ENUM.DoesNotStartWith]: OPERATOR_LABELS.DOES_NOT_START_WITH,
  [OPERATOR_ENUM.After]: OPERATOR_LABELS.AFTER,
  [OPERATOR_ENUM.Before]: OPERATOR_LABELS.BEFORE,
  [OPERATOR_ENUM.NoValue]: OPERATOR_LABELS.NOVALUE,
  [OPERATOR_ENUM.AllValues]: OPERATOR_LABELS.ALLVALUES,
  [OPERATOR_ENUM.BeforeOrEqualTo]: OPERATOR_LABELS.BEFORE_OR_EQUAL_TO,
  [OPERATOR_ENUM.AfterOrEqualTo]: OPERATOR_LABELS.AFTER_OR_EQUAL_TO,
  [OPERATOR_ENUM.DoesNotEndWith]: OPERATOR_LABELS.DOES_NOT_END_WITH,
  [OPERATOR_ENUM.Subset]: OPERATOR_LABELS.SUBSET,
  [OPERATOR_ENUM.NotSubset]: OPERATOR_LABELS.NOT_SUBSET,
  [OPERATOR_ENUM.Intersection]: OPERATOR_LABELS.INTERSECTION,
  [OPERATOR_ENUM.SymmetricDifference]: OPERATOR_LABELS.SYMMETRIC_DIFFERENCE
}

export const OPERATOR_ICONS = {
  [OPERATOR_ENUM.EqualTo]: 'fas fa-equals',
  [OPERATOR_ENUM.NotEqualTo]: 'fas fa-not-equal',
  [OPERATOR_ENUM.GreaterThan]: 'fas fa-greater-than',
  [OPERATOR_ENUM.GreaterThanOrEqualTo]: 'fas fa-greater-than-equal',
  [OPERATOR_ENUM.LessThan]: 'fas fa-less-than',
  [OPERATOR_ENUM.LessThanOrEqualTo]: 'fas fa-less-than-equal',
  [OPERATOR_ENUM.Between]: 'fas fa-exchange-alt',
  [OPERATOR_ENUM.StartsWith]: 'fas fa-step-forward',
  [OPERATOR_ENUM.EndsWith]: 'fas fa-step-backward',
  [OPERATOR_ENUM.StartsWith]: 'fas fa-step-forward',
  [OPERATOR_ENUM.Contains]: 'fas fa-asterisk',
  [OPERATOR_ENUM.DoesNotContain]: 'fas fa-ban',
  [OPERATOR_ENUM.After]: 'fas fa-step-forward',
  [OPERATOR_ENUM.Before]: 'fas fa-step-backward',
  [OPERATOR_ENUM.NoValue]: 'fas fa-empty-set',
  [OPERATOR_ENUM.AllValues]: 'fas fa-check-double',
  [OPERATOR_ENUM.BeforeOrEqualTo]: 'fas fa-fast-backward',
  [OPERATOR_ENUM.AfterOrEqualTo]: 'fas fa-fast-forward',
  [OPERATOR_ENUM.DoesNotEndWith]: 'fas fa-chevron-circle-right',
  [OPERATOR_ENUM.DoesNotStartWith]: 'fas fa-chevron-circle-left',
  [OPERATOR_ENUM.Subset]: 'subset-1.svg',
  [OPERATOR_ENUM.NotSubset]: 'not-subset.svg',
  [OPERATOR_ENUM.Intersection]: 'fas fa-intersection',
  [OPERATOR_ENUM.SymmetricDifference]: 'fas fa-theta'
}

export const ICON_FORMAT_FONTAWESOME = 'FontAwesome'
export const ICON_FORMAT_IMAGE = 'Image'

export const OPERATOR_ICONS_FORMAT = {
  [OPERATOR_ENUM.EqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.NotEqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.GreaterThan]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.GreaterThanOrEqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.LessThan]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.LessThanOrEqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.Between]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.StartsWith]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.EndsWith]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.StartsWith]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.Contains]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.DoesNotContain]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.After]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.Before]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.NoValue]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.AllValues]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.BeforeOrEqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.AfterOrEqualTo]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.DoesNotEndWith]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.DoesNotStartWith]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.Subset]: ICON_FORMAT_IMAGE,
  [OPERATOR_ENUM.NotSubset]: ICON_FORMAT_IMAGE,
  [OPERATOR_ENUM.Intersection]: ICON_FORMAT_FONTAWESOME,
  [OPERATOR_ENUM.SymmetricDifference]: ICON_FORMAT_FONTAWESOME
}

export const getOperatorLabel = operatorEnum => {
  return OPERATOR_DICTIONARY[operatorEnum] || operatorEnum
}

export const getOperatorIcon = operatorEnum => {
  return OPERATOR_ICONS[operatorEnum] || 'fas fa-question'
}

export const getOperatorIconFormat = operatorEnum => {
  return OPERATOR_ICONS_FORMAT[operatorEnum]
}