// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import axios from 'axios'
import { setupInterceptors } from '@/utils/httpInterceptors'
import { saveAxiosInstance } from '@/common/utils/tokenPropogater'

const BASE_URL = window.KUBE_SETTINGS.CPQConfiguratorServiceURL

let configuratorApi = axios.create({
  baseURL: `${BASE_URL}/`,
  crossDomain: true,
  withCredentials: true
})
setupInterceptors(configuratorApi)
saveAxiosInstance(configuratorApi)

export default configuratorApi