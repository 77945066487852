// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import { SET_NEW_SORT, SET_SEARCH, SET_NEW_PAGE_INFO, SET_OBJECT_KEY, RESET_OBJECT_VIEW, ROUTER_SET_FROM_ROUTE } from '@/store/types'

const defaultSort = {
  name: 'name',
  type: 'string',
  order: 'ascending'
}

const defaultPageInfo = {
  pageIndex: 1,
  pageSize: 10
}

const state = {
  fromRoute: null,
  objectKey: {
    modelId: null,
    priceListId: null
  },
  sort: {
    bundles: defaultSort,
    carts: { name: 'created', type: 'timestamp', order: 'descending' },
    constraints: defaultSort,
    customFields: defaultSort,
    decisionRules: defaultSort,
    formulas: defaultSort,
    groups: defaultSort,
    guidedUis: defaultSort,
    integrations: defaultSort,
    models: defaultSort,
    modelUis: { name: 'type', type: 'string', order: 'ascending' },
    parameters: defaultSort,
    pricedItems: defaultSort,
    priceLists: { name: 'isDefault', type: 'string', order: 'descending' },
    priceListItems: defaultSort,
    variables: defaultSort,
    boms: defaultSort,
    'currency-table': { name: 'name', type: 'string', order: 'ascending' }
  },
  search: {
    bundles: null,
    carts: null,
    constraints: null,
    customFields: null,
    decisionRules: null,
    formulas: null,
    guidedUis: null,
    groups: null,
    integrations: null,
    models: null,
    modelUis: null,
    parameters: null,
    pricedItems: null,
    priceLists: null,
    priceListItems: null,
    variables: null,
    boms: null,
    'currency-table': null
  },
  pageInfo: {
    bundles: defaultPageInfo,
    carts: defaultPageInfo,
    constraints: defaultPageInfo,
    customFields: defaultPageInfo,
    decisionRules: defaultPageInfo,
    formulas: defaultPageInfo,
    guidedUis: defaultPageInfo,
    groups: defaultPageInfo,
    integrations: defaultPageInfo,
    models: defaultPageInfo,
    modelUis: defaultPageInfo,
    parameters: defaultPageInfo,
    pricedItems: defaultPageInfo,
    priceLists: defaultPageInfo,
    priceListItems: defaultPageInfo,
    variables: defaultPageInfo,
    boms: defaultPageInfo,
    'currency-table': { pageIndex: 1, pageSize: 250 }
  }
}

const getters = {
  getObjectKey(state) {
    return objectName => {
      return state.objectKey[objectName]
    }
  },
  getSortColumn(state) {
    return objectName => {
      return state.sort[objectName]
    }
  },
  getDefaultSearch(state) {
    return objectName => {
      return state.search[objectName]
    }
  },
  getPageInfo(state) {
    return objectName => {
      return state.pageInfo[objectName]
    }
  },
  getFromRoute(state) {
    return state.fromRoute
  }
}

const mutations = {
  [SET_OBJECT_KEY](state, payload) {
    Vue.set(state.objectKey, payload.objectName, payload.objectId)
  },
  [SET_NEW_SORT](state, payload) {
    Vue.set(state.sort, payload.sortComponent, {
      name: payload.column.name,
      type: payload.column.type,
      order: payload.column.order
    })
  },
  [SET_SEARCH](state, payload) {
    Vue.set(state.search, payload.searchTable, payload.searchText)
  },
  [RESET_OBJECT_VIEW](state, objectName) {
    Vue.set(state.search, objectName, null)
    Vue.set(state.sort, objectName, defaultSort)
  },
  [SET_NEW_PAGE_INFO](state, payload) {
    Vue.set(state.pageInfo, payload.objectName, {
      pageIndex: payload.pageInfo.pageIndex,
      pageSize: payload.pageInfo.pageSize
    })
  },
  [ROUTER_SET_FROM_ROUTE](state, fromRoute) {
    state.fromRoute = fromRoute
  }
}

export default {
  state,
  getters,
  mutations
}
