// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED
import * as contexts from '@/constants/ruleTableTypes'

export default function getConditionsUsageByContext(context) {
  switch (context) {
    case contexts.BUNDLE_AVAILABILITY:
      return 'BundleAvailabilityConditions'
    case contexts.CONSTRAINT:
      return 'ConstraintRuleConditions'
    default:
      return 'DecisionRuleConditions'
  }
}
