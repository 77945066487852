// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const VALUE_NUMERIC = 'Numeric'
export const VALUE_STRING = 'String'
export const VALUE_DATE = 'Date'
export const VALUE_NUMERIC_RANGE = 'NumericBetween'
export const VALUE_DATE_RANGE = 'DateBetween'
// per Ian's request
export const VALUE_BOOLEAN = 'Boolean'
export const VALUE_LIST = 'List'
export const VALUE_MULTI_SELECT = 'MultiSelect'