// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import DeleteWarningModal from './DeleteWarningModal'
import ServerErrorModal from './ServerErrorModal'
import WarningModal from './WarningModal'
import BaseCurrencyModal from './BaseCurrencyModal'
import InfoModal from './InfoModal'

export function showDeleteWarningModal(vm, commit, type, label = null) {
  vm.$modal.show(DeleteWarningModal,
    { 
      success: commit,
      deleteType: type,
      checkboxLabel: label
    }, 
    { 
      classes: ['delete-warning-modal-container'],
      clickToClose: false
    }
  )
}

export function showWarningModal(vm, confirmCb, title, description, confirmBtnText, cancelBtnText, cancelCb = () => {}) {
  vm.$modal.show(WarningModal,
    { 
      confirmCb,
      title,
      description,
      confirmBtnText,
      cancelBtnText,
      cancelCb
    }, 
    {
      classes: ['warning-modal-container'],
      clickToClose: true
    }
  )
}

export function showServerErrorModal(vm, error) {
  vm.$modal.show(ServerErrorModal,
    { 
      error
    }, 
    { 
      classes: ['server-error-modal-container'],
      clickToClose: true
    }
  )
}

export function showBaseCurrencyModal(vm, callback) {
  vm.$modal.show(BaseCurrencyModal,
    {
      refreshCurrencies: callback
    },
    {
      classes: ['base-currency-modal'],
      clickToClose: true
    }
  )
}

export function showInfoModal(vm, info) {
  vm.$modal.show(InfoModal,
    { 
      info
    }, 
    { 
      classes: ['info-modal-container'],
      clickToClose: true
    }
  )
}