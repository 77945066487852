// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const Group = 'OptionGroup'
export const ProductGroup = 'BasicProductGroup'
export const ConfiguredProductGroup = 'ConfiguredProductGroup'
export const BomItemGroup = 'BomItemGroup'
export const Variable = 'Variable'
export const Parameter = 'Parameter'
export const CustomField = 'CustomField'
export const Integration = 'Integration'
export const Configuration = 'Configuration'
export const SystemProperty = 'SystemProperty'

export default {
  Group,
  BomItemGroup,
  ProductGroup,
  ConfiguredProductGroup,
  Variable,
  Parameter,
  CustomField,
  Integration,
  Configuration,
  SystemProperty
}