// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED   
import router from '@/router'

const _oktaPlugin = {
  install: function (Vue, options) {
    // 1. add global method or property
    // Vue.myGlobalMethod = function () {
    // some logic ...
    // }
  
    // 2. add a global asset
    // Vue.directive('my-directive', {
    //   bind (el, binding, vnode, oldVnode) {
    //     // some logic ...
    //   }
    // })
  
    // 3. inject some component options
    // Vue.mixin({
    //   created: function () {
    // some logic ...
    //   }
    // })
  
    // 4. add an instance method
    Vue.prototype.$checkAuth = async function (methodOptions) {
      if (Vue.prototype.$auth) {
        const auth = Vue.prototype.$auth
        const isAuth = await auth.isAuthenticated()
        if (!isAuth && router.history.current.name !== 'login') location.reload()
      }
    }
  }
}

export default _oktaPlugin