// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import { MULTIPLE, COLUMN, LANGUAGE } from '../../constants/warningModalTypes'
import { blurInmplayer, unBlurInmplayer } from '../../utils/modalBlur'
import CheckboxInput from '@/common/form-controls/checkbox-input'

export default {
  name: 'DeleteWarningModal',
  props: [ 'success', 'deleteType', 'checkboxLabel' ],
  components: {
    CheckboxInput
  },
  data() {
    return {
      checkboxValue: false
    }
  },
  computed: {
    titleMessage() {
      switch (this.deleteType) {
        case COLUMN: {
          return this.$t2('delete_column_modal_title', 'Delete this column?', this.$t)
        }
        case MULTIPLE: {
          return this.$t2('delete_multiple_modal_title', 'Delete the selected record(s)?', this.$t)
        }
        case LANGUAGE: {
          return this.$t('delete_language_modal_title', 'Delete Language?')
        }
        default: {
          return this.$t2('delete_warning_modal_title', 'Delete this record?', this.$t)
        }
      }
    }
  },
  mounted() {
    blurInmplayer()
    if (this.$el.querySelector('.delete-warning-modal__buttons__do-it')) this.$el.querySelector('.delete-warning-modal__buttons__do-it').focus()
  },
  destroyed() {
    unBlurInmplayer()
  },
  methods: {
    successClicked() {
      this.success(this.checkboxValue)
      this.$emit('close')
    }
  }
}