// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { blurInmplayer, unBlurInmplayer } from '../../utils/modalBlur'

export default {
  name: 'InfoModal',
  props: [
    'info'
  ],
  computed: {
    text() {
      return this.info.toString()
    }
  },
  mounted() {
    blurInmplayer()

    if (this.$el.querySelector && this.$el.querySelector('.button.button-default-outline.modal.info-modal__buttons__cancel')) {
      this.$el.querySelector('.button.button-default-outline.modal.info-modal__buttons__cancel').focus()
    }
  },
  destroyed() {
    unBlurInmplayer()
  }
}