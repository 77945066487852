// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import TextInput from '@/common/form-controls/text-input'
import Vue from 'vue'

export default Vue.extend({
  name: 'chatbot',
  components: {
    TextInput
  },
  data() {
    return {
      chatHistory: [], // In the shape of: [{ question: string, answer: string }]
      defaultSendButtonLabel: 'Send',
      defaultSubtitle: 'Ask a question',
      isSendButtonDisabled: false,
      question: '',
      sendButtonLabel: 'Send',
      subtitle: 'Ask a question'
    }
  },
  methods: {
    close() {
      this.$refs.dialog.classList.remove('chatbot__dialog--open')
    },
    disableSendButton(label) {
      this.isSendButtonDisabled = true
      this.sendButtonLabel = label
    },
    enableSendButton(label) {
      this.isSendButtonDisabled = false
      this.sendButtonLabel = label
    },
    async onSubmit() {
      if (this.question?.trim?.() === '') return

      this.disableSendButton('<i class="fa-solid fa-spin fa-spinner"></i> Loading')

      // Add user chat message
      const div = document.createElement('div')
      const p = document.createElement('p')
      p.innerHTML = '<strong>You: </strong>'
      p.innerHTML += this.question.trim()

      div.classList.add('chatbot__message', 'chatbot__message--client')
      div.appendChild(p)
      this.$refs.messages.appendChild(div)
      this.scrollToBottomOfChat()

      // Request config
      const username = process.env.VUE_APP_CHATBOT_USERNAME
      const password = process.env.VUE_APP_CHATBOT_PASSWORD
      const url = 'https://cpq-chatbot.devlkube.eastus2-dev.cincomcloud.com/chat'

      // Transform the chat history from an array of observers to an array of plain objects
      const chatHistory = this.chatHistory.map(chat => ({ ...chat }))
      const postData = { query: this.question, chat_history: chatHistory }
      const options = {
        body: JSON.stringify(postData),
        headers: {
          Authorization: `Basic ${btoa(username + ':' + password)}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }

      try {
        this.subtitle = 'Analyzing question...'
        const response = await fetch(url, options)
        const reader = response.body.getReader()
        const decoder = new TextDecoder('utf-8')
        const question = this.question.trim()
        this.question = ''

        // Add chatbot chat message
        const div = document.createElement('div')
        const p = document.createElement('p')
        this.subtitle = 'Chatbot is typing...'
        p.innerHTML = '<strong>Chatbot: </strong>'

        div.classList.add('chatbot__message', 'chatbot__message--server')
        div.appendChild(p)
        this.$refs.messages.appendChild(div)

        while (true) {
          const { done, value } = await reader.read()

          if (done) break

          const decodedValue = decoder.decode(value, { stream: true })

          if (!decodedValue.trim().startsWith('{"answer"')) {
            p.innerHTML += decodedValue.replaceAll('\n', '<br>').replace('**', '')
          } else {
            const { answer, source_document_metadata: sourceDocumentMetadata = [] } = JSON.parse(decodedValue)

            if (sourceDocumentMetadata.length) {
              p.innerHTML += `<br><br>Source${sourceDocumentMetadata.length > 1 ? 's' : ''}:<br><br>`
              const ul = document.createElement('ul')

              ul.classList.add('chatbot__source-list')

              sourceDocumentMetadata.forEach(source => {
                const li = document.createElement('li')
                li.innerHTML = `<a href="${source.source}" target="_blank">${source.title}</a>`

                ul.appendChild(li)
              })

              p.appendChild(ul)
            }

            this.chatHistory.push({ question, answer })
          }

          this.scrollToBottomOfChat()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.question = ''
        this.subtitle = this.defaultSubtitle

        this.enableSendButton(this.defaultSendButtonLabel)
      }
    },
    open() {
      this.$refs.dialog.classList.add('chatbot__dialog--open')
      this.$refs.questionInput.$el.querySelector('input').focus()
    },
    scrollToBottomOfChat() {
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
    }
  }
})
