// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED                      
/* istanbul ignore file */
export const DeploymentRoutes = [
  {
    path: '/deployment/',
    name: 'deployment',
    component: () =>
      import(/* webpackChunkName: "deployment" */ './deployment'),
    meta: {
      breadcrumbs: [{
        name: 'deployment',
        displayLocizeKey: 'breadcrumbs_deployment'
      }],
      requiresAuth: true
    }
  }, {
    path: '/models/model/:modelId/deployment/',
    name: 'modelDeployment',
    component: () =>
        import('./deployment'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'models',
          displayLocizeKey: 'breadcrumbs_models'
        },
        {
          name: 'modelDetail'
        },
        {
          name: 'modelDeployment',
          displayLocizeKey: 'breadcrumbs_deployment'
        }
      ],
      requiresAuth: true
    }
  }, {
    path: '/pricing/price-rules/header/:modelId/deployment/',
    name: 'priceHeaderRuleDeployment',
    component: () =>
        import('./deployment'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricing',
          displayLocizeKey: 'breadcrumbs_pricing'
        },
        {
          name: 'pricingHeaders',
          displayLocizeKey: 'breadcrumbs_header-rules'
        },
        {
          name: 'priceHeaderRuleDeployment',
          displayLocizeKey: 'breadcrumbs_deployment'
        }
      ],
      requiresAuth: true
    }
  }, {
    path: '/pricing/price-rules/lines/:modelId/deployment/',
    name: 'priceLineRuleDeployment',
    component: () =>
        import('./deployment'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'pricing',
          displayLocizeKey: 'breadcrumbs_pricing'
        },
        {
          name: 'pricingLines',
          displayLocizeKey: 'breadcrumbs_line-rules'
        },
        {
          name: 'priceLineRuleDeployment',
          displayLocizeKey: 'breadcrumbs_deployment'
        }
      ],
      requiresAuth: true
    }
  }, {
    path: '/outputs/:modelId/deployment/',
    name: 'outputsDeployment',
    component: () =>
        import('./deployment'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          name: 'outputs',
          displayLocizeKey: 'breadcrumbs_outputs'
        },
        {
          name: 'outputsDeployment',
          displayLocizeKey: 'breadcrumbs_deployment'
        }
      ],
      requiresAuth: true
    }
  }
]
