// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const MODEL_TYPE_PRODUCT_BASED = 'ProductBased'
export const MODEL_TYPE_LINE_BASED = 'LineBased'
export const MODEL_TYPE_HEADER_BASED = 'HeaderBased'
export const MODEL_TYPE_SOLUTION_BASED = 'SolutionBased'
export const MODEL_TYPE_OUTPUT_BASED = 'OutputBased'
export const MODEL_TYPE_BUNDLE_BASED = 'BundleBased'

export default {
  MODEL_TYPE_PRODUCT_BASED,
  MODEL_TYPE_LINE_BASED,
  MODEL_TYPE_HEADER_BASED,
  MODEL_TYPE_SOLUTION_BASED,
  MODEL_TYPE_OUTPUT_BASED,
  MODEL_TYPE_BUNDLE_BASED
}