// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import {
  MASTER_DATA_SET_CURRENCIES,
  TENANT_SET_CURRENCIES,
  MASTER_DATA_SET_CURRENCY_SELECTED_STATE,
  MASTER_DATA_SET_CUSTOM_FIELDS,
  MASTER_DATA_SET_CURRENT_CUSTOM_FIELD,
  MASTER_DATA_SET_PRICED_ITEMS,
  MASTER_DATA_SET_CURRENT_PRICED_ITEM,
  MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS,
  MASTER_DATA_SET_PRICE_LISTS,
  MASTER_DATA_SET_CURRENT_PRICE_LIST,
  MASTER_DATA_SET_PRICE_LIST_ITEMS,
  MASTER_DATA_SET_PRICE_SHEET_ITEMS,
  MASTER_DATA_SET_COST_SHEET_ITEMS,
  MASTER_DATA_SET_GROUPS,
  MASTER_DATA_SET_GROUP_SELECTED_TO_FALSE,
  MASTER_DATA_SET_CURRENT_GROUP,
  MASTER_DATA_SET_GROUP_SELECTED_STATE,
  MASTER_DATA_SET_COLUMN_SETTINGS
} from '@/store/types'

export default {
  [MASTER_DATA_SET_GROUP_SELECTED_STATE](state, ids) {
    state.groups = state.groups.map(grp =>
      ids.includes(grp.id) ? {
        ...grp,
        selected: true
      } : {
        ...grp,
        selected: false
      }
    )
  },
  [MASTER_DATA_SET_GROUPS](state, groups) {
    state.groups = groups
  },
  [MASTER_DATA_SET_CURRENT_GROUP](state, currentGroup) {
    state.currentGroup = currentGroup
  },
  [MASTER_DATA_SET_GROUP_SELECTED_TO_FALSE](state, group) {
    const groupInState = state.groups.find(g => g.id === group.id) || {}
    groupInState['selected'] = false
  },
  [MASTER_DATA_SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  },
  [TENANT_SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  },
  [MASTER_DATA_SET_CURRENCY_SELECTED_STATE](state, id) {
    state.currencies = state.currencies.map(curr =>
      curr.id === id ? {
        ...curr,
        selected: true
      } : {
        ...curr,
        selected: false
      }
    )
  },
  [MASTER_DATA_SET_CUSTOM_FIELDS](state, customFields) {
    state.customFields = customFields
  },
  [MASTER_DATA_SET_CURRENT_CUSTOM_FIELD](state, currentCustomField) {
    state.currentCustomField = currentCustomField
  },
  [MASTER_DATA_SET_PRICED_ITEMS](state, pricedItems) {
    state.pricedItems = pricedItems
  },
  [MASTER_DATA_SET_CURRENT_PRICED_ITEM](state, currentPricedItem) {
    state.currentPricedItem = currentPricedItem
  },
  [MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS](state, unassignedPricedItems) {
    state.unassignedPricedItems = unassignedPricedItems
  },
  [MASTER_DATA_SET_PRICE_LISTS](state, priceLists) {
    let priceList = priceLists.map(item => ({ ...item, currencyCode: (item.currency) ? item.currency.code : item.currencyCode }))
    state.priceLists = priceList
  },
  [MASTER_DATA_SET_CURRENT_PRICE_LIST](state, currentPriceList) {
    state.currentPriceList = currentPriceList
  },
  [MASTER_DATA_SET_PRICE_LIST_ITEMS](state, priceListItems) {
    state.priceListItems = priceListItems
  },
  [MASTER_DATA_SET_PRICE_SHEET_ITEMS](state, priceSheetItems) {
    state.priceSheetItems = priceSheetItems
  },
  [MASTER_DATA_SET_COST_SHEET_ITEMS](state, costSheetItems) {
    state.costSheetItems = costSheetItems
  },
  [MASTER_DATA_SET_COLUMN_SETTINGS](state, columnSettings) {
    state.columnSettings = columnSettings
  }
}