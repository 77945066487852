// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
import Vue from 'vue'
import { AUTH_SET_IS_AUTHENTICATED, AUTH_SET_USER, AUTH_SET_OKTA_TOKENS } from '@/store/types'

const state = {
  isAuthenticated: false,
  user: {},
  oktaTokens: null
}

const getters = {
  getIsAuthenticated: state => state.isAuthenticated,
  getUser: state => state.user,
  // getUserId: state => state.oktaTokens && state.oktaTokens.idToken && state.oktaTokens.idToken.claims ? state.oktaTokens.idToken.claims.userid : state.user.userid,
  // getUserTenantId: state => state.oktaTokens && state.oktaTokens.idToken && state.oktaTokens.idToken.claims ? state.oktaTokens.idToken.claims.tenant : state.user.tenant,
  // getUserAuthorId: state => state.oktaTokens && state.oktaTokens.idToken && state.oktaTokens.idToken.claims ? state.oktaTokens.idToken.claims.authorId : null,
  getUserId: state => {
    const oktaTokenJSON = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (oktaTokenJSON && oktaTokenJSON.idToken && oktaTokenJSON.idToken.claims) {
      return oktaTokenJSON.idToken.claims.userid
    } else {
      return state.user.userid
    }
  },
  getUserTenantId: state => {
    const oktaTokenJSON = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (oktaTokenJSON && oktaTokenJSON.idToken && oktaTokenJSON.idToken.claims) {
      return oktaTokenJSON.idToken.claims.tenant
    } else {
      return state?.user && state.user.tenant
    }
  },
  getUserAuthorId: () => {
    const oktaTokenJSON = JSON.parse(localStorage.getItem('okta-token-storage'))
    return oktaTokenJSON.idToken.claims.authorId 
  },
  hasUserBeenLoaded: state => Object.keys(state.user).length > 0
}

const mutations = {
  [AUTH_SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  [AUTH_SET_USER](state, user) {
    if (!user) return
    Vue.set(state, 'user', user)
  },
  [AUTH_SET_OKTA_TOKENS](state, oktaTokens) {
    state.oktaTokens = oktaTokens
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}