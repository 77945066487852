// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import Vue from 'vue'
import Router from 'vue-router'
import AppRoutes from '@/modules/routes'
import Login from '@/common/login/login'
import { logout, validateAccessAsync, setUseOktaLogin } from '@/common/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history', // removes # from url
  base: process.env.BASE_URL,
  routes: [
    ...AppRoutes,
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    {
      name: 'logout',
      path: '/logout',
      component: logout
    },
    // catch-all route
    // AKA if no route was found
    {
      path: '*',
      redirect: {
        name: 'dashboard'
      }
    }
  ]
})

setUseOktaLogin(false)
router.beforeEach(async (to, from, next) => {
  await validateAccessAsync(to, from, next)
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
