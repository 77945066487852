// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED                      
export const LOCIZE_CONFIG = {
  lng: 'en-US',
  fallbackLng: 'en-US',
  whitelist: ['en-US', 'fr'],
  debug: false,
  saveMissing: true,
  missingKeyHandler: function () {
    return ' '
  },
  parseMissingKeyHandler: function () {
    return ' '
  },
  backend: {
    projectId: '7d7cda9c-bf53-40cd-8d77-e376e91e2cc5',
    apiKey: 'daa0adbe-79b6-4728-81de-bf9430e85603',
    referenceLng: 'en-US',
    version: 'production'
  }
}