// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const DocumentOutputRoutes = [
  {
    path: '/settings',
    name: 'documentOutputSettings',
    component: () =>
      import(/* webpackChunkName: "documentOutput" */ './document-output-settings'),
    meta: {
      breadcrumbs: [
        {
          name: 'documentOutputSettings',
          displayLocizeKey: 'breadcrumbs_settings',
          iconClass: 'fa-file'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/templates',
    name: 'documentOutputTemplates',
    component: () =>
      import(/* webpackChunkName: "documentOutput" */ './document-output-templates'),
    meta: {
      breadcrumbs: [
        {
          name: 'documentOutputTemplates',
          displayLocizeKey: 'breadcrumbs_templates',
          iconClass: 'fa-file'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/sequence',
    name: 'documentOutputSequence',
    component: () =>
      import(/* webpackChunkName: "documentOutput" */ './document-output-sequence'),
    meta: {
      breadcrumbs: [
        {
          name: 'documentOutputSequence',
          displayLocizeKey: 'breadcrumbs_output-sequence',
          iconClass: 'fa-sort-numeric-up'
        }
      ],
      requiresAuth: true
    }
  }
]
