// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import debounce from '../../utils/debounce'

export default {
  name: 'accordion',
  props: {
    displayText: {
      type: String,
      required: true
    },
    capitalizeDisplayText: {
      type: Boolean,
      default: false
    },
    badgeCount: {
      type: Number,
      default: null
    },
    isCollapsedByDefault: {
      type: Boolean,
      default: false
    },
    editorOperatorList: {
      type: String,
      default: null
    },
    accordionIcon: {
      type: String,
      default: 'fa-chevron-down'
    },
    accordionTooltip: {
      type: Object,
      default: null
    },
    useHideOverConditional: {
      type: Boolean,
      default: false
    },
    forceCollapse: {
      type: Boolean,
      default: false
    },
    useExtension: {
      type: Boolean,
      default: false
    },
    canExtend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: this.isCollapsedByDefault,
      selected: false,
      extended: false,
      savedCollapsedValue: null,
      toggleIsCollapsedCb: debounce(() => {
        if (!this.forceCollapse) {
          if (this.savedCollapsedValue || this.savedCollapsedValue == null) {
            if (!this.selected) {
              this.isCollapsed = !this.isCollapsed
            } else if (this.extended) {
              this.isCollapsed = !this.isCollapsed
            }
          }
        }
      }, 100)
    }
  },
  beforeMounted() {
    if (this.useHideOverConditional) {
      this.checkIfSelected()
    }
  },
  mounted() {
    if (this.useHideOverConditional) {
      this.checkIfSelected()
    }
  },
  updated() {
    this.updatedMethod()
  },
  computed: {
    renderedDisplayText() {
      return this.capitalizeDisplayText ? this.displayText.toUpperCase() : this.displayText
    },
    isExtended() { 
      if (this.useExtension) {
        return !this.isCollapsedComputed ? true : this.canExtend
      } else {
        return true
      }
    },
    isCollapsedComputed() { 
      return this.forceCollapse ? true : this.isCollapsed
    }
  },
  methods: {
    updatedMethod() {
      if (this.useHideOverConditional) {
        this.checkIfSelected()
        if (this.canExtend) {
          setTimeout(
            () => { this.extended = true }, 100)
        } else {
          setTimeout(
            () => { this.extended = false }, 100)
        }
      }
    },
    checkIfSelected() {
      if (this.useHideOverConditional) {
        let element1 = this.$el.querySelector('.accordion .router-link-exact-active')
        let element2 = this.$el.querySelector('.accordion .button-active')
        if (element1 !== null) {
          this.selected = true
          if (!this.canExtend) {
            this.isCollapsed = false
          }
        } else if (element2 !== null) {
          this.selected = true
          if (!this.canExtend) {
            this.isCollapsed = false
          }
        } else {
          this.selected = false
        }
      } 
    },
    keyPressed(e) {
      /* istanbul ignore next */
      if (e.key !== 'Tab') e.preventDefault()
      /* istanbul ignore next */
      switch (e.key) {
        case 'ArrowDown':
          if (document.querySelector('.' + this.editorOperatorList)) document.querySelector('.' + this.editorOperatorList).children[0].focus()
          break
        case 'Enter':
        case ' ':
          this.toggleIsCollapsedCb()
          break
        default:
          break
      }
    }
  }
}
