// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const OutputsRoutes = [
  {
    path: '/outputs/:modelId',
    name: 'outputs',
    component: () =>
      import(/* webpackChunkName: "outputs" */ './outputs-dashboard'),
    meta: {
      breadcrumbs: [{
        name: 'outputs',
        displayLocizeKey: 'breadcrumbs_outputs'
      }],
      requiresAuth: true
    }
  },
  {
    path: '/outputs/:modelId/manufacturing-bom',
    name: 'mfgBOM',
    component: () =>
      import(/* webpackChunkName: "mfgBOM" */ './mfg-bom-info'),
    meta: {
      breadcrumbs: [
        // {
        //   name: 'outputs',
        //   displayLocizeKey: 'breadcrumbs_outputs'
        // },
        {
          name: 'mfgBOM',
          displayLocizeKey: 'breadcrumbs_manufacturing-bom',
          iconClass: 'fa-receipt'
        }
      ],
      requiresAuth: true
    }
  }
]
