// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import objectTypes from '@/constants/objectTypes'
import bindingTypes from '@/constants/bindingTypes'
// import outcomeTypes from '@/modules/misc/rules-table'

export default {
  // FORMULAS
  getFormulas(state) {
    return state.formulas
  },
  getCurrentFormula(state) {
    return state.currentFormula 
  },
  getFormulasSequence(state) {
    return state.formulasSequence
  },
  getEditingBundle(state) {
    return state.editingBundle 
  },
  getExistingFormulas(state) {
    return state.existingFormulas
  },
  getExistingParameterFormulas(state) {
    return state.existingFormulas.formulaParameters
  },
  getExistingCustomFieldFormulas(state) {
    return state.existingFormulas.formulaCustomFields
  },
  getExistingIntegrationFormulas(state) {
    return state.existingFormulas.formulaIntegrations
  },
  getExistingSystemPropertyFormulas(state) {
    return state.existingFormulas.formulaSystemProperties
  },
  getSystemProperties(state) {
    return state.systemProperties
      .map(({ name }) => ({
        outcomeType: 'Binding',
        binding: {
          name: name,
          id: name,
          systemProperty: name,
          bindingType: bindingTypes.SYSTEM_PROPERTY
        }
      }))
  },
  // MODELS
  getModels(state) {
    return state.models
  },
  getCurrentModel(state) {
    return state.currentModel
  },
  getCurrentModelDetails(state) {
    return state.currentModelDetails
  },
  getOutputsModelId(state) {
    return state.outputsModelId
  },
  getLanguagesModelId(state) {
    return state.languagesModelId
  },
  getCurrentModelType(state) {
    return state.currentModelDetails && state.currentModelDetails.modelType
  },
  getCurrentModelVersion(state) {
    return state.currentModelDetails ? state.currentModelDetails.version : null
  },
  getCurrentModelProducts(state) {
    return state.currentModelDetails ? state.currentModelDetails.products : []
  },
  getCurrentModelOptionGroups(state) {
    return state.currentModelDetails ? state.currentModelDetails.optionGroups : []
  },
  getCurrentModelOptionGroupIds(state, getters) {
    return getters.getCurrentModelOptionGroups.map(({ id }) => id)
  },
  getCurrentModelProductGroups(state) {
    return state.currentModelDetails ? state.currentModelDetails.basicProductGroups : []
    // return state.currentModelDetails
    //   ? state.currentModelDetails.modelType === 'BundleBased'
    //     ? state.currentModelDetails.basicProductGroups
    //     : [...state.currentModelDetails.basicProductGroups, ...state.currentModelDetails.configuredProductGroups]
    //   : []
  },
  getCurrentModelProductGroupIds(state, getters) {
    return getters.getCurrentModelProductGroups.map(({ id }) => id)
  },
  getCurrentModelConfiguredProductGroups(state) {
    return state.currentModelDetails ? state.currentModelDetails.configuredProductGroups : []
  },
  getCurrentModelConfiguredProductGroupIds(state, getters) {
    return getters.getCurrentModelConfiguredProductGroups.map(({ id }) => id)
  },
  getCurrentModelProductIds(state, getters) {
    return getters.getCurrentModelProducts.map(({ id }) => id)
  },
  getCurrentModelInfo(state) {
    return state.currentModelInfo
  },
  getConstraints(state) {
    return state.constraints
  },
  getGroupsByModel(state) {
    return state.groupOptionsModel
  },
  getConstraintData(state) {
    return state.currentConstraint
  },
  getDecisionRules(state) {
    return state.decisionRules
  },
  getMetadataUis(state) {
    return state.modelUis
  },
  getGuidedUis(state) {
    return state.guidedUis
  },
  getCurrentGuidedUi(state) {
    return state.currentGuidedUI
  },
  getMetadata(state) {
    return state.metadata
  },
  getMetadataSections(state) {
    const step = state.metadata.steps && state.metadataActiveStepId ? state.metadata.steps.find(step => step.id === state.metadataActiveStepId) : null
    return step ? step.sections || [] : state.metadata.sections || [] 
  },
  getMetadataSteps(state) {
    return state.metadata.steps || []
  },
  getMetadataActiveStepId(state) {
    return state.metadataActiveStepId
  },
  getBundles(state) {
    return state.bundles
  },
  getVariables(state) {
    return state.variables
  },
  getCurrentVariable(state) {
    return state.currentVariable
  },
  getBoms(state) {
    return state.boms
  },
  getBom(state) {
    return state.currentBom
  },
  getCurrentBom(state) {
    return state.currentBom
  },
  getEntities(state) {
    return state.entities
  },
  getNumericParametersFromEntities(state, getters) {
    return getters.getParametersFromEntities.filter(({ dataType }) => dataType === 'Numeric')
  },
  getStringParametersFromEntities(state, getters) {
    return getters.getParametersFromEntities.filter(({ dataType }) => dataType === 'String')
  },
  getParametersForFormulas(state, getters) {
    return getters.getParametersFromEntities.filter(({ dataType }) => dataType === 'Numeric' || dataType === 'String')
  },
  getCurrentEntity(state) {
    return state.currentEntity
  },
  getAllEntitiesForModel(state) {
    return state.entities
  },
  getBomEntities(state) {
    return state.bomEntities
  },
  getConfigurationFromEntities(state) {
    return state.entities.find(({ objectType }) => objectType === objectTypes.Configuration)
  },
  getConfigurationId(state, getters) {
    return getters.getConfigurationFromEntities && getters.getConfigurationFromEntities.id
  },
  getBomItemGroupsFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.BomItemGroup)
  },
  getConfiguredProductGroupsFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.ConfiguredProductGroup)
  },
  getBasicProductGroupsFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.ProductGroup)
  },
  getOptionGroupsFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.Group)
  },
  getParametersFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.Parameter)
  },
  getVariablesFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.Variable)
  },
  getIntegrationsFromEntities(state) {
    return state.entities.filter(({ objectType }) => objectType === objectTypes.Integration)
  },
  getNumericIntegrationsFromEntities(state, getters) {
    return getters.getIntegrationsFromEntities.filter(({ dataType }) => dataType === 'Numeric')
  },
  getParameters(state) {
    return state.parameters
  },
  getCurrentParameter(state) {
    return state.currentParameter
  },
  getIntegrations(state) {
    return state.integrations
  },
  getPrecision(state) {
    return state.precision
  }
}
