// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import { getTenantApi } from '@/api/tenantApi'
import handleError from '@/api/handleError'
import { AUTH_SET_IS_AUTHOR, TENANT_SET_TENANT } from '@/store/types'

const state = {
  isAuthor: null,
  tenant: null
}

const getters = {
  getIsAuthor: state => state.isAuthor,
  getTenant: state => state.tenant
}

const mutations = {
  [AUTH_SET_IS_AUTHOR](state, isAuthor) {
    state.isAuthor = isAuthor
  },
  [TENANT_SET_TENANT](state, tenant) {
    state.tenant = tenant
  }
}

const actions = {
  fetchPackages() {
    return new Promise((resolve, reject) => {
      return getTenantApi().get('packages').then(response => {
        resolve(response.data)
      }).catch(error => {
        handleError(error)
      })
    }) 
  },
  fetchTenant({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return getTenantApi().get(`tenants/${getters.getUserTenantId}/light`).then((response) => {
        commit(TENANT_SET_TENANT, response.data)
        resolve(response.data)
      }).catch((error) => {
        handleError(error)
        reject(error)
      })
    })
  },
  fetchUser({ getters, commit }, { userId }) {
    return new Promise((resolve, reject) => {
      getTenantApi().get(`tenants/${getters.getUserTenantId}/users/${userId}`)
        .then(response => {
          commit(AUTH_SET_IS_AUTHOR, response.data.isAuthor)
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSupportTickets({ getters }, { payload }) {
    return new Promise((resolve, reject) => {
      return getTenantApi().post(`tenants/${getters.getUserTenantId}/support-tickets`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    }) 
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
