// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
/* istanbul ignore file */
export const PricedItemsRoutes = [{
  path: '/priced-items/',
  name: 'pricedItems',
  component: () =>
    import(/* webpackChunkName: "core" */ './priced-items'),
  meta: {
    breadcrumbs: [{
      name: 'pricedItems',
      displayLocizeKey: 'breadcrumbs_priced-items',
      iconClass: 'fa-box-alt'
    }],
    requiresAuth: true
  }
},
{
  path: '/priced-items/priced-item-info/',
  name: 'addPricedItem',
  component: () =>
      import(/* webpackChunkName: "pricedItemInfo" */ './priced-item-info'),
  props: true,
  meta: {
    breadcrumbs: [
      {
        name: 'pricedItems',
        displayLocizeKey: 'breadcrumbs_priced-items'
      },
      {
        name: 'addPricedItem',
        displayLocizeKey: 'breadcrumbs_priced-item_add',
        iconClass: 'fa-box-alt'
      }
    ],
    requiresAuth: true
  }
},
{
  path: '/priced-items/priced-item-info/:pricedItemId?',
  name: 'editPricedItem',
  component: () =>
      import(/* webpackChunkName: "pricedItemInfo" */ './priced-item-info'),
  props: true,
  meta: {
    breadcrumbs: [{
      name: 'pricedItems',
      displayLocizeKey: 'breadcrumbs_priced-items'
    },
    {
      name: 'editPricedItem',
      displayLocizeKey: 'breadcrumbs_priced-item_edit'
    }
    ],
    requiresAuth: true
  }
}
]
