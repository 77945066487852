// (c) Cincom Systems, Inc. <2018> - <2020>
// ALL RIGHTS RESERVED

// Contstraint Rules      
export const CONSTRAINT = 'Constraint'

// BOM Based Rules
export const SALES_BOM = 'SalesBom'
export const MFG_BOM = 'MfgBom'

// "Decision Rule card" rules
export const MODEL = 'Model'

// Bundle Based Rules
export const BUNDLE_AVAILABILITY = 'Availability'

// Rules on Model UI Page
export const VISIBILITY = 'Visibility'
export const RENDERING = 'Rendering'
export const ENABLEMENT = 'Enablement'
export const REQUIRED = 'Required'
export const SPECIAL_REQUEST = 'Special Request'
export const SECTION_VISIBILITY = 'Section Visibility'
export const SECTION_ENABLEMENT = 'Section Enablement'
export const SECTION_REQUIRED = 'Section Required'
export const SECTION_SPECIAL_REQUEST = 'Section Special Request'

// Rules on Relationships Page
export const RECOMMENDATIONS = 'Recommendations'
export const FILTERS = 'Filters'

export const headerLocalizeKey = {
  [CONSTRAINT]: '',
  [SALES_BOM]: 'decision-rule-editor_title',
  [MODEL]: 'decision-rule-editor_title',
  [VISIBILITY]: '',
  [ENABLEMENT]: '',
  [REQUIRED]: ''
} || 'decision-rule-error-message'