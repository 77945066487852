// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED                      
import { getDocumentApiClient } from '@/api/documentApi'
import handleError from '@/api/handleError'
import { TENANT_SET_NEW_AUTHOR_HOTFIX, TENANT_SET_NEW_AUTHOR_VERSION, CPQ_MANAGER_TOAST } from '@/store/types'

const state = {
  newAuthorHotfix: null,
  newAuthorVersion: null
}

const getters = {
  getNewAuthorHotfix: state => state.newAuthorHotfix,
  getNewAuthorVersion: state => state.newAuthorVersion
}

const mutations = {
  [TENANT_SET_NEW_AUTHOR_HOTFIX](state, newAuthorHotfix) {
    state.newAuthorHotfix = newAuthorHotfix
  },
  [TENANT_SET_NEW_AUTHOR_VERSION](state, newAuthorVersion) {
    state.newAuthorVersion = newAuthorVersion
  }
}

const actions = {
  fetchAuthorInstallVersion({ commit, getters }, t) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/users/${getters.getUserId}/install/version`)
        .then(response => {
          const newAuthorVersion = response.data.lastDownload.authorVersion && response.data.available.authorVersion && response.data.available.authorVersion !== response.data.lastDownload.authorVersion ? response.data.available.authorVersion : null
          const newAuthorHotfix = response.data.lastDownload.authorVersion && response.data.available.hotfixNumber && (response.data.available.authorVersion !== response.data.lastDownload.authorVersion || response.data.available.hotfixNumber !== response.data.lastDownload.hotfixNumber) ? response.data.available.hotfixNumber : null
          commit(TENANT_SET_NEW_AUTHOR_VERSION, newAuthorVersion)
          commit(TENANT_SET_NEW_AUTHOR_HOTFIX, newAuthorHotfix)
          if (newAuthorVersion) {
            commit(CPQ_MANAGER_TOAST, { type: 'notification', messages: t('new-eloquence-author-version_message'), showInnerSlot: true })
          } else if (newAuthorHotfix) {
            commit(CPQ_MANAGER_TOAST, { type: 'notification', messages: t('new-eloquence-author-hotfix_message'), showInnerSlot: true })  
          } else {
            commit(CPQ_MANAGER_TOAST, null)
          }
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadAuthor({ getters }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/users/${getters.getUserId}/install/download`)
        .then((response) => {
          let link = document.createElement('a')
          link.href = response.data
          // link.download = 'Author ' + version
          document.body.appendChild(link)
          link.click()
          resolve(response.data)
        })
        .catch(error => {
          console.error('Download Author file error', error)
          handleError()
          reject(error)
        })
    })
  },
  downloadHotfix({ getters }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/users/${getters.getUserId}/hotfix/download`)
        .then((response) => {
          let link = document.createElement('a')
          link.href = response.data
          // link.download = 'Author Hotfix ' + hotfixNumber
          document.body.appendChild(link)
          link.click()
          resolve(response.data)
        })
        .catch(error => {
          console.error('Download Author Hotfix file error', error)
          handleError()
          reject(error)
        })
    })
  },
  downloadJSON({ getters }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/document-schemas`)
        .then((response) => {
          let link = document.createElement('a')
          link.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data))
          link.download = 'document-schemas.json'
          link.target = '_blank'
          document.body.appendChild(link)
          link.click()
          // alert(response.data)
          resolve(response.data)
        })
        .catch(error => {
          console.error('Download JSON file error.', error)
          handleError()
          reject(error)
        })
    })
  },
  // AUTHOR TEMPLATES
  activateAuthorTemplate({ getters }, { templateId, runtime }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().post(`tenants/${getters.getUserTenantId}/templates/${templateId}/activate/${runtime}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deactivateAuthorTemplate({ getters }, { templateId, runtime }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().post(`tenants/${getters.getUserTenantId}/templates/${templateId}/deactivate/${runtime}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateAuthorTemplate({ getters }, { templateId, payload }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().put(`tenants/${getters.getUserTenantId}/templates/${templateId}`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAuthorConnectInfo({ getters }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/connect-info`)
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAuthorTemplates({ getters }) {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get(`tenants/${getters.getUserTenantId}/templates`)
        .then(response => {
          resolve(response.data.sort((a, b) => {
            const nameA = a.displayName?.toUpperCase()
            const nameB = b.displayName?.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }))
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchInstallVersion() {
    return new Promise((resolve, reject) => {
      getDocumentApiClient().get('install/version')
        .then(response => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}